import React from "react";
import { default as dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { E500 } from "../../components/e500";
import { useCreateCall } from "../../lib/api/create";
import { useUpdateCall } from "../../lib/api/update";
import { useGetMutateCall } from "../../lib/api/get";
import httpParams from "../../lib/httpParams";

dayjs.extend(utc);
dayjs.extend(timezone);

export const ExamMarksEntry = ({
  authData,
  session,
  modSchema,
  navs,
  openMenu,
  setOpenMenu,
  routesData,
  appVariables,
  authsSession,
  roleActive,
  primaryColor,
  secondaryColor,
  favicon,
  app,
}) => {
  const params = httpParams.adminOrgParams(appVariables);
  const getCallMutation = useGetMutateCall();
  const createCallMutation = useCreateCall();
  const updateCallMutation = useUpdateCall();

  const [marksData, setMarksData] = React.useState({ data: { formData: [] } });
  const [classSectionData, setClassSectionData] = React.useState({
    data: { formData: [] },
  });
  const [classSectionMarksData, setClassSectionMarksData] = React.useState({
    data: { formData: [] },
  });
  const [examSchedules, setExamSchedules] = React.useState(null);
  const [createData, setCreateData] = React.useState({});
  const [gettingData, setGettingData] = React.useState(false);
  const [
    classSectionSyllabusClassCombinationsData,
    setClassSectionSyllabusClassCombinationsData,
  ] = React.useState(null);
  const [classAYApplicationsData, setClassAYApplicationsData] =
    React.useState(null);
  const [
    syllabusCombinationSubjectExamsData,
    setSyllabusCombinationSubjectExamsData,
  ] = React.useState(null);
  const [
    examScheduleData,
    setExamScheduleData,
  ] = React.useState(null);
  const [examId, setExamId] = React.useState(null);
  const [examDate, setExamDate] = React.useState(null);
  const [selAcadecYearId, setSelAcadecYearId] = React.useState(null);
  const [selClassSectionId, setSelClassSectionId] = React.useState(null);
  const [selSubjectId, setSelSubjectId] = React.useState(null);
  const [selectedFilters, setSelectedFilters] = React.useState({
    classSectionId: "",
    syllabusCombinationSubjectId: "",
    examId: "",
    examDate: "",
  });

  const getOnchangeAppl = async (e) => {
    e.preventDefault();
    setGettingData(true);
    const data = new FormData(e.target);

    console.log(data.get("marks"));
    setCreateData({
      classSectionId: data.get("classSectionId"),
      examId: data.get("examId"),
      subjectId: data.get("subjectId"),
      examDate: data.get("examDate"),
    });
    await getCallMutation
      .mutateAsync({
        apiURL: appVariables.REACT_APP_API_URL,
        apiEndpoint: "ApplicationAYClassSections",
        params:
          params +
          "&classSectionId=" +
          data.get("classSectionId") +
          "&examId=" +
          data.get("examId") +
          "&subjectId=" +
          data.get("subjectId"),
      })
      .then((resp) => {
        setMarksData({ data: resp });
      });
    setGettingData(false);
  };

  const onSelectAcademicYear=(id)=>{
    setSelAcadecYearId(id)
  }

  const onSelectClassSection = async (id) => {
    if (id === "Select Class Section") {
      return;
    }
    setClassSectionSyllabusClassCombinationsData(null)
    setSyllabusCombinationSubjectExamsData(null)
    setExamScheduleData(null)
    selectedFilters["classSectionId"] = JSON.parse(id).classSectionId;
    setSelectedFilters(selectedFilters);
    setSelClassSectionId(JSON.parse(id));
    await getCallMutation
      .mutateAsync({
        apiURL: appVariables.REACT_APP_API_URL,
        // apiEndpoint: "ClassSectionSyllabusClassCombinations/subjects",
        apiEndpoint: "syllabusCombinationSubjects",
        params: params + "&syllabusCombinationId=" + JSON.parse(id).syllabusCombinationId,
      })
      .then((resp) => {
        setClassSectionSyllabusClassCombinationsData(resp);
      });
  };
  const onSelectClassSectionSubject = async (id) => {
    if (id === "Select Subject") {
      return;
    }
    setSyllabusCombinationSubjectExamsData(null)
    setExamScheduleData(null)
    selectedFilters["syllabusCombinationSubjectId"] = id;
    setSelectedFilters(selectedFilters);
    const subData = classSectionSyllabusClassCombinationsData.formData.filter(
      (f) => f.syllabusCombinationSubjectId === id
    );
    await getCallMutation
      .mutateAsync({
        apiURL: appVariables.REACT_APP_API_URL,
        apiEndpoint: "SyllabusCombinationSubjectExams",
        params: params + "&syllabusCombinationSubjectId=" + id,
      })
      .then((resp) => {
        setSyllabusCombinationSubjectExamsData(resp);
      });
    setSelSubjectId(subData);
  };
  const onSelectClassSectionSubjectExam = async (id) => {
    if (id === "Select Exam") {
      return;
    }
    setExamScheduleData(null)
    selectedFilters["examId"] = JSON.parse(id).examId;
    setSelectedFilters(selectedFilters);
    const exData = syllabusCombinationSubjectExamsData.formData.filter(
      (f) => f.examId === id
    );
    setExamId(exData);
    // const exmScheds = authData.dependeciesFormAPIS.syllabusCombinationSubjectExamSchedules.formData.filter((f)=>f.classSectionId===id)
    // setExamSchedules(exmScheds)
    selectedFilters["subjectExamId"] = JSON.parse(id).subjectExamId;
    setSelectedFilters(selectedFilters);
    await getCallMutation
      .mutateAsync({
        apiURL: appVariables.REACT_APP_API_URL,
        apiEndpoint: "syllabusCombinationSubjectExamSchedules",
        params:
          params +
          "&syllabusCombinationSubjecExamId="+JSON.parse(id).subjectExamId+
          "&academicYearId="+selAcadecYearId+
          "&classSectionId=" +
          selClassSectionId.classSectionId,
      })
      .then((resp) => {
        const filtrd = resp.formData.filter((f)=>f.syllabusCombinationSubjectExamId===JSON.parse(id).subjectExamId)
        console.log(filtrd)
        setExamScheduleData({ data: {formData:filtrd} });
      });
  };
  const onSelectClassSectionSubjectExamDate = async (date) => {
    if (date === null) {
      return;
    }
    selectedFilters["examDate"] = date;
    setSelectedFilters(selectedFilters);
    setExamDate(date);
    let emData;
    await getCallMutation
      .mutateAsync({
        apiURL: appVariables.REACT_APP_API_URL,
        apiEndpoint: "ApplicationAYClassSectionsSubjectExamMarks",
        params:
          params +
          "&classSectionId=" +
          selClassSectionId.classSectionId +
          "&syllabusCombinationSubjectId=" +
          examId[0].syllabusCombinationSubjectId +
          "&examId=" +
          examId[0].examId +
          "&examDate=" +
          date,
      })
      .then((resp) => {
        setMarksData({ data: resp });
        emData = { data: resp };
      });
    await getCallMutation
      .mutateAsync({
        apiURL: appVariables.REACT_APP_API_URL,
        apiEndpoint: "ApplicationAYClassSections",
        params: params + "&classSectionId=" + selClassSectionId.classSectionId,
      })
      .then((resp) => {
        setClassSectionData({ data: resp });
        processData({ data: resp }, emData);
      });
  };
  const onSelectClassSectionSubjectExamSchedule = async (schedule) => {
    if (schedule === null) {
      return;
    }
    console.log(schedule)
    selectedFilters["examDate"] = JSON.parse(JSON.parse(schedule)).date;
    selectedFilters["examScheduleId"] = JSON.parse(JSON.parse(schedule)).id;
    setSelectedFilters(selectedFilters);
    console.log(selectedFilters)
    setExamDate(JSON.parse(JSON.parse(schedule)).date);
    let emData;
    await getCallMutation
      .mutateAsync({
        apiURL: appVariables.REACT_APP_API_URL,
        apiEndpoint: "ApplicationAYClassSectionsSubjectExamMarks",
        params:
          params +
          "&classSectionId=" +
          selClassSectionId.classSectionId +
          "&syllabusCombinationSubjectId=" +
          selectedFilters.syllabusCombinationSubjectId +
          "&examId=" +
          selectedFilters.examId +
          "&syllabusCombinationSubjectExamScheduleId=" +
          JSON.parse(JSON.parse(schedule)).id,
      })
      .then((resp) => {
        setMarksData({ data: resp });
        emData = { data: resp };
      });
    await getCallMutation
      .mutateAsync({
        apiURL: appVariables.REACT_APP_API_URL,
        apiEndpoint: "ApplicationAYClassSections",
        params: params + "&classSectionId=" + selClassSectionId.classSectionId,
      })
      .then((resp) => {
        setClassSectionData({ data: resp });
        processData({ data: resp }, emData);
      });
  };

  const processData = (data, emData) => {
    const cmData = [];
    data.data.formData.map((d) => {
      //const dData = { ...d };
      emData.data.formData.map((m) => {
        if (d.applicationAYClassSectionId === m.applicationAYClassSectionId) {
          d["marks"] = m.marks;
          d["grade"] = m.grade;
          d["notes"] = m.notes;
          d["applicationAYClassSectionsSubjectExamMarksId"] =
            m.applicationAYClassSectionsSubjectExamMarksId;
        }
      });
      cmData.push(d);
    });

    setClassSectionMarksData({ data: { formData: cmData } });
  };

  const handleSubmit = async (e, mData) => {
    e.preventDefault();

    console.log(selectedFilters)
    const data = new FormData(e.target);
    //const examSchedule = JSON.parse(JSON.parse(examDate))
    //const formData={...createData,marks:data.get('marks'),grade:data.get("grade"),notes:data.get("notes")}
    const formsData = {
      applicationAYClassSectionId: data.get("applicationAYClassSectionId"),
      classSectionId: selClassSectionId.classSectionId,
      syllabusCombinationId: selSubjectId[0].syllabusCombinationId,
      syllabusCombinationSubjectId: selectedFilters.syllabusCombinationSubjectId,
      subjectId: selSubjectId[0].subjectId,
      syllabusCombinationSubjectExamId:
      selectedFilters.subjectExamId,
      examId: selectedFilters.examId,
      examDate: selectedFilters.examDate,
      marks: data.get("marks"),
      grade: data.get("grade"),
      notes: data.get("notes"),
      syllabusCombinationSubjectExamScheduleId:selectedFilters.examScheduleId
    };
//console.log(formsData)
    await createCallMutation.mutateAsync({
      url: appVariables.REACT_APP_API_URL,
      apiEndpoint: modSchema.formAPI,
      data: formsData,
      messageTitle: modSchema.message.title,
      message: modSchema.message.message,
      params: params,
      isMultipart: true,
    });
  };

  const handleUpdateSubmit = async (e, mData) => {
    e.preventDefault();

    const data = new FormData(e.target);

    //const formData={...createData,marks:data.get('marks'),grade:data.get("grade"),notes:data.get("notes")}
    const formsData = {
      applicationAYClassSectionId: data.get("applicationAYClassSectionId"),
      classSectionId: selClassSectionId.classSectionId,
      syllabusCombinationId: selSubjectId[0].syllabusCombinationId,
      syllabusCombinationSubjectId: examId[0].syllabusCombinationSubjectId,
      subjectId: selSubjectId[0].subjectId,
      syllabusCombinationSubjectExamId:
        examId[0].syllabusCombinationSubjectExamId,
      examId: examId[0].examId,
      examDate: JSON.parse(JSON.parse(examDate)).date,
      marks: data.get("marks"),
      grade: data.get("grade"),
      notes: data.get("notes"),
      syllabusCombinationSubjectExamScheduleId:JSON.parse(JSON.parse(examDate)).id
    };

    await updateCallMutation.mutateAsync({
      url: appVariables.REACT_APP_API_URL,
      apiEndpoint: modSchema.formAPI,
      data: formsData,
      id: data.get("applicationAYClassSectionsSubjectExamMarksId"),
      messageTitle: modSchema.message.title,
      message: modSchema.message.message,
      params: params,
      isMultipart: true,
    });
  };

  try {
    return (
      <div className="contentbar">
        <div className="row">
          <form className="row" onSubmit={(e) => getOnchangeAppl(e)}>
            <div key="academicYearId" className="col-md-3">
              <select
                className="form-control"
                name="academiYearId"
                onChange={(e) => {
                  onSelectAcademicYear(e.target.value);
                }}
                disabled={
                  authData.dependeciesFormAPIS.academicYears?.formData.length >
                  0
                    ? false
                    : true
                }
              >
                <option>
                  {authData.dependeciesFormAPIS.academicYears?.formData.length >
                  0
                    ? "Select Academic Year"
                    : "Loading..."}
                </option>
                {authData.dependeciesFormAPIS.academicYears?.formData.length >
                  0 &&
                  authData.dependeciesFormAPIS.academicYears?.formData.map(
                    (ops, opsInd) => (
                      <option key={opsInd} value={ops.academicYearId}>
                        {ops.academicYearName}
                      </option>
                    )
                  )}
              </select>
            </div>
            <div key="classSectionId" className="col-md-3">
              <select
                className="form-control"
                name="classSectionId"
                onChange={(e) => {
                  onSelectClassSection(e.target.value);
                }}
                disabled={
                  authData.dependeciesFormAPIS.classSections?.formData.length >
                  0
                    ? false
                    : true
                }
              >
                <option>
                  {authData.dependeciesFormAPIS.classSections?.formData.length >
                  0
                    ? "Select Class Section"
                    : "Loading..."}
                </option>
                {authData.dependeciesFormAPIS.classSections?.formData.length >
                  0 &&
                  authData.dependeciesFormAPIS.classSections?.formData.map(
                    (ops, opsInd) => (
                      <option key={opsInd} value={JSON.stringify({syllabusCombinationId:ops.syllabusCombinationId,classSectionId:ops.classSectionId})}>
                        {ops.classSectionName}
                      </option>
                    )
                  )}
              </select>
            </div>
            <div key="syllabusCombinationSubjectId" className="col-md-3">
              <select
                className="form-control"
                name="syllabusCombinationSubjectId"
                onChange={(e) => {
                  onSelectClassSectionSubject(e.target.value);
                }}
                disabled={
                  classSectionSyllabusClassCombinationsData !== null &&
                  classSectionSyllabusClassCombinationsData.formData.length > 0
                    ? false
                    : true
                }
              >
                <option>
                  {classSectionSyllabusClassCombinationsData !== null &&
                  classSectionSyllabusClassCombinationsData.formData.length > 0
                    ? "Select Subject"
                    : "Loading..."}
                </option>
                {classSectionSyllabusClassCombinationsData !== null &&
                  classSectionSyllabusClassCombinationsData.formData.length >
                    0 &&
                  classSectionSyllabusClassCombinationsData.formData.map(
                    (ops, opsInd) => (
                      <option
                        key={opsInd}
                        value={ops.syllabusCombinationSubjectId}
                      >
                        {ops.subjectName}
                      </option>
                    )
                  )}
              </select>
            </div>
            <div key="ExamId" className="col-md-3">
              <select
                className="form-control"
                name="examId"
                onChange={(e) => {
                  onSelectClassSectionSubjectExam(e.target.value);
                }}
                disabled={
                  syllabusCombinationSubjectExamsData !== null &&
                  syllabusCombinationSubjectExamsData.formData.length > 0
                    ? false
                    : true
                }
              >
                <option>
                  {syllabusCombinationSubjectExamsData !== null &&
                  syllabusCombinationSubjectExamsData.formData.length > 0
                    ? "Select Exam"
                    : "Loading..."}
                </option>
                {syllabusCombinationSubjectExamsData !== null &&
                  syllabusCombinationSubjectExamsData.formData.length > 0 &&
                  syllabusCombinationSubjectExamsData.formData.sort((a,b)=>a.examName-b.examName).map(
                    (ops, opsInd) => (
                      <option key={opsInd} value={JSON.stringify({subjectExamId:ops.syllabusCombinationSubjectExamId,examId:ops.examId})}>
                        {ops.examName}
                      </option>
                    )
                  )}
              </select>
            </div>
            <div key="examSchedule" className="col-md-3">
              <select
                className="form-control"
                name="examScheduleId"
                onChange={(e) => {
                  onSelectClassSectionSubjectExamSchedule(JSON.stringify(e.target.value));
                }}
                disabled={
                  examScheduleData !== null &&
                  examScheduleData.data.formData.length > 0
                    ? false
                    : true
                }
              >
                <option>
                  {examScheduleData !== null &&
                  examScheduleData.data.formData.length > 0
                    ? "Select Schedule"
                    : "Loading..."}
                </option>
                {examScheduleData !== null &&
                  examScheduleData.data.formData.length > 0 &&
                  examScheduleData.data.formData.map(
                    (ops, opsInd) => (
                      <option key={opsInd} value={JSON.stringify({id:ops.syllabusCombinationSubjectExamScheduleId,date:ops.examDate})}>
                        {ops.examName + " - " +dayjs(ops.examDate).format("DD-MM-YYYY")}
                        {console.log(ops)}
                      </option>
                    )
                  )}
              </select>
            </div>
          </form>
          <div className="col-md-12">
            <hr />
            <div className="b-all border-2 rounded border_Width-1">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="card b-all p-1 m-1"
                    style={{ "margin-bottom": "1px" }}
                  >
                    <div className="card-body" style={{ padding: "1px" }}>
                      <div className="row" key={classSectionMarksData}>
                        {classSectionMarksData !== null &&
                          classSectionMarksData.data.formData.length > 0 &&
                          classSectionMarksData.data.formData.map(
                            (mData, mDataInd) => (
                              <React.Fragment key={mDataInd}>
                                <div className="col-6">
                                  <h5 className="card-title">
                                    {mData.ApplicationAYClassSectionRollNumber}{" "}
                                    - {mData.userChildName}
                                  </h5>
                                </div>
                                <form
                                  className="row"
                                  onSubmit={(e) => {
                                    mData.applicationAYClassSectionsSubjectExamMarksId
                                      ? handleUpdateSubmit(e, mData)
                                      : handleSubmit(e, mData);
                                  }}
                                >
                                  <div
                                    style={{ display: "none" }}
                                    className="col-2"
                                  >
                                    <input
                                      type="text"
                                      name="applicationAYClassSectionId"
                                      value={mData.applicationAYClassSectionId}
                                      placeholder="Marks"
                                      className="form-control"
                                    />
                                  </div>
                                  {mData.applicationAYClassSectionsSubjectExamMarksId && (
                                    <div
                                      style={{ display: "none" }}
                                      className="col-2"
                                    >
                                      <input
                                        type="text"
                                        name="applicationAYClassSectionsSubjectExamMarksId"
                                        value={
                                          mData.applicationAYClassSectionsSubjectExamMarksId
                                        }
                                        placeholder="Marks"
                                        className="form-control"
                                      />
                                    </div>
                                  )}
                                  <div className="col-2">
                                    <input
                                      type="text"
                                      name="marks"
                                      placeholder="Marks"
                                      className="form-control"
                                      defaultValue={mData.marks}
                                    />
                                  </div>
                                  <div className="col-2">
                                    <input
                                      type="text"
                                      name="grade"
                                      placeholder="Grade"
                                      className="form-control"
                                      defaultValue={mData.grade}
                                    />
                                  </div>
                                  <div className="col-2">
                                    <input
                                      type="text"
                                      name="notes"
                                      placeholder="Notes"
                                      className="form-control"
                                      defaultValue={mData.notes}
                                    />
                                  </div>
                                  <div
                                    className="col-6 text-right"
                                    style={{
                                      "-webkit-text-align": "right",
                                      "text-align": "right",
                                    }}
                                  >
                                    <button
                                      type="submit"
                                      title="Present"
                                      className="btn btn-sm btn-success"
                                    >
                                      <i className="fa fa-check" />
                                    </button>
                                  </div>
                                </form>
                              </React.Fragment>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } catch (err) {
    return (
      <E500
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        favicon={favicon}
        error={err}
      />
    );
  }
};
