import React from "react";
import { Link } from "react-router-dom";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import logo from "../assets/images/logo.png";
import { mainUILoad, openModal, closeModal } from "../lib/uihelper";
import { ModalLogin } from "../modules/modal";
import { Maintenance } from "../modules/lib/maintenance";
import { E500 } from "../components/e500";
import * as uiHelper from "../lib/uihelper";
import * as Web from "./webcomps";
import httpParams from "../lib/httpParams";
import { useGetCall } from "../lib/api/get";
import { useCreateCall } from "../lib/api/create";
import { useDeleteCall } from "../lib/api/delete";
import { useGetSPAPageCall, useGetPageCall } from "../lib/api/get";

//import "../assets/css/styleHo.css";

import { CompBar } from "./webcomps/compbar";
import { PropertyBar } from "./webcomps/propertybar";
import { Seo } from "../components/seo";
import { ConfirmModal } from "../components/confirmModal";
import { Landing } from "../components/landing";
import { Navbar1T2 } from "./webcomps/theme2/Navbars/Navbar1T2";
import { Banner1T2 } from "./webcomps/theme2/Banners/Banner1T2";
import { Features1T2 } from "./webcomps/theme2/Features/Features1T2";
import { CallToAction7T2 } from "./webcomps/theme2/CallToAction/CallToAction7T2";
import { Footer1T2 } from "./webcomps/theme2/Footers/Footer1T2";
import { Contact1T2 } from "./webcomps/theme2/Contact/Contact1T2";
import { VideoImageGalleryT2 } from "./webcomps/theme2/Others/VideoImageGalleryT2";
//import { Banner3T2 } from "./webcomps/theme2/Banners/Banner3T2";

export const Website = ({
	authData,
	userLogout,
	primaryColor,
	secondaryColor,
	favicon,
	appVariables,
	routeData,
	webSettings,
	appSettings,
	seo,
}) => {
	mainUILoad(primaryColor, secondaryColor, favicon);

	const params = httpParams.uiParams(appVariables);
	const queryParams = new URLSearchParams(window.location.search);
	const editMode = queryParams.get("edit");
	const authSession = localStorage.getItem("authenticateSession") || null;
	const [loginModal, setLoginModal] = React.useState(false);
	const [userRoles, setUserRoles] = React.useState(false);
	const [showEditingControls, setShowEditingControls] = React.useState(false);
	const [showCompBar, setShowCompBar] = React.useState(false);
	const [showPropBar, setShowPropBar] = React.useState(false);
	const [compData, setCompData] = React.useState(null);
	const [textEditContent, setTextEditorContent] = React.useState(null);

	const createCallMutation = useCreateCall();
	const deleteCallMutation = useDeleteCall();

	const [deleteId, setDeleteId] = React.useState([]);
	const [deleteConfirm, setDeleteConfirm] = React.useState(false);

	const listQuery = useGetPageCall(
		appVariables.REACT_APP_API_URL,
		"pageData",
		routeData.pageId,
		routeData.name,
		params,
		true
	);
	const listSPAQuery = useGetSPAPageCall(
		appVariables.REACT_APP_API_URL,
		"pageData",
		routeData.name,
		params,
		webSettings["webSettings-defaultSPAPage"]
	);

	const elemTypes = useGetCall(
		appVariables.REACT_APP_API_URL,
		"ElementType",
		params,
		false,
		queryParams.get("edit") && JSON.parse(authSession).role === "AppAdmin"
			? true
			: false
	);

	const confirmDelete = (id) => {
		setDeleteId(id);
		setDeleteConfirm(true);
	};

	const openProps = (comp) => {
		setShowPropBar(true);
		setCompData(comp);
	};

	const onDeleteForm = async () => {
		await deleteCallMutation.mutateAsync({
			url: appVariables.REACT_APP_API_URL,
			apiEndpoint: "PageElement",
			id: deleteId[0],
			noConfirmation: true,
			messageTitle: "Page Element",
			message: "Page element deleted!",
			params,
		});
		await deleteCallMutation.mutateAsync({
			url: appVariables.REACT_APP_API_URL,
			apiEndpoint: "PageElement",
			id: deleteId[1],
			noConfirmation: true,
			messageTitle: "Page Element",
			message: "Page element deleted!",
			params,
		});
		await deleteCallMutation.mutateAsync({
			url: appVariables.REACT_APP_API_URL,
			apiEndpoint: "PageElement",
			id: deleteId[2],
			parentAPI: "pageData",
			messageTitle: "Page Element",
			message: "Page element deleted!",
			params,
		});
		setDeleteConfirm(false);
	};

	const Drops = ({ elemIndex }) => {
		//const [{ isOver, canDrop }, drop] = useDrop({
		//const [{ canDrop, isOver }, drop] = useDrop({
		const [, drop] = useDrop({
			accept: "elementType",
			drop: async (item, monitor) => {
				const rowFormObject = {};
				rowFormObject["parentPageElementId"] = null;
				rowFormObject["pageId"] = routeData.pageId;
				rowFormObject["pageElementName"] = "Row";
				rowFormObject["appId"] = appVariables.REACT_APP_APP_ID;
				rowFormObject["tenantId"] = appVariables.REACT_APP_TENANT_ID;
				rowFormObject["orgId"] = appVariables.REACT_APP_ORG_ID;
				rowFormObject["elementTypeName"] = "Row";
				rowFormObject["elementTypeId"] = "86ECAB37-5659-4B70-A71B-217D225FBCF7";
				rowFormObject["pageElementDisplayOrder"] = elemIndex;
				await createCallMutation
					.mutateAsync({
						url: appVariables.REACT_APP_API_URL,
						apiEndpoint: "PageElement",
						data: rowFormObject,
						noConfirmation: true,
						messageTitle: "Page Element",
						message: "Page element added",
						params: params,
					})
					.then(async (resp) => {
						const colFormObject = {};
						colFormObject["parentPageElementId"] = resp.formData.pageElementId;
						colFormObject["pageId"] = routeData.pageId;
						colFormObject["pageElementName"] = "Col";
						colFormObject["appId"] = appVariables.REACT_APP_APP_ID;
						colFormObject["tenantId"] = appVariables.REACT_APP_TENANT_ID;
						colFormObject["orgId"] = appVariables.REACT_APP_ORG_ID;
						colFormObject["elementTypeName"] = "Col";
						colFormObject["elementTypeId"] =
							"86ECAB37-5659-4B70-A71B-217D225FBCF7";
						colFormObject["pageElementDisplayOrder"] = 0;
						await createCallMutation
							.mutateAsync({
								url: appVariables.REACT_APP_API_URL,
								apiEndpoint: "PageElement",
								data: colFormObject,
								noConfirmation: true,
								messageTitle: "Page Element",
								message: "Page element added",
								params: params,
							})
							.then(async (resp) => {
								const formObject = { ...item };
								formObject["parentPageElementId"] = resp.formData.pageElementId;
								formObject["pageId"] = routeData.pageId;
								formObject["pageElementName"] = formObject.elementTypeName;
								formObject["appId"] = appVariables.REACT_APP_APP_ID;
								formObject["tenantId"] = appVariables.REACT_APP_TENANT_ID;
								formObject["orgId"] = appVariables.REACT_APP_ORG_ID;
								formObject["pageElementDisplayOrder"] = 0;
								await createCallMutation.mutateAsync({
									url: appVariables.REACT_APP_API_URL,
									apiEndpoint: "PageElement",
									data: formObject,
									parentAPI: "pageData",
									messageTitle: "Page Element",
									message: "Page element added",
									params: params + "pageName=" + routeData.name,
									refetch: true,
								});
							});
					});
			},
			collect: (monitor) => ({
				isOver: monitor.isOver(),
				canDrop: monitor.canDrop(),
			}),
		});
		return (
			<div className="builderbox" ref={drop}>
				<div className="builderhidden" style={{ textAlign: "center" }}>
					(Drop Here......)
				</div>
			</div>
		);
	};

	if (
		listQuery.isLoading ||
		listSPAQuery.isLoading ||
		elemTypes.isLoading ||
		elemTypes.isRefetching ||
		(!editMode && (listQuery.isRefetching || listSPAQuery.isRefetching))
	) {
		return (
			<>
				<div
					className="spinner-border"
					role="status"
					style={{
						position: "fixed",
						top: "50%",
						left: "50%",
						color: appVariables.REACT_APP_THEME_COLOR,
					}}
				>
					<span className="sr-only">Loading...</span>
				</div>
				<span className="sr-only">Loading...</span>
			</>
		);
	}
	try {
		let TagName;

		const pData = JSON.parse(listQuery.data?.formData?.pageData);

		const pDataSPA =
			listSPAQuery.data?.formData &&
			JSON.parse(listSPAQuery.data?.formData?.pageData);

		return (
			<DndProvider backend={HTML5Backend}>
				<div key={routeData.path} className="bg-white">
					<Seo
						appVariables={appVariables}
						title={routeData.pageTitle}
						location={routeData.path}
						webSettings={webSettings}
						seo={seo}
					/>

					{routeData.isSPA &&
						pDataSPA.length > 0 &&
						pDataSPA.map((row) =>
							row.childItems.map((col) =>
								col.childItems.map(
									(comp, compIndex) =>
										comp.className.className ===
											webSettings["webSettings-navbarComponent"] && (
											<React.Fragment key={compIndex}>
												{(TagName = Web[comp.className.className])}

												<TagName
													setLoginModal={setLoginModal}
													authSession={JSON.parse(authSession)}
													pageData={comp}
													userLogout={userLogout}
													primaryColor={primaryColor}
												/>
											</React.Fragment>
										)
								)
							)
						)}

					{pData.length > 0 &&
						pData.map((row, rIndex) =>
							row.childItems.map((col) =>
								col.childItems
									.sort(
										(a, b) =>
											a.pageElementDisplayOrder - b.pageElementDisplayOrder
									)
									.map((comp, compIndex) => (
										<div key={compIndex}>
											{(TagName = Web[comp.className.className])}
											{TagName ? (
												<>
													{comp.className.className !==
														webSettings["webSettings-navbarComponent"] &&
														editMode &&
														JSON.parse(authSession).role === "AppAdmin" && (
															<div className="row builderbox">
																<div className="col-md-12 builderbottomRow">
																	<button
																		className="btn btn-primary btn-sm"
																		style={{
																			marginLeft: "1rem",
																			marginTop:
																				rIndex === 0
																					? "10px !important"
																					: "0px",
																		}}
																		onClick={() => setShowCompBar(!showCompBar)}
																	>
																		<i className="fa fa-plus"></i>
																	</button>
																	<Drops elemIndex={rIndex - 1} />
																</div>
															</div>
														)}
													<div
														key={compIndex}
														className={
															editMode &&
															JSON.parse(authSession).role === "AppAdmin"
																? "builderdndcard w-100"
																: ""
														}
														style={{
															minHeight:
																editMode &&
																JSON.parse(authSession).role === "AppAdmin" &&
																comp.className.className ===
																	webSettings["webSettings-navbarComponent"]
																	? "6rem"
																	: "auto",
														}}
													>
														<div className="buildertopright">
															<button
																className="btn btn-danger btn-sm "
																onClick={() =>
																	confirmDelete([
																		row.pageElementId,
																		col.pageElementId,
																		comp.pageElementId,
																	])
																}
															>
																<i className="fa fa-trash"></i>
															</button>
														</div>
														<div className="buildertopleft">
															<span className="btn btn-primary btn-sm ">
																{comp.className.className}
															</span>
														</div>
														<div className="builderbottomright">
															<button
																className="btn btn-primary btn-sm"
																onClick={() => openProps(comp)}
															>
																<i className="fa fa-cog"></i>
															</button>
														</div>

														<TagName
															setLoginModal={setLoginModal}
															authSession={JSON.parse(authSession)}
															pageData={comp}
															appVariables={appVariables}
															userLogout={userLogout}
															primaryColor={primaryColor}
														/>
													</div>
													{comp.className.className !==
														webSettings["webSettings-footerComponent"] &&
														editMode &&
														JSON.parse(authSession).role === "AppAdmin" && (
															<div className="row builderbox">
																<div className="col-md-12 builderbottomRow">
																	<button
																		className="btn btn-primary btn-sm"
																		style={{ marginLeft: "1rem" }}
																		onClick={() => setShowCompBar(!showCompBar)}
																	>
																		<i className="fa fa-plus"></i>
																	</button>
																	<Drops elemIndex={rIndex + 1} />
																</div>
															</div>
														)}
												</>
											) : (
												<div>
													<div
														key={compIndex}
														className={
															editMode &&
															JSON.parse(authSession).role === "AppAdmin"
																? "builderdndcard w-100"
																: ""
														}
														style={{
															minHeight:
																editMode &&
																JSON.parse(authSession).role === "AppAdmin" &&
																comp.className.className ===
																	webSettings["webSettings-navbarComponent"]
																	? "150px"
																	: "auto",
															paddingBottom: "4rem",
														}}
													>
														<div className="buildertopright">
															<button
																className="btn btn-danger btn-sm "
																onClick={() =>
																	confirmDelete([
																		row.pageElementId,
																		col.pageElementId,
																		comp.pageElementId,
																	])
																}
															>
																<i className="fa fa-trash"></i>
															</button>
														</div>
														<div className="buildertopleft">
															<span className="btn btn-primary btn-sm ">
																{comp.className.className}
															</span>
														</div>
														<div className="builderbottomright">
															<button
																className="btn btn-primary btn-sm"
																onClick={() => openProps(comp)}
															>
																<i className="fa fa-cog"></i>
															</button>
														</div>
														Something went wrong
													</div>
												</div>
											)}
										</div>
									))
							)
						)}
					{editMode &&
						JSON.parse(authSession).role === "AppAdmin" &&
						pData.length <= 0 && (
							<>
								<div className="row builderbox pt-5">
									<div className="col-md-12 builderbottomRow mt-5 mb-5">
										<button
											className="btn btn-primary btn-sm"
											style={{
												marginLeft: "1rem",
												marginTop: "8rem !important",
											}}
											onClick={() => setShowCompBar(!showCompBar)}
										>
											<i className="fa fa-plus"></i>
										</button>
										<Drops elemIndex={0} />
									</div>
								</div>
							</>
						)}

					{routeData.isSPA &&
						pDataSPA.length > 0 &&
						pDataSPA.map((row) =>
							row.childItems.map((col) =>
								col.childItems.map(
									(comp, compIndex) =>
										comp.className.className ===
											webSettings["webSettings-footerComponent"] && (
											<React.Fragment key={compIndex}>
												{(TagName = Web[comp.className.className])}

												<TagName
													setLoginModal={setLoginModal}
													pageData={comp}
													userLogout={userLogout}
													primaryColor={primaryColor}
												/>
											</React.Fragment>
										)
								)
							)
						)}

					{!editMode && pData.length <= 0 && (
						<Landing
							primaryColor={primaryColor}
							secondaryColor={secondaryColor}
							favicon={favicon}
							authData={authData}
							userLogout={userLogout}
							appVariables={appVariables}
						/>
					)}

					{showCompBar && (
						<CompBar
							setShowCompBar={setShowCompBar}
							appVariables={appVariables}
							elemTypes={elemTypes}
							webSettings={webSettings}
						/>
					)}
					{showPropBar && (
						<PropertyBar
							setShowPropBar={setShowPropBar}
							appVariables={appVariables}
							compData={compData}
							pageId={routeData.pageId}
							pageName={routeData.name.replace(" ", "")}
							elemTypes={elemTypes}
							appSettings={appSettings}
							webSettings={webSettings}
						/>
					)}
					{loginModal && (
						<ModalLogin
							closeModal={uiHelper.closeModal}
							setLoginModal={setLoginModal}
							primaryColor={primaryColor}
							secondaryColor={secondaryColor}
							favicon={favicon}
							appVariables={appVariables}
						/>
					)}
					{deleteConfirm && (
						<ConfirmModal
							onDelete={onDeleteForm}
							setDeleteConfirm={setDeleteConfirm}
							setDeleteId={setDeleteId}
							primaryColor={primaryColor}
							secondaryColor={secondaryColor}
							favicon={favicon}
						/>
					)}
				</div>
			</DndProvider>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
