import React from "react";
import { default as dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import _ from "lodash";

import httpParams from "../../../lib/httpParams";
import { useGetCall } from "../../../lib/api/get";

import "./report.css";

dayjs.extend(utc);
dayjs.extend(timezone);

export const FeeStatement = ({
	feeStatementData,feeStatementId,
  setFeeStatementId,
  setFeeStatement,
  appVariables,isApplicationLevel,modSchema,filterById
}) => {
  const params = httpParams.adminOrgParams(appVariables);
  const [feeRs, setFeeRs] = React.useState(null);
  const [totalFee, setTotalFee] = React.useState(0);

  const getData = useGetCall(
    appVariables.REACT_APP_API_URL,
    modSchema.isScholarshipStatement ?"scholarshipDistributions":"FeeReceipt",
    params + `&applicationId=${feeStatementId}`,
    false,
    true
  );

  if (getData.isLoading || getData.isRefetching) {
    return (
      <div
        className={`modal fade bd-example-modal-lg show`}
        style={{ display: "block", backgroundColor: "rgba(55,55,55,0.3)" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body  pt-2">Loading...</div>
          </div>
        </div>
      </div>
    );
  }

  const stmtData = getData.data?.formData.length >0 && getData.data?.formData.filter((f)=>f.applicationId===filterById)

  const getAcademicYears = () => {
    const academicYears =
      localStorage.getItem("authent") &&
      JSON.parse(localStorage.getItem("authent"));
    return academicYears.state.dependeciesFormAPIS.academicYears;
  };

  const getOnchangeAppl = (e) => {
    const fltArr = [];
	setTotalFee(0)
    getData.data?.formData?.map((f) => {
      if (
        f.ApplicationAYClassSection &&
        f.ApplicationAYClassSection.academicYearId &&
        f.ApplicationAYClassSection.academicYearId !== null &&
        f.ApplicationAYClassSection.academicYearId === e
      ) {
		setTotalFee(totalFee+f.feeAmount)
        fltArr.push(f);
      }
    });
    setFeeRs(fltArr);
  };

  if(modSchema.isScholarshipStatement){
    return(
      <div
      className={`modal fade bd-example-modal-lg show`}
      style={{ display: "block", backgroundColor: "rgba(55,55,55,0.3)" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-body  pt-2" id="report-to-print-fr">
            <button
              type="button"
              id="close-button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setFeeStatementId(null);
                setFeeStatement(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            
            <button
              id="print-button"
              type="button"
              onClick={() => window.print()}
              className="btn btn-primary-rgba py-1 font-16"
            >
              <i className="fa fa-print mr-2" />
              Print
            </button>
            <div className="table-responsive">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="text-center" colSpan={5}>
                      <h3 style={{ textDecoration: "underline" }}>
                        Scholarship Statement
                      </h3>
                    </td>
                  </tr>
                  <tr>
                    <td className="mt-2" colSpan={4}>
                      <b>
                        Student Name:{" "}
                        {
                          getData.data?.formData[0]?.Application
                            ?.applicationName
                        }
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td className="mt-2">Date</td>
                    <td className="mt-2">Amount</td>
                    <td className="mt-2">Payment Mode</td>
                    <td className="mt-2">Paid To</td>
                    <td className="mt-2">Notes</td>
                  </tr>
                  {stmtData && stmtData.map((data, dataIndex)=>
                    <tr key={dataIndex}>
                    <td className="mt-2">
                      {dayjs(data.scholarshipDistributionDate).format("DD-MM-YYYY")}
                    </td>
                    <td className="mt-2">{data.scholarshipDistributionAmount.toFixed(2)}</td>
                    <td className="mt-2">{data.paymentMethod}</td>
                    <td className="mt-2">{data.scholarshipDistributionTo}</td>
                    <td className="mt-2">{data.scholarshipDistributionNotes}</td>
                  </tr>
                  )}
                </tbody>
              </table>
              {/* {getData.data?.formData && getData.data?.formData > 0 ? (
							) : (
								"No Data!"
							)} */}
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }else{
  return (
    <div
      className={`modal fade bd-example-modal-lg show`}
      style={{ display: "block", backgroundColor: "rgba(55,55,55,0.3)" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-body  pt-2" id="report-to-print-fr">
            <button
              type="button"
              id="close-button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setFeeStatementId(null);
                setFeeStatement(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <select
              className="form-control mb-2 mt-3"
              id="select-dd"
              name="class"
              onChange={(e) => {
                getOnchangeAppl(e.target.value);
              }}
            >
              <option title="S C">Select Academic Year</option>
              {getAcademicYears().formData.map((opt, optIndex) => (
                <option value={opt.academicYearId} key={optIndex}>
                  {opt.academicYearName}
                </option>
              ))}
            </select>
            <button
              id="print-button"
              type="button"
              onClick={() => window.print()}
              className="btn btn-primary-rgba py-1 font-16"
            >
              <i className="fa fa-print mr-2" />
              Print
            </button>
            <div className="table-responsive">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="text-center" colSpan={5}>
                      <h3 style={{ textDecoration: "underline" }}>
                        School Fee Statement
                      </h3>
                    </td>
                  </tr>
                  <tr>
                    <td className="mt-2" colSpan={4}>
                      <b>
                        Student Name:{" "}
                        {
                          getData.data?.formData[0]?.Application
                            ?.applicationName
                        }
                      </b>
                    </td>
                    <td className="mt-2" colSpan={3}>
                      <b>
                        Total Fees:{" "}
                        {
                          getData.data?.formData[0]?.ApplicationAYClassSection
                            ?.AY_TotalFee
                        }
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td className="mt-2">Date</td>
                    <td className="mt-2">Type</td>
                    <td className="mt-2">Amount</td>
                    <td className="mt-2">Payment Mode</td>
                    <td className="mt-2">Paid By</td>
                  </tr>
                  {feeRs !== null &&
                    feeRs.map((data, dataIndex) => (
                      <tr key={dataIndex}>
                        <td className="mt-2">
                          {dayjs(data.feeReceivedDate).format("DD-MM-YYYY")}
                        </td>
                        <td className="mt-2">{data.feeType}</td>
                        <td className="mt-2">{data.feeAmount}</td>
                        <td className="mt-2">{data.paymentMethod}</td>
                        <td className="mt-2">{data.feeReceivedFrom}</td>
                      </tr>
                    ))}
                  <tr>
                    <td colSpan={2} className="trtui-mt-2">
                      <strong>Total Amount Paid</strong>
                    </td>
                    <td className="trtui-mt-2">
                      <strong>{totalFee.toFixed(2)}</strong>
                    </td>
                    <td className="trtui-mt-2">
                      <strong>Balance</strong>
                    </td>
                    <td className="trtui-mt-2">
                      <strong>
                        {(feeStatementData?.AY_TotalFee - totalFee).toFixed(2)}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* {getData.data?.formData && getData.data?.formData > 0 ? (
							) : (
								"No Data!"
							)} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );}
};
