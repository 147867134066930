import React from "react";
import { E500 } from "../../components/e500";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Y from "yup";

import { Pagination } from "../pagination";
import httpParams from "../../lib/httpParams";
import { useGetMutateCall } from "../../lib/api/get";
import { useCreateCall } from "../../lib/api/create";
import { useUpdateCall } from "../../lib/api/update";
import { useDeleteCall } from "../../lib/api/delete";
import { createYupSchema } from "../../lib/createYupSchema";
import { ExamScheduleAdd } from "./examScheduleAdd";
import { ExamScheduleUpdate } from "./examScheduleUpdate";
import { Form } from "../../components/form";

export const ExamSchedule = ({
  setMainCrudAdd,
  mainCrudAdd,
  closeAddMainCrud,
  modSchema,
  currentPage,
  totalCount,
  siblingCount,
  pageSize,
  filteredData,
  handlePage,
  getData,
  appVariables,
  uiHelper,
  setAllData,
  allData,
  primaryColor,
  secondaryColor,
  favicon,
  authData,
}) => {
  const params = httpParams.adminOrgParams(appVariables);
  const getCallMutation = useGetMutateCall();
  const createCallMutation = useCreateCall();
  const updateCallMutation = useUpdateCall();
  const deleteCallMutation = useDeleteCall();

  const authSession = JSON.parse(localStorage.getItem("authenticateSession"));
  const authentData = JSON.parse(localStorage.getItem("authent"));

  const [modalFormCollapse, setModalFormCollapse] = React.useState(null);
  const [modalFormSchema, setModalFormSchema] = React.useState(null);
  const [modalFormAdd, setModalFormAdd] = React.useState(false);
  const [isAdd, setIsAdd] = React.useState(true);

  const [value, setValue] = React.useState(0);

  const [subjectOps, setSubjectOps] = React.useState(null);
  const [subjectExamOps, setSubjectExamOps] = React.useState(null);

  const validation = Y.object().shape({
    ...modSchema.form.update.buildValidation.reduce(createYupSchema, {}),
  });

  const validationCreate = Y.object().shape({
    ...modSchema.form.create.buildValidation.reduce(createYupSchema, {}),
  });

  const useForceUpdate=(p)=>{
    handlePage(p)
    setValue(value => value + 1)
}

  //const forceUpdate = useForceUpdate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: mainCrudAdd ? "onSubmit" : "onChange",
    resolver: yupResolver(mainCrudAdd ? validationCreate : validation),
  });

  const getSubjects = async (e) => {
    if (e === "Select Class Section...") {
      return;
    }
    await getCallMutation
      .mutateAsync({
        apiURL: appVariables.REACT_APP_API_URL,
        apiEndpoint: "ClassSectionSyllabusClassCombinations/subjects",
        params: params + "&classSectionId=" + e,
      })
      .then((resp) => {
        setSubjectOps(resp);
      });
  };

  const getSubjectExams = async (e) => {
    if (e === "Select Class Section...") {
      return;
    }
    await getCallMutation
      .mutateAsync({
        apiURL: appVariables.REACT_APP_API_URL,
        apiEndpoint: "syllabusCombinationSubjects",
        params: params + "&syllabusCombinationSubjectId=" + e,
      })
      .then((resp) => {
        setSubjectExamOps(resp);
      });
  };

  const onCreate = async (data, e) => {
    let formData = data;
    formData["appId"] = appVariables.REACT_APP_APP_ID;
    formData["tenantId"] = appVariables.REACT_APP_TENANT_ID;
    formData["orgId"] = appVariables.REACT_APP_ORG_ID;
    await createCallMutation.mutateAsync({
      url: appVariables.REACT_APP_API_URL,
      apiEndpoint: modSchema.form.create.formAPI,
      data: formData, //data,
      messageTitle: modSchema.form.create.message.title,
      message: modSchema.form.create.message.message,
      params: params,
    });
  };

  const onUpdate = async (data) => {
    console.log(data);
    let formData = data;
    formData["appId"] = appVariables.REACT_APP_APP_ID;
    formData["tenantId"] = appVariables.REACT_APP_TENANT_ID;
    formData["orgId"] = appVariables.REACT_APP_ORG_ID;
    formData["examStartTime"] = new Date(
      formData.examDate + " " + formData.examStartTime
    );
    formData["examEndTime"] = new Date(
      formData.examDate + " " + formData.examEndTime
    );
    console.log(formData);
    await updateCallMutation.mutateAsync({
      url: appVariables.REACT_APP_API_URL,
      apiEndpoint: modSchema.form.update.formAPI,
      data: formData, //data,
      id: formData[modSchema.form.update.id],
      messageTitle: modSchema.form.update.message.title,
      message: modSchema.form.update.message.message,
      params: params,
    });
  };

  const onDelete = async (id) => {
    await deleteCallMutation.mutateAsync({
      url: appVariables.REACT_APP_API_URL,
      apiEndpoint: modSchema.form.update.formAPI,
      parentAPI: modSchema.form.update.parentAPI,
      id: id,
      messageTitle: modSchema.form.update.deleteMessage.title,
      message: modSchema.form.update.deleteMessage.message,
      params,
    });
  };

  try {
    return (
      <div className="contentbar">
        <div className="row">
          {mainCrudAdd ? (
            <ExamScheduleAdd
              key={mainCrudAdd}
              appVariables={appVariables}
              authData={authData}
              moduleSchema={modSchema}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              favicon={favicon}
              modSchema={modSchema}
              closeAddMainCrud={closeAddMainCrud}
              setMainCrudAdd={setMainCrudAdd}
            />
          ) : (
            // <div className="col-md-12" key={getData.data.formData[currentPage-1]}>
            //   <div className="card m-b-30">
            //     <div className="card-body">
            //       {getData.data.formData.length > 0 ? (
            //         <div
            //           key={`${modSchema.moduleName}${currentPage}${getData.data.formData}update`}
            //         >
            //           <form
            //             className="row"
            //             onSubmit={handleSubmit(
            //               mainCrudAdd ? onCreate : onUpdate
            //             )}
            //             key={currentPage}
            //           >
            //             <div
            //               className="form-group col-md-6"
            //               style={{ display: "none" }}
            //               key={`${currentPage}syllabusCombinationSubjectExamScheduleId`}
            //             >
            //               <label htmlFor="inputEmail4">Exam Schedule</label>
            //               <div className="input-group">
            //                 <input
            //                   type="text"
            //                   className="form-control undefined"
            //                   placeholder="Exam Schedule"
            //                   {...register("syllabusCombinationSubjectExamScheduleId")}
            //                   style={{ color: "rgb(0, 0, 0)" }}
            //                   defaultValue={getData.data.formData[currentPage - 1].syllabusCombinationSubjectExamScheduleId}
            //                   key={`${currentPage}syllabusCombinationSubjectExamScheduleId`}
            //                 />
            //               </div>
            //               {errors["syllabusCombinationSubjectExamScheduleId"] && (
            //                 <div className="invalid-feedback">
            //                   {errors["syllabusCombinationSubjectExamScheduleId"].message}
            //                 </div>
            //               )}
            //             </div>
            //             <div
            //               className="form-group col-md-4"
            //               style={{ display: "block" }}
            //               key={
            //                 authentData.state.dependeciesFormAPIS.academicYears
            //               }
            //             >
            //               <label className="form-control-label">
            //                 Academic Year
            //               </label>
            //               <select
            //                 className="form-control"
            //                 {...register("academicYearId")}
            //                 style={{ color: "rgb(0, 0, 0)" }}
            //                 defaultValue={
            //                   getData.data.formData[currentPage - 1]
            //                     .academicYearId
            //                 }
            //                 key={`${currentPage}academicYearId`}
            //               >
            //                 <option>Select Academic Year...</option>
            //                 {authentData.state.dependeciesFormAPIS.academicYears?.formData.map(
            //                   (ops, opsInd) => (
            //                     <>
            //                       {getData.data.formData[currentPage - 1]
            //                         .academicYearId === ops.academicYearId ? (
            //                         <option
            //                           key={opsInd}
            //                           selected
            //                           value={ops.academicYearId}
            //                         >
            //                           {ops.academicYearName}
            //                         </option>
            //                       ) : (
            //                         <option
            //                           key={opsInd}
            //                           value={ops.academicYearId}
            //                         >
            //                           {ops.academicYearName}
            //                         </option>
            //                       )}
            //                     </>
            //                   )
            //                 )}
            //               </select>
            //               {errors[""] && (
            //                 <div className="invalid-feedback">
            //                   {errors[""].message}
            //                 </div>
            //               )}
            //             </div>
            //             <div
            //               className="form-group col-md-4"
            //               style={{ display: "block" }}
            //             >
            //               <label className="form-control-label">
            //                 Class Section
            //               </label>
            //               <select
            //                 className="form-control undefined"
            //                 {...register("classSectionId")}
            //                 style={{ color: "rgb(0, 0, 0)" }}
            //                 onChange={(e) => getSubjects(e.target.value)}
            //                 defaultValue={
            //                   getData.data.formData[currentPage - 1]
            //                     .classSectionId
            //                 }
            //                 key={`${currentPage}classSectionId`}
            //               >
            //                 <option>Select Class Section...</option>
            //                 {authentData.state.dependeciesFormAPIS.classSections.formData.map(
            //                   (ops, opsInd) => (
            //                     <>
            //                       {getData.data.formData[currentPage - 1]
            //                         .classSectionId === ops.classSectionId ? (
            //                         <option
            //                           key={opsInd}
            //                           selected
            //                           value={ops.classSectionId}
            //                         >
            //                           {ops.classSectionName}
            //                         </option>
            //                       ) : (
            //                         <option
            //                           key={opsInd}
            //                           value={ops.classSectionId}
            //                         >
            //                           {ops.classSectionName}
            //                         </option>
            //                       )}
            //                     </>
            //                   )
            //                 )}
            //               </select>
            //               {errors["classSectionId"] && (
            //                 <div className="invalid-feedback">
            //                   {errors["classSectionId"].message}
            //                 </div>
            //               )}
            //             </div>
            //             <div
            //               className="form-group col-md-4"
            //               style={{ display: "block" }}
            //             >
            //               <label className="form-control-label">Subject</label>
            //               <select
            //                 className="form-control undefined"
            //                 {...register("syllabusCombinationSubjectId")}
            //                 style={{ color: "rgb(0, 0, 0)" }}
            //                 disabled={subjectOps === null ? true : false}
            //                 onChange={(e) => getSubjectExams(e.target.value)}
            //                 defaultValue={
            //                   getData.data.formData[currentPage - 1]
            //                     .syllabusCombinationSubjectId
            //                 }
            //                 key={`${currentPage}syllabusCombinationSubjectId`}
            //               >
            //                 <option>Select Subject...</option>
            //                 {subjectOps !== null &&
            //                   subjectOps.formData?.map((ops, opsInd) => (
            //                     <>
            //                       {getData.data.formData[currentPage - 1]
            //                         .syllabusCombinationSubjectId ===
            //                       ops.syllabusCombinationSubjectId ? (
            //                         <option
            //                           key={opsInd}
            //                           selected
            //                           value={ops.syllabusCombinationSubjectId}
            //                         >
            //                           {ops.subjectName}
            //                         </option>
            //                       ) : (
            //                         <option
            //                           key={opsInd}
            //                           value={ops.syllabusCombinationSubjectId}
            //                         >
            //                           {ops.subjectName}
            //                         </option>
            //                       )}
            //                     </>
            //                   ))}
            //               </select>
            //               {errors["syllabusCombinationSubjectId"] && (
            //                 <div className="invalid-feedback">
            //                   {errors["syllabusCombinationSubjectId"].message}
            //                 </div>
            //               )}
            //             </div>
            //             <div
            //               className="form-group col-md-4"
            //               style={{ display: "block" }}
            //             >
            //               <label className="form-control-label">
            //                 Subject Exam
            //               </label>
            //               <select
            //                 className="form-control undefined"
            //                 {...register("syllabusCombinationSubjectExamId")}
            //                 style={{ color: "rgb(0, 0, 0)" }}
            //                 disabled={subjectExamOps === null ? true : false}
            //                 defaultValue={
            //                   getData.data.formData[currentPage - 1]
            //                     .syllabusCombinationSubjectExamId
            //                 }
            //                 key={`${currentPage}syllabusCombinationSubjectExamId`}
            //               >
            //                 <option>Select Subject Exam...</option>
            //                 {subjectExamOps !== null &&
            //                   subjectExamOps.formData[
            //                     0
            //                   ]?.rowFormData.map((ops, opsInd) => (
            //                     <>
            //                       {getData.data.formData[currentPage - 1]
            //                         .syllabusCombinationSubjectExamId ===
            //                       ops.syllabusCombinationSubjectExamId ? (
            //                         <option
            //                           key={opsInd}
            //                           selected
            //                           value={
            //                             ops.syllabusCombinationSubjectExamId
            //                           }
            //                         >
            //                           {ops.syllabusCombinationSubjectExamId}
            //                         </option>
            //                       ) : (
            //                         <option
            //                           key={opsInd}
            //                           value={
            //                             ops.syllabusCombinationSubjectExamId
            //                           }
            //                         >
            //                           {ops.syllabusCombinationSubjectExamId}
            //                         </option>
            //                       )}
            //                     </>
            //                   ))}
            //               </select>
            //               {errors["syllabusCombinationSubjectExamId"] && (
            //                 <div className="invalid-feedback">
            //                   {
            //                     errors["syllabusCombinationSubjectExamId"]
            //                       .message
            //                   }
            //                 </div>
            //               )}
            //             </div>
            //             <div
            //               className="form-group col-md-6"
            //               style={{ display: "block" }}
            //               key={`${currentPage}examDate`}
            //             >
            //               <label htmlFor="inputEmail4">Exam Date</label>
            //               <div className="input-group">
            //                 <input
            //                   type="date"
            //                   className="form-control undefined"
            //                   placeholder="Exam Date"
            //                   {...register("examDate")}
            //                   style={{ color: "rgb(0, 0, 0)" }}
            //                   defaultValue={dayjs(
            //                     getData.data.formData[currentPage - 1].examDate
            //                   ).format("YYYY-MM-DD")}
            //                   key={`${currentPage}examDate`}
            //                 />
            //               </div>
            //               {errors["examDate"] && (
            //                 <div className="invalid-feedback">
            //                   {errors["examDate"].message}
            //                 </div>
            //               )}
            //             </div>
            //             <div
            //               className="form-group col-md-6"
            //               style={{ display: "block" }}
            //               key={`${currentPage}examStartTime`}
            //             >
            //               <label htmlFor="inputEmail4">Start Time</label>
            //               <div className="input-group">
            //                 <input
            //                   type="time"
            //                   className="form-control undefined"
            //                   placeholder="Start Time"
            //                   {...register("examStartTime")}
            //                   style={{ color: "rgb(0, 0, 0)" }}
            //                   defaultValue={dayjs(
            //                     getData.data.formData[currentPage - 1]
            //                       .examStartTime
            //                   ).format("H:mm")}
            //                   key={`${currentPage}examStartTime`}
            //                 />
            //                 {errors["examStartTime"] && (
            //                   <div className="invalid-feedback">
            //                     {errors["examStartTime"].message}
            //                   </div>
            //                 )}
            //               </div>
            //             </div>
            //             <div
            //               className="form-group col-md-6"
            //               style={{ display: "block" }}
            //               key={`${currentPage}examEndTime`}
            //             >
            //               <label htmlFor="inputEmail4">End Time</label>
            //               <div className="input-group">
            //                 <input
            //                   type="time"
            //                   className="form-control undefined"
            //                   placeholder="End Time"
            //                   {...register("examEndTime")}
            //                   style={{ color: "rgb(0, 0, 0)" }}
            //                   defaultValue={dayjs(
            //                     getData.data.formData[currentPage - 1]
            //                       .examEndTime
            //                   ).format("H:mm")}
            //                   key={`${currentPage}examEndTime`}
            //                 />
            //                 {errors["examEndTime"] && (
            //                   <div className="invalid-feedback">
            //                     {errors["examEndTime"].message}
            //                   </div>
            //                 )}
            //               </div>
            //             </div>
            //             <div
            //               className="form-group col-md-12"
            //               style={{ display: "block" }}
            //             >
            //               <button
            //                 type="submit"
            //                 className="btn btn-success"
            //                 disabled={updateCallMutation.isLoading}
            //               >
            //                 {updateCallMutation.isLoading ? (
            //                   <>
            //                     <div
            //                       className="spinner-border"
            //                       style={{
            //                         height: "1rem",
            //                         width: "1rem",
            //                         border: "0.15em solid currentColor",
            //                         borderRightColor: "transparent",
            //                         marginBottom: "0.2rem",
            //                       }}
            //                       role="status"
            //                     >
            //                       <span className="sr-only">Loading...</span>
            //                     </div>{" "}
            //                     Updating
            //                   </>
            //                 ) : "Update"}
            //               </button>
            //               <button
            //                 type="button"
            //                 className="btn btn-danger ml-2"
            //                 disabled={deleteCallMutation.isLoading}
            //               >
            //                 {deleteCallMutation.isLoading && (
            //                   <>
            //                     <div
            //                       className="spinner-border"
            //                       style={{
            //                         height: "1rem",
            //                         width: "1rem",
            //                         border: "0.15em solid currentColor",
            //                         borderRightColor: "transparent",
            //                         marginBottom: "0.2rem",
            //                       }}
            //                       role="status"
            //                     >
            //                       <span className="sr-only">Loading...</span>
            //                     </div>{" "}
            //                   </>
            //                 )}
            //                 Delete
            //               </button>
            //             </div>
            //           </form>
            //           {mainCrudAdd !== true && (
            //             <div className="float-right mt-2">
            //               <Pagination
            //                 currentPage={currentPage}
            //                 totalCount={
            //                   modSchema.filterBy
            //                     ? filteredData.length
            //                     : getData.data.formData.length
            //                 }
            //                 siblingCount={siblingCount}
            //                 pageSize={pageSize}
            //                 onPageChange={handlePage}
            //                 align="justify-content-end"
            //                 primaryColor={primaryColor}
            //                 secondaryColor={secondaryColor}
            //                 favicon={favicon}
            //               />
            //             </div>
            //           )}
            //         </div>
            //       ) : (
            //         <div>No Records Found!</div>
            //       )}
            //     </div>
            //   </div>
            // </div>
            <div className="col-md-12">
              <div className="card m-b-30">
                <div className="card-body" key={value}>
                  {getData.data.formData.length > 0 ? (
                    <>
                      {/* <ExamScheduleUpdate
                        key={`${getData.data.formData[currentPage - 1]}update`}
                        getData={getData.data.formData[currentPage - 1]}
                        currentPage={currentPage}
                        modSchema={modSchema}
                        handleSubmit={handleSubmit}
                        mainCrudAdd={mainCrudAdd}
                        onUpdate={onUpdate}
                        register={register}
                        deleteCallMutation={deleteCallMutation}
                        handlePage={handlePage}
                        errors={errors}
                        authentData={authentData}
                        primaryColor={primaryColor}
                        secondaryColor={secondaryColor}
                        favicon={favicon}
                        getSubjects={getSubjects}
                        subjectOps={subjectOps}
                        getSubjectExams={getSubjectExams}
                        subjectExamOps={subjectExamOps}
                        updateCallMutation={updateCallMutation}
                        filteredData={filteredData}
                        siblingCount={siblingCount}
                        filteredDataLength={filteredData.length}
                        getDataLength={getData.data.formData.length}
                        pageSize={pageSize}
                      /> */}
                      <Form
														key={`${modSchema.moduleName}${currentPage}${getData.data.formData}update`}
														defaultData={
															modSchema.filterBy
																? filteredData[currentPage - 1]
																: getData.data.formData[currentPage - 1]
														}
														formSchema={modSchema.form.update}
														modSchema={modSchema}
														appVariables={appVariables}
														crud={modSchema.form.crud}
														primaryColor={primaryColor}
														secondaryColor={secondaryColor}
														favicon={favicon}
													/>
                      {mainCrudAdd !== true && (
                        <div className="float-right mt-2">
                          <Pagination
                            currentPage={currentPage}
                            totalCount={
                              modSchema.filterBy
                                ? filteredData.length
                                : getData.data.formData.length
                            }
                            siblingCount={siblingCount}
                            pageSize={pageSize}
                            onPageChange={useForceUpdate}
                            align="justify-content-end"
                            primaryColor={primaryColor}
                            secondaryColor={secondaryColor}
                            favicon={favicon}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <div>No Records Found!</div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } catch (err) {
    return (
      <E500
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        favicon={favicon}
        error={err}
      />
    );
  }
};
