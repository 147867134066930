import React from "react";
import { E500 } from "../../components/e500";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Y from "yup";
import { default as dayjs } from "dayjs";

import httpParams from "../../lib/httpParams";
import { useGetMutateCall } from "../../lib/api/get";
import { useCreateCall } from "../../lib/api/create";
import { useUpdateCall } from "../../lib/api/update";
import { useDeleteCall } from "../../lib/api/delete";
import { createYupSchema } from "../../lib/createYupSchema";
import { Pagination } from "../pagination";

export const ExamScheduleAdd = ({
  setMainCrudAdd,
  mainCrudAdd,
  closeAddMainCrud,
  modSchema,
  currentPage,
  totalCount,
  siblingCount,
  pageSize,
  filteredData,
  handlePage,
  getData,
  appVariables,
  uiHelper,
  setAllData,
  allData,
  primaryColor,
  secondaryColor,
  favicon,
  authData,
}) => {
  const params = httpParams.adminOrgParams(appVariables);
  const getCallMutation = useGetMutateCall();
  const createCallMutation = useCreateCall();
  const updateCallMutation = useUpdateCall();
  const deleteCallMutation = useDeleteCall();

  const authSession = JSON.parse(localStorage.getItem("authenticateSession"));
  const authentData = JSON.parse(localStorage.getItem("authent"));

  const [modalFormCollapse, setModalFormCollapse] = React.useState(null);
  const [modalFormSchema, setModalFormSchema] = React.useState(null);
  const [modalFormAdd, setModalFormAdd] = React.useState(false);
  const [isAdd, setIsAdd] = React.useState(true);
  const [clsSectionId,setClsSectionId]=React.useState(null);

  const [subjectOps, setSubjectOps] = React.useState(null);
  const [subjectExamOps, setSubjectExamOps] = React.useState(null);

  const validation = Y.object().shape({
    ...modSchema.form.update.buildValidation.reduce(createYupSchema, {}),
  });

  const validationCreate = Y.object().shape({
    ...modSchema.form.create.buildValidation.reduce(createYupSchema, {}),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(validationCreate),
  });

  const getSubjects = async (e) => {
    if (e === "Select Class Section...") {
      return;
    }
    setSubjectOps(null)
    setSubjectExamOps(null)
    const parseData = JSON.parse(e)
    setClsSectionId(parseData.sectionId)
    await getCallMutation
      .mutateAsync({
        apiURL: appVariables.REACT_APP_API_URL,
        apiEndpoint: "syllabusCombinationSubjects",
        params: params + "&syllabusCombinationId=" + parseData.combinationId,
      })
      .then((resp) => {
        setSubjectOps(resp);
      });
  };

  const getSubjectExams = async (e) => {
    if (e === "Select Class Section...") {
      return;
    }
    setSubjectExamOps(null)
    await getCallMutation
      .mutateAsync({
        apiURL: appVariables.REACT_APP_API_URL,
        apiEndpoint: "syllabusCombinationSubjectExams",
        params: params + "&syllabusCombinationSubjectId=" + e,
      })
      .then((resp) => {
        setSubjectExamOps(resp);
      });
  };

  const onCreate = async (data, e) => {
    let formData = data;
    formData["appId"] = appVariables.REACT_APP_APP_ID;
    formData["tenantId"] = appVariables.REACT_APP_TENANT_ID;
    formData["orgId"] = appVariables.REACT_APP_ORG_ID;
    formData["examStartTime"]=`${formData.examDate} ${e.target.examStartTime.value}`
    formData["examEndTime"]=`${formData.examDate} ${e.target.examEndTime.value}`
    formData["classSectionId"]=clsSectionId
    //console.log(formData)
    await createCallMutation.mutateAsync({
      url: appVariables.REACT_APP_API_URL,
      apiEndpoint: modSchema.form.create.formAPI,
      data: formData, //data,
      messageTitle: modSchema.form.create.message.title,
      message: modSchema.form.create.message.message,
      params: params,
    }).then(()=>{
      closeAddMainCrud(setMainCrudAdd);
                          setMainCrudAdd(false);
    });
  };
  
  try {
    return (
      <div className="contentbar">
        <div className="row">
        <div className="col-md-12">
              <div className="card m-b-30">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-6">Add Schedule</div>
                    <div className="col-6 text-right">
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          closeAddMainCrud(setMainCrudAdd);
                          setMainCrudAdd(false);
                        }}
                      >
                        <i className="fa fa-times" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form
                    className="row"
                    onSubmit={handleSubmit(onCreate)}
                  >
                    <div
                      className="form-group col-md-4"
                      style={{ display: "block" }}
                      key={authentData.state.dependeciesFormAPIS.academicYears}
                    >
                      <label className="form-control-label">
                        Academic Year
                      </label>
                      <select
                        className="form-control"
                        {...register("academicYearId")}
                        style={{ color: "rgb(0, 0, 0)" }}
                      >
                        <option>Select Academic Year...</option>
                        {authentData.state.dependeciesFormAPIS.academicYears.formData.map(
                          (ops, opsInd) => (
                            <option key={opsInd} value={ops.academicYearId}>
                              {ops.academicYearName}
                            </option>
                          )
                        )}
                      </select>
                      {errors[""] && (
                        <div className="invalid-feedback">
                          {errors[""].message}
                        </div>
                      )}
                    </div>
                    <div
                      className="form-group col-md-4"
                      style={{ display: "block" }}
                    >
                      <label className="form-control-label">
                        Class Section
                      </label>
                      <select
                        className="form-control undefined"
                        {...register("classSectionId")}
                        style={{ color: "rgb(0, 0, 0)" }}
                        onChange={(e) => getSubjects(e.target.value)}
                      >
                        <option>Select Class Section...</option>
                        {authentData.state.dependeciesFormAPIS.classSections.formData.map(
                          (ops, opsInd) => (
                            <option key={opsInd} value={JSON.stringify({combinationId:ops.syllabusCombinationId,sectionId:ops.classSectionId})}>
                              {ops.classSectionName}
                            </option>
                          )
                        )}
                      </select>
                      {errors["classSectionId"] && (
                        <div className="invalid-feedback">
                          {errors["classSectionId"].message}
                        </div>
                      )}
                    </div>
                    <div
                      className="form-group col-md-4"
                      style={{ display: "block" }}
                    >
                      <label className="form-control-label">Subject</label>
                      <select
                        className="form-control undefined"
                        {...register("syllabusCombinationSubjectId")}
                        style={{ color: "rgb(0, 0, 0)" }}
                        disabled={subjectOps === null ? true : false}
                        onChange={(e) => getSubjectExams(e.target.value)}
                      >
                        <option>Select Subject...</option>
                        {subjectOps !== null &&
                          subjectOps.formData?.map((ops, opsInd) => (
                            <option
                              key={opsInd}
                              value={ops.syllabusCombinationSubjectId}
                            >
                              {ops.subjectName}
                            </option>
                          ))}
                      </select>
                      {errors["syllabusCombinationSubjectId"] && (
                        <div className="invalid-feedback">
                          {errors["syllabusCombinationSubjectId"].message}
                        </div>
                      )}
                    </div>
                    <div
                      className="form-group col-md-4"
                      style={{ display: "block" }}
                    >
                        {console.log(subjectExamOps)}
                      <label className="form-control-label">Subject Exam</label>
                      <select
                        className="form-control undefined"
                        {...register("syllabusCombinationSubjectExamId")}
                        style={{ color: "rgb(0, 0, 0)" }}
                        disabled={subjectExamOps === null ? true : false}
                        key={subjectExamOps}
                      >
                        <option>Select Subject Exam...</option>
                        {subjectExamOps !== null &&
                          subjectExamOps.formData.sort((a,b)=>a.examName-b.examName).map((ops, opsInd) => (
                            <option
                              key={opsInd}
                              value={ops.syllabusCombinationSubjectExamId}
                            >
                              {ops.examName}
                            </option>
                          ))}
                      </select>
                      {errors["syllabusCombinationSubjectExamId"] && (
                        <div className="invalid-feedback">
                          {errors["syllabusCombinationSubjectExamId"].message}
                        </div>
                      )}
                    </div>
                    <div
                      className="form-group col-md-6"
                      style={{ display: "block" }}
                    >
                      <label htmlFor="inputEmail4">Exam Date</label>
                      <div className="input-group">
                        <input
                          type="date"
                          className="form-control undefined"
                          placeholder="Exam Date"
                          {...register("examDate")}
                          style={{ color: "rgb(0, 0, 0)" }}
                        />
                      </div>
                      {errors["examDate"] && (
                        <div className="invalid-feedback">
                          {errors["examDate"].message}
                        </div>
                      )}
                    </div>
                    <div
                      className="form-group col-md-6"
                      style={{ display: "block" }}
                    >
                      <label htmlFor="inputEmail4">Start Time</label>
                      <div className="input-group">
                        <input
                          type="time"
                          className="form-control undefined"
                          placeholder="Start Time"
                          {...register("examStartTime")}
                          style={{ color: "rgb(0, 0, 0)" }}
                        />
                        {errors["examStartTime"] && (
                          <div className="invalid-feedback">
                            {errors["examStartTime"].message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="form-group col-md-6"
                      style={{ display: "block" }}
                    >
                      <label htmlFor="inputEmail4">End Time</label>
                      <div className="input-group">
                        <input
                          type="time"
                          className="form-control undefined"
                          placeholder="End Time"
                          {...register("examEndTime")}
                          style={{ color: "rgb(0, 0, 0)" }}
                        />
                        {errors["examEndTime"] && (
                          <div className="invalid-feedback">
                            {errors["examEndTime"].message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="form-group col-md-12"
                      style={{ display: "block" }}
                    >
                      <button
                        type="submit"
                        className="btn btn-success"
                        disabled={createCallMutation.isLoading}
                      >
                        {createCallMutation.isLoading && (
                          <>
                            <div
                              className="spinner-border"
                              style={{
                                height: "1rem",
                                width: "1rem",
                                border: "0.15em solid currentColor",
                                borderRightColor: "transparent",
                                marginBottom: "0.2rem",
                              }}
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>{" "}
                          </>
                        )}
                        Add
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          closeAddMainCrud(setMainCrudAdd);
                          setMainCrudAdd(false);
                        }}
                        className="btn btn-secondary ml-2"
                      >
                        Close
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  } catch (err) {
    return (
      <E500
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        favicon={favicon}
        error={err}
      />
    );
  }
};
