import React from "react";
import { Title } from "./title";
import { useGetCall } from "../lib/api/get";
import { getDependencyCall } from "./../lib/api/dependencies";
import httpParams from "./../lib/httpParams";
import { useAuthStore } from "./../store/auth";
import { useDependencies } from "../store/dependencies";
import { E500 } from "../components/e500";
import { BasicForm } from "./moduleComps/basicForm";
import { ExamSchedule } from "./moduleComps/examSchedules";
import { Profile } from "./moduleComps/profile";
import { Receipts } from "./moduleComps/receipts";
import { CheckInOut } from "./moduleComps/checkInOut";
import { StaffAttendance } from "./moduleComps/staffAttendance";
import { AttendanceStaff } from "./moduleComps/attendanceStaff";
import { Messages } from "./moduleComps/messages";
import { QRCodeForm } from "./moduleComps/qrCodeForm";
import { Attendance } from "./moduleComps/attendance";
import { BioFPAttendance } from "./moduleComps/bioFPAttendance";
import { ClassAttendance } from "./moduleComps/classAttendance";
import { Reports } from "./moduleComps/reports";
import { QRCodeFormChild } from "./moduleComps/qrCodeFormChild";
import { ChildAttendance } from "./moduleComps/childAttendance";
import { AttendanceReport } from "./moduleComps/reporting/attendanceReport";
import { StaffAttendanceReport } from "./moduleComps/reporting/staffAttendanceReport";
import { AttendanceReportChild } from "./moduleComps/reporting/attendanceReportChild";
import { ExamMarksEntry } from "./moduleComps/examMarksEntry";
import { ExamMarksReport } from "./moduleComps/reporting/examMarksReport";
import { ExpenseReport } from "./moduleComps/reporting/expenseReport";
import { ScholarshipDueReport } from "./moduleComps/reporting/scholarshipDueReport";
import { ScholarshipSummaryReport } from "./moduleComps/reporting/scholarshipSummaryReport";

export const Content = ({
	uiHelper,
	setMainCrudAdd,
	mainCrudAdd,
	modSchema,
	appVariables,
	primaryColor,
	secondaryColor,
	favicon,
	authData,
	routeDetails,
	userLogout,webSettings
}) => {
	React.useEffect(() => {
		document.getElementById("siteTitle").innerHTML =
			appVariables.REACT_APP_PAGE_TITLE + " :: " + modSchema.pageTitle;
		getDeps();
	}, []);
	const params = httpParams.adminOrgParams(appVariables);
	const appState = useAuthStore((state) => state);
	const [allData, setAllData] = React.useState(null);
	const [filterValue, setFilterValue] = React.useState(null);
	const { setDependency } = useDependencies();
	const getDeps = () => {
		const stateVar = { ...appState };
		if (modSchema.hasDependencies) {
			modSchema.dependeciesFormAPIS.map(async (api) => {
				stateVar.dependeciesFormAPIS[api] = await getDependencyCall(
					appVariables.REACT_APP_API_URL,
					api,
					params
				);
				setDependency(stateVar, stateVar.role, stateVar.roleId);
			});
		}
	};

	const getData = useGetCall(
		appVariables.REACT_APP_API_URL,
		modSchema.api,
		params +
			`${modSchema.moduleAddParam ? modSchema.moduleAddParamString : ""}`,
		false,
		!modSchema.api ? false : true
	);
	const [activePage, setActivePage] = React.useState(
		localStorage.getItem(modSchema.moduleName + "pageActive")
			? parseInt(localStorage.getItem(modSchema.moduleName + "pageActive"))
			: 1
	);

	const handlePage = (page) => {
		localStorage.setItem(modSchema.moduleName + "pageActive", page);
		setActivePage(page);
	};

	const Modules = {
		BasicForm: BasicForm,
		ExamSchedule:ExamSchedule,
		Profile: Profile,
		Receipts: Receipts,
		CheckInOut: CheckInOut,
		StaffAttendance: StaffAttendance,
		AttendanceStaff: AttendanceStaff,
		Messages: Messages,
		QRCodeForm: QRCodeForm,
		QRCodeFormChild: QRCodeFormChild,
		Attendance: Attendance,
		BioFPAttendance: BioFPAttendance,
		ClassAttendance: ClassAttendance,
		ChildAttendance: ChildAttendance,
		AttendanceReport: AttendanceReport,
		StaffAttendanceReport: StaffAttendanceReport,
		AttendanceReportChild: AttendanceReportChild,
		Reports: Reports,
		ExamMarksEntry:ExamMarksEntry,
		ExamMarksReport:ExamMarksReport,
		ExpenseReport:ExpenseReport,
		ScholarshipDueReport:ScholarshipDueReport,
		ScholarshipSummaryReport:ScholarshipSummaryReport
	};

	let ModuleName = Modules[routeDetails.component];

	if (getData.isLoading) {
		return (
			<div className="text-ceter" style={{ flex: "1 1 auto" }}>
				<div
					className="spinner-grow"
					role="status"
					style={{
						position: "fixed",
						top: "50%",
						left: "50%",
						color: primaryColor,
					}}
				>
					<span className="sr-only">Loading...</span>
				</div>
				<div
					className="spinner-grow"
					role="status"
					style={{
						position: "fixed",
						top: "50%",
						left: "55%",
						color: primaryColor,
					}}
				>
					<span className="sr-only">Loading...</span>
				</div>
				<div
					className="spinner-grow"
					role="status"
					style={{
						position: "fixed",
						top: "50%",
						left: "60%",
						color: primaryColor,
					}}
				>
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}

	let filteredData = [];
	if (modSchema.filterBy) {
		filteredData = getData.data?.formData.filter(
			(f) => f.elementCSSLibraryName === modSchema.filterByKey
		);
		if (filteredData.length > 0 && activePage > filteredData.length) {
			handlePage(1);
		}
	}

	if (
		getData.data?.formData?.length > 0 &&
		activePage > getData.data?.formData?.length
	) {
		handlePage(1);
	}

	try {
		return (
			<>
				<Title
					addMainCrud={uiHelper.addMainCrud}
					closeAddMainCrud={uiHelper.closeAddMainCrud}
					setMainCrudAdd={setMainCrudAdd}
					mainCrudAdd={mainCrudAdd}
					modSchema={modSchema}
					primaryColor={primaryColor}
					secondaryColor={secondaryColor}
					favicon={favicon}
				/>
				{ModuleName ? (
					<ModuleName
						key={routeDetails.component}
						appVariables={appVariables}
						addMainCrud={uiHelper.addMainCrud}
						setMainCrudAdd={setMainCrudAdd}
						mainCrudAdd={mainCrudAdd}
						closeAddMainCrud={uiHelper.closeAddMainCrud}
						modSchema={modSchema}
						currentPage={activePage}
						siblingCount={1}
						pageSize={1}
						handlePage={handlePage}
						filteredData={filteredData}
						getData={getData}
						uiHelper={uiHelper}
						setAllData={setAllData}
						allData={allData}
						primaryColor={primaryColor}
						secondaryColor={secondaryColor}
						favicon={favicon}
						authData={authData}
						userLogout={userLogout}
						webSettings={webSettings}
						setFilterValue={setFilterValue}
						filterValue={filterValue}
					/>
				) : (
					<div className="contentbar">
						<div className="row">
							<div className="col-12">Module not found!</div>
						</div>
					</div>
				)}
			</>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
