import React from "react";
import axios from "axios";
import { default as dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { E500 } from "../../components/e500";
import httpParams from "../../lib/httpParams";
import { queryClient } from "../../lib/react-query/react-query";
import { useGetMutateCall } from "../../lib/api/get";
import { Link } from "react-router-dom";
import { ReceiptView } from "./receiptView";
import { ReceiptViewCC } from "./receiptViewCC";

import "./receiptStatement.css";

dayjs.extend(utc);
dayjs.extend(timezone);
const date = new Date();
const tzOffset = date.getTimezoneOffset();

export const Receipts = ({
  primaryColor,
  secondaryColor,
  favicon,
  appVariables,
  getData,
  authData,
  modSchema,
}) => {
  const params = httpParams.adminOrgParams(appVariables);
  const orgDetails = localStorage.getItem("authent")
    ? JSON.parse(localStorage.getItem("authent")).state.dependeciesFormAPIS?.org
        ?.formData[0]
    : null;

  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedAppl, setSelectedAppl] = React.useState(null);
  const [dates, setDates] = React.useState(null);
  const [classes, setClasses] = React.useState(null);
  const [gettingData, setGettingData] = React.useState(false);
  const [selectedApplData, setSelectedApplData] = React.useState({
    data: { formData: [] },
  });

  const [dateFrom, setDateFrom] = React.useState(null);
  const [dateTo, setDateTo] = React.useState(null);
  const [applSelected, setApplSelected] = React.useState(null);

  const [viewFeeStatement, setViewFeeStatement] = React.useState(false);

  const getCallMutation = useGetMutateCall();

  const handlePage = (index) => {
    setCurrentPage(index);
  };

  const getOnchangeAppl = async (e) => {
    setGettingData(true);
    let param;
    if (dateFrom !== null && dateTo !== null && applSelected !== null) {
      param =
        params +
        "&applicationId=" +
        applSelected +
        "&checkInDateTime=" +
        dayjs.utc(dates).format("YYYY-MM-DD HH:mm:ss") +
        "&className=" +
        classes +
        "&dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo;
    } else if (applSelected !== null) {
      param =
        params +
        "&applicationId=" +
        applSelected +
        "&checkInDateTime=" +
        dayjs.utc(dates).format("YYYY-MM-DD HH:mm:ss") +
        "&className=" +
        classes;
    }
    await getCallMutation
      .mutateAsync({
        apiURL: appVariables.REACT_APP_API_URL,
        apiEndpoint: "FeeReceipt",
        params: param,
      })
      .then((resp) => {
        setSelectedApplData(resp.formData);
        if (resp.formData.length <= 0) {
          setSelectedAppl(null);
        }
        setApplSelected(null);
        setDateFrom(null);
        setDateTo(null);
      });
    setGettingData(false);
  };

  try {
    return (
      <div className="contentbar">
        <div className="row">
          <div className="col-12 form-group">
            <select
              className="form-control"
              onChange={(e) => {
                setApplSelected(e.target.value);
              }}
            >
              <option>Select Application</option>
              {authData.dependeciesFormAPIS["appl"]?.formData.length > 0 &&
                authData.dependeciesFormAPIS["appl"]?.formData.map(
                  (ops, opsInd) => (
                    <option key={opsInd} value={ops.applicationId}>
                      {ops.applicationName}
                    </option>
                  )
                )}
            </select>
          </div>
          <div key="datefrom" className="col-5 ">
            <input
              type="date"
              className="form-control"
              onChange={(e) => {
                setDateFrom(e.target.value);
              }}
              defaultValue={dates}
            />
          </div>
          <div key="dateTo" className="col-5">
            <input
              type="date"
              className="form-control"
              onChange={(e) => {
                setDateTo(e.target.value);
              }}
              defaultValue={dates}
            />
          </div>
          <div className="col-2">
            <button
              className="btn btn-primary"
              onClick={() => getOnchangeAppl()}
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
          <hr />
          <div className="col-12">
            <button
              className="btn btn-primary"
              onClick={() => setViewFeeStatement(!viewFeeStatement)}
            >
              {viewFeeStatement ? "Close" : "Fee Statement"}
            </button>
            {viewFeeStatement && selectedApplData !== null && (
              <>
                {" "}
                <button className="btn btn-info" onClick={() => window.print()}>
                  Print Statement
                </button>
              </>
            )}
            {viewFeeStatement && selectedApplData !== null && (
              <>
                <div
                  id="receiptStatement-to-print"
                  className="table-responsive receiptStatement-to-print"
                >
                  <table className="table table-bordered mb-2">
					<tr><td colSpan={10} style={{textAlign:"center"}}>
					<h4>
												{orgDetails.orgName !== null && orgDetails.orgName}
											</h4>
											<h6>
												{orgDetails.orgStreetAddress !== null &&
													orgDetails.orgStreetAddress}{" "}
											</h6>
											<p className="mb-0">
												{orgDetails.orgHeader !== null && orgDetails.orgHeader}{" "}
											</p>
						</td></tr>
					<tr><td colSpan={10} style={{textAlign:"center"}}>Name: {selectedApplData[0].Application.applicationName}</td></tr>
                    <tbody style={{ fontSize: "0.7rem" }}>
                      <th>Receipt No</th>
                      <th>Received From</th>
                      <th>Amount</th>
                      <th>Amount In Words</th>
                      <th>Mode Of Payment</th>
                      <th>Type</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Received Date</th>
                      <th>Notes</th>
                      {selectedApplData.length > 0 &&
                        selectedApplData.map((r, rInd) => (
                          <tr key={rInd}>
                            <td>{r.feeReceiptNumber}</td>
                            <td>{r.feeReceivedFrom}</td>
                            <td>{r.feeAmount}</td>
                            <td>{r.feeAmountInWords}</td>
                            <td>{r.paymentMethod}</td>
                            <td>{r.feeType}</td>
                            <td>{dayjs(r.feeDurationStartDate).format("MM/DD/YYYY")}</td>
                            <td>{dayjs(r.feeDurationEndDate).format("MM/DD/YYYY")}</td>
                            <td>{dayjs(r.feeReceivedDate).format("MM/DD/YYYY")}</td>
                            <td>{r.feeReceiptNotes}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {!viewFeeStatement && (
              <div className="table-responsive">
                <table className="table table-borderless mb-2">
                  <tbody>
                    {selectedApplData.length > 0 ? (
                      selectedApplData.map((atten, attenInd) => (
                        <tr key={attenInd}>
                          <td>
                            <h5 className="card-title font-16 mb-1">
                              {atten.Application.applicationName}
                            </h5>
                            <p className="text-muted font-14 mb-0">
                              {dayjs(atten.feeReceivedDate).format(
                                "MMM D, YYYY"
                              )}
                            </p>
                          </td>
                          <td className="text-right">
                            <button
                              className="btn btn-sm btn-primary-rgba"
                              onClick={() => setSelectedAppl(atten)}
                            >
                              <i className="fa fa-eye"></i> View
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>No Records Found!</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        {gettingData && (
          <div className="row">
            <div className="calendar-container">
              <div className="calendar">
                <div className="year-header">
                  <span className="year" id="label">
                    Loading...
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        {selectedAppl !== null &&
          (modSchema.isChildCare ? (
            <ReceiptViewCC
              appVariables={appVariables}
              orgDetails={orgDetails}
              selectedAppl={selectedAppl}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              favicon={favicon}
              title={"RECEIPT FOR CHILD CARE SERVICES"}
            />
          ) : (
            <ReceiptView
              appVariables={appVariables}
              orgDetails={orgDetails}
              selectedAppl={selectedAppl}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              favicon={favicon}
              title={"RECEIPT FOR CHILD CARE SERVICES"}
            />
          ))}
      </div>
    );
  } catch (err) {
    return (
      <E500
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        favicon={favicon}
        error={err}
      />
    );
  }
};
