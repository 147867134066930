import React from "react";
import { E500 } from "../e500";

export const SelectBox = ({
	field,
	errors,
	register,
	isAdd,
	defaultData,
	mainData,
	primaryColor,
	secondaryColor,
	favicon,
}) => {
	const [selectedOpt, setSelectedOpt] = React.useState(null);
	const [selectedChildRowData, setSelectedChildRowData] = React.useState(null);

	const setValOfSelect = (event) => {
		const filtObj = JSON.parse(
			localStorage.getItem("authent")
		).state.dependeciesFormAPIS[field.compOptionAPIDependency]?.formData.filter(
			(f) => f.applicationId === event.target.value
		);
		const mergedArr = [];
		const mergeObj = JSON.parse(
			localStorage.getItem("authent")
		).state.dependeciesFormAPIS["academicYears"]?.formData.map((m) =>
			filtObj[0].applicationAYClassSections.map((a) => {
				if (a.academicYearId === m.academicYearId) {
					mergedArr.push({
						academicYearName: m.academicYearName,
						applicationAYClassSectionId: a.applicationAYClassSectionId,
					});
				}
			})
		);

		setSelectedOpt(mergedArr);
	};

	const chainRowData = (event)=>{
		const filtObj = JSON.parse(
			localStorage.getItem("authent")
		).state.dependeciesFormAPIS[field.compOptionAPIDependency]?.formData.filter(
			(f) => f[field.compId] === event.target.value
		);
			
		setSelectedChildRowData(filtObj[0][field.rowDataArray])
	}
	try {
		if(field.chainRowDataArray){
			return(
				<>
					<div
						className={`form-group col-md-${field.size}`}
						style={{ display: field.hidden ? "none" : "block" }}
					>
						<label className="form-control-label">{field.placeholder}</label>
						<select
						key={field.name}
							className={`form-control ${
								errors[field.name] && "form-control-danger"
							}`}
							style={{ color: "#000000" }}
							{...register(field.name)}
							onChange={chainRowData}
							defaultValue={defaultData ? defaultData[field.name] : null}
						>
							<option>Select {field.placeholder}...</option>
							{field.optionAPIDependency &&
								JSON.parse(
									localStorage.getItem("authent")
								).state.dependeciesFormAPIS[
									field.compOptionAPIDependency
								]?.formData.map((option, optionIndex) => (
									<option key={optionIndex} value={option[field.compId]}>
										{option[field.compName]}
									</option>
								))}
						</select>
						{errors[field.name] && (
							<div className="form-control-feedback">
								{errors[field.name].message}
							</div>
						)}
					</div>

					<div
						className={`form-group col-md-${field.childChain[0].size}`}
						style={{ display: field.childChain[0].hidden ? "none" : "block" }}
					>
						<label className="form-control-label">{field.childChain[0].placeholder}</label>
						<select
						key={field.childChain[0].name}
							className={`form-control ${
								errors[field.childChain[0].name] && "form-control-danger"
							}`}
							style={{ color: "#000000" }}
							{...register(field.childChain[0].name)}
							defaultValue={defaultData ? defaultData[field.childChain[0].name] : null}
						>
							<option>Select {field.childChain[0].placeholder}...</option>
							{
								selectedChildRowData!==null && selectedChildRowData.length>0 && selectedChildRowData.map((ops,opsInd)=>
								<option key={opsInd} value={ops[field.childChain[0].compId]}>
									{console.log(ops,field)}
									{ops[field.childChain[0].compName]||ops[field.childChain[0].compId]}
								</option>
								)
							}
						</select>
						{errors[field.childChain[0].name] && (
							<div className="form-control-feedback">
								{errors[field.childChain[0].name].message}
							</div>
						)}
					</div>
				</>
			)
		}
		else if (field.isChildChained) {
			return (
				<>
					<div
						className={`form-group col-md-${field.size}`}
						style={{ display: field.hidden ? "none" : "block" }}
					>
						<label className="form-control-label">{field.placeholder}</label>
						<select
							className={`form-control ${
								errors[field.name] && "form-control-danger"
							}`}
							style={{ color: "#000000" }}
							{...register(field.name)}
							onChange={setValOfSelect}
							defaultValue={defaultData ? defaultData[field.name] : null}
						>
							<option>Select {field.placeholder}...</option>
							{field.optionAPIDependency &&
								JSON.parse(
									localStorage.getItem("authent")
								).state.dependeciesFormAPIS[
									field.compOptionAPIDependency
								]?.formData.map((option, optionIndex) => (
									<option key={optionIndex} value={option[field.compId]}>
										{option[field.compName]}
									</option>
								))}
						</select>
						{errors[field.name] && (
							<div className="form-control-feedback">
								{errors[field.name].message}
							</div>
						)}
					</div>

					{field.childChain &&
						field.childChain.length > 0 &&
						field.childChain.map((childField, childFieldIndex) => (
							<div
								className={`form-group col-md-${childField.size}`}
								style={{ display: childField.hidden ? "none" : "block" }}
								key={`${childFieldIndex}`}
							>
								<label className="form-control-label">
									{childField.placeholder}
								</label>
								<select
									className={`form-control ${
										errors[childField.name] && "form-control-danger"
									}`}
									style={{ color: "#000000" }}
									{...register(childField.name)}
									defaultValue={
										defaultData ? defaultData[childField.name] : null
									}
								>
									<option>Select {childField.placeholder}...</option>
									{selectedOpt !== null &&
										selectedOpt.length > 0 &&
										selectedOpt.map((childOption, childOptionIndex) => (
											<option
												key={childOptionIndex}
												value={childOption[childField.childId]}
											>
												{childOption[childField.nameObj]}
											</option>
										))}
								</select>
								{errors[field.name] && (
									<div className="form-control-feedback">
										{errors[field.name].message}
									</div>
								)}
							</div>
						))}
				</>
			);
		} else {
			return (
				<div
					className={`form-group col-md-${field.size}`}
					style={{ display: field.hidden ? "none" : "block" }}
				>
					<label className="form-control-label">{field.placeholder}</label>
					<select
						className={`form-control ${
							errors[field.name] && "form-control-danger"
						}`}
						style={{ color: "#000000" }}
						{...register(field.name)}
						defaultValue={defaultData ? defaultData[field.name] : null}
					>
						<option>Select {field.placeholder}...</option>
						{field.optionArrayDependency &&
							field.compOptionArrayDependency.map((option, optionIndex) => (
								<option key={optionIndex} value={option.id}>
									{option.name}
								</option>
							))}
						{field.valFromObj &&
							mainData[field.obj[0]] &&
							mainData[field.obj[0]].map((option, optionIndex) => (
								<option key={optionIndex} value={option[field.compId]}>
									{option[field.obj[1]]}
								</option>
							))}
						{field.optionAPIDependency &&
							JSON.parse(
								localStorage.getItem("authent")
							).state.dependeciesFormAPIS[
								field.compOptionAPIDependency
							]?.formData.map((option, optionIndex) => (
								<option key={optionIndex} value={option[field.compId]}>
									{option[field.compName]}
								</option>
							))}
					</select>
					{errors[field.name] && (
						<div className="form-control-feedback">
							{errors[field.name].message}
						</div>
					)}
				</div>
			);
		}
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
