import React from "react";
import { default as dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import _ from "lodash";

import { useCreateCall } from "../../../lib/api/create";
import { useUpdateCall } from "../../../lib/api/update";
import { useGetMutateCall } from "../../../lib/api/get";
import httpParams from "../../../lib/httpParams";

import "./examReport.css";

export const ExamMarksReport = ({ authData, appVariables }) => {
  const apiData = [
    {
      id: "BF93D584-65BD-4997-8C2B-8B08CBB07BC9",
      userChildrenId: "D736BD30-B5DC-491B-9819-8FC38833360E",
      applicationId: "996AF07E-38BF-49A4-AFDF-E93AD8AA5811",
      academicYearId: "1C12749B-89D8-45B4-A970-6010EFC75144",
      academicYearName: "AY2023-24",
      userChildName: "Boyini Sirisha",
      classSectionId: "950D1D79-DDD5-4FE4-B38C-2147C94ACE07",
      classSectionName: "CLASS 10A",
      ApplicationAYClassSectionRollNumber: 3,
      examGroupId: "B351748F-2FF8-4868-B764-DE1F6D130734",
      examGroupName: "FORMATIVE - 1",
      weightage: 100,
      examGroupFullName: "FORMATIVE - 1 - 100%",
      subjectId: "1F9CE6EE-0F11-4313-AF6C-4F8EA651832D",
      subjectName: "ENGLISH",
      examId: "EFB98B6A-9EF5-4E3D-AEB2-C5790BE79274",
      examName: "FA-1 Written",
      maxMarks: 100,
      marks: null,
      grade: null,
    },
    {
      id: "01A1A1B4-A50C-4BDF-8F17-19E79DC1FA09",
      userChildrenId: "22243FA2-D965-4D48-8EFF-A01AA8157931",
      applicationId: "A18A0141-6C4D-481C-8537-A90DFEA85DFF",
      academicYearId: "1C12749B-89D8-45B4-A970-6010EFC75144",
      academicYearName: "AY2023-24",
      userChildName: "Vadla Ananya",
      classSectionId: "950D1D79-DDD5-4FE4-B38C-2147C94ACE07",
      classSectionName: "CLASS 10A",
      ApplicationAYClassSectionRollNumber: 1,
      examGroupId: "B351748F-2FF8-4868-B764-DE1F6D130734",
      examGroupName: "FORMATIVE - 1",
      weightage: 100,
      examGroupFullName: "FORMATIVE - 1 - 100%",
      subjectId: "1F9CE6EE-0F11-4313-AF6C-4F8EA651832D",
      subjectName: "ENGLISH",
      examId: "EFB98B6A-9EF5-4E3D-AEB2-C5790BE79274",
      examName: "FA-1 Written",
      maxMarks: 100,
      marks: null,
      grade: null,
    },
    {
      id: "D8358FDE-A196-40F9-A9F1-571654BE485F",
      userChildrenId: "5AB50801-DCC2-4762-9397-4CBF1457C172",
      applicationId: "8E55A0D7-618B-4858-9295-A47F5E079BF4",
      academicYearId: "1C12749B-89D8-45B4-A970-6010EFC75144",
      academicYearName: "AY2023-24",
      userChildName: "Boyini Spandana",
      classSectionId: "950D1D79-DDD5-4FE4-B38C-2147C94ACE07",
      classSectionName: "CLASS 10A",
      ApplicationAYClassSectionRollNumber: 5,
      examGroupId: "B351748F-2FF8-4868-B764-DE1F6D130734",
      examGroupName: "FORMATIVE - 1",
      weightage: 100,
      examGroupFullName: "FORMATIVE - 1 - 100%",
      subjectId: "1F9CE6EE-0F11-4313-AF6C-4F8EA651832D",
      subjectName: "ENGLISH",
      examId: "EFB98B6A-9EF5-4E3D-AEB2-C5790BE79274",
      examName: "FA-1 Written",
      maxMarks: 100,
      marks: null,
      grade: null,
    },
    {
      id: "C77DE50C-410B-4504-B77D-CF47DDDD00BE",
      userChildrenId: "CF2B000E-4A22-4CFC-BE94-BDC2FAA1F469",
      applicationId: "B8843628-3504-4D1A-A812-D02FF978C4A8",
      academicYearId: "1C12749B-89D8-45B4-A970-6010EFC75144",
      academicYearName: "AY2023-24",
      userChildName: "Rekha Kumari",
      classSectionId: "950D1D79-DDD5-4FE4-B38C-2147C94ACE07",
      classSectionName: "CLASS 10A",
      ApplicationAYClassSectionRollNumber: 2,
      examGroupId: "B351748F-2FF8-4868-B764-DE1F6D130734",
      examGroupName: "FORMATIVE - 1",
      weightage: 100,
      examGroupFullName: "FORMATIVE - 1 - 100%",
      subjectId: "1F9CE6EE-0F11-4313-AF6C-4F8EA651832D",
      subjectName: "ENGLISH",
      examId: "EFB98B6A-9EF5-4E3D-AEB2-C5790BE79274",
      examName: "FA-1 Written",
      maxMarks: 100,
      marks: null,
      grade: null,
    },
    {
      id: "F0CD865B-A058-438A-B300-C5A0C695688C",
      userChildrenId: "F760A22C-527B-4A8D-8A0C-E05EF9498772",
      applicationId: "954037AF-CA4A-4153-A100-AFB2B38F4842",
      academicYearId: "1C12749B-89D8-45B4-A970-6010EFC75144",
      academicYearName: "AY2023-24",
      userChildName: "Aveta Hari Vaishnavi",
      classSectionId: "950D1D79-DDD5-4FE4-B38C-2147C94ACE07",
      classSectionName: "CLASS 10A",
      ApplicationAYClassSectionRollNumber: 8,
      examGroupId: "B351748F-2FF8-4868-B764-DE1F6D130734",
      examGroupName: "FORMATIVE - 1",
      weightage: 100,
      examGroupFullName: "FORMATIVE - 1 - 100%",
      subjectId: "1F9CE6EE-0F11-4313-AF6C-4F8EA651832D",
      subjectName: "ENGLISH",
      examId: "EFB98B6A-9EF5-4E3D-AEB2-C5790BE79274",
      examName: "FA-1 Written",
      maxMarks: 100,
      marks: null,
      grade: null,
    },
    {
      id: "F559DFE6-EA89-4127-8A93-A1975E54810F",
      userChildrenId: "26893293-2360-46C4-915F-41E8684AAFB7",
      applicationId: "A78ECFC4-4867-48D2-B063-FB324B090CCD",
      academicYearId: "1C12749B-89D8-45B4-A970-6010EFC75144",
      academicYearName: "AY2023-24",
      userChildName: "Shiv",
      classSectionId: "950D1D79-DDD5-4FE4-B38C-2147C94ACE07",
      classSectionName: "CLASS 10A",
      ApplicationAYClassSectionRollNumber: 1,
      examGroupId: "B351748F-2FF8-4868-B764-DE1F6D130734",
      examGroupName: "FORMATIVE - 1",
      weightage: 100,
      examGroupFullName: "FORMATIVE - 1 - 100%",
      subjectId: "1F9CE6EE-0F11-4313-AF6C-4F8EA651832D",
      subjectName: "ENGLISH",
      examId: "EFB98B6A-9EF5-4E3D-AEB2-C5790BE79274",
      examName: "FA-1 Written",
      maxMarks: 100,
      marks: 80,
      grade: "A",
    },
    {
      id: "09D1F675-8FB5-43EA-9C24-ED4FAA4A6750",
      userChildrenId: "5D822DB5-992D-4F28-844B-0878F3A30F0B",
      applicationId: "80D3E870-2379-40E1-97E4-16D2B98B0FC1",
      academicYearId: "1C12749B-89D8-45B4-A970-6010EFC75144",
      academicYearName: "AY2023-24",
      userChildName: "Gandi Anushka",
      classSectionId: "950D1D79-DDD5-4FE4-B38C-2147C94ACE07",
      classSectionName: "CLASS 10A",
      ApplicationAYClassSectionRollNumber: 6,
      examGroupId: "B351748F-2FF8-4868-B764-DE1F6D130734",
      examGroupName: "FORMATIVE - 1",
      weightage: 100,
      examGroupFullName: "FORMATIVE - 1 - 100%",
      subjectId: "1F9CE6EE-0F11-4313-AF6C-4F8EA651832D",
      subjectName: "ENGLISH",
      examId: "EFB98B6A-9EF5-4E3D-AEB2-C5790BE79274",
      examName: "FA-1 Written",
      maxMarks: 100,
      marks: null,
      grade: null,
    },
    {
      id: "A04A3E2E-85E2-450A-843A-57B24279BA39",
      userChildrenId: "2F311100-F4E3-4B9B-8BE0-02D16A97AFB9",
      applicationId: "DF81D66D-43F5-457D-8D8D-A22077F5EBBC",
      academicYearId: "1C12749B-89D8-45B4-A970-6010EFC75144",
      academicYearName: "AY2023-24",
      userChildName: "Yamajala Spurthi",
      classSectionId: "950D1D79-DDD5-4FE4-B38C-2147C94ACE07",
      classSectionName: "CLASS 10A",
      ApplicationAYClassSectionRollNumber: 7,
      examGroupId: "B351748F-2FF8-4868-B764-DE1F6D130734",
      examGroupName: "FORMATIVE - 1",
      weightage: 100,
      examGroupFullName: "FORMATIVE - 1 - 100%",
      subjectId: "1F9CE6EE-0F11-4313-AF6C-4F8EA651832D",
      subjectName: "ENGLISH",
      examId: "EFB98B6A-9EF5-4E3D-AEB2-C5790BE79274",
      examName: "FA-1 Written",
      maxMarks: 100,
      marks: null,
      grade: null,
    },
    {
      id: "137544D6-3064-4300-B8A1-0EA25837D78D",
      userChildrenId: "0C4A7DBF-F034-4E14-A83F-5B00BF9F195D",
      applicationId: "682DB139-2ABD-4FFC-AE17-5A7E7540E71A",
      academicYearId: "1C12749B-89D8-45B4-A970-6010EFC75144",
      academicYearName: "AY2023-24",
      userChildName: "Neerudi Vaishnavi",
      classSectionId: "950D1D79-DDD5-4FE4-B38C-2147C94ACE07",
      classSectionName: "CLASS 10A",
      ApplicationAYClassSectionRollNumber: 4,
      examGroupId: "B351748F-2FF8-4868-B764-DE1F6D130734",
      examGroupName: "FORMATIVE - 1",
      weightage: 100,
      examGroupFullName: "FORMATIVE - 1 - 100%",
      subjectId: "1F9CE6EE-0F11-4313-AF6C-4F8EA651832D",
      subjectName: "ENGLISH",
      examId: "EFB98B6A-9EF5-4E3D-AEB2-C5790BE79274",
      examName: "FA-1 Written",
      maxMarks: 100,
      marks: null,
      grade: null,
    },
    {
      id: "0BAC50C0-4E04-4DF7-BEE1-4D6D03389E21",
      userChildrenId: "D736BD30-B5DC-491B-9819-8FC38833360E",
      applicationId: "996AF07E-38BF-49A4-AFDF-E93AD8AA5811",
      academicYearId: "1C12749B-89D8-45B4-A970-6010EFC75144",
      academicYearName: "AY2023-24",
      userChildName: "Boyini Sirisha",
      classSectionId: "950D1D79-DDD5-4FE4-B38C-2147C94ACE07",
      classSectionName: "CLASS 10A",
      ApplicationAYClassSectionRollNumber: 3,
      examGroupId: "B351748F-2FF8-4868-B764-DE1F6D130734",
      examGroupName: "FORMATIVE - 1",
      weightage: 100,
      examGroupFullName: "FORMATIVE - 1 - 100%",
      subjectId: "30CCDB67-3D9E-46D9-978F-61FF43401C76",
      subjectName: "MATHS",
      examId: "EFB98B6A-9EF5-4E3D-AEB2-C5790BE79274",
      examName: "FA-1 Written",
      maxMarks: 100,
      marks: null,
      grade: null,
    },
    {
      id: "B654740B-D636-400A-8B69-B8F18F090F0D",
      userChildrenId: "22243FA2-D965-4D48-8EFF-A01AA8157931",
      applicationId: "A18A0141-6C4D-481C-8537-A90DFEA85DFF",
      academicYearId: "1C12749B-89D8-45B4-A970-6010EFC75144",
      academicYearName: "AY2023-24",
      userChildName: "Vadla Ananya",
      classSectionId: "950D1D79-DDD5-4FE4-B38C-2147C94ACE07",
      classSectionName: "CLASS 10A",
      ApplicationAYClassSectionRollNumber: 1,
      examGroupId: "B351748F-2FF8-4868-B764-DE1F6D130734",
      examGroupName: "FORMATIVE - 1",
      weightage: 100,
      examGroupFullName: "FORMATIVE - 1 - 100%",
      subjectId: "30CCDB67-3D9E-46D9-978F-61FF43401C76",
      subjectName: "MATHS",
      examId: "EFB98B6A-9EF5-4E3D-AEB2-C5790BE79274",
      examName: "FA-1 Written",
      maxMarks: 100,
      marks: null,
      grade: null,
    },
    {
      id: "3EA7A5DB-7010-4944-8432-055E01DED31E",
      userChildrenId: "5AB50801-DCC2-4762-9397-4CBF1457C172",
      applicationId: "8E55A0D7-618B-4858-9295-A47F5E079BF4",
      academicYearId: "1C12749B-89D8-45B4-A970-6010EFC75144",
      academicYearName: "AY2023-24",
      userChildName: "Boyini Spandana",
      classSectionId: "950D1D79-DDD5-4FE4-B38C-2147C94ACE07",
      classSectionName: "CLASS 10A",
      ApplicationAYClassSectionRollNumber: 5,
      examGroupId: "B351748F-2FF8-4868-B764-DE1F6D130734",
      examGroupName: "FORMATIVE - 1",
      weightage: 100,
      examGroupFullName: "FORMATIVE - 1 - 100%",
      subjectId: "30CCDB67-3D9E-46D9-978F-61FF43401C76",
      subjectName: "MATHS",
      examId: "EFB98B6A-9EF5-4E3D-AEB2-C5790BE79274",
      examName: "FA-1 Written",
      maxMarks: 100,
      marks: null,
      grade: null,
    },
    {
      id: "1F8756E6-D09F-462C-8439-160A5BFF1E00",
      userChildrenId: "CF2B000E-4A22-4CFC-BE94-BDC2FAA1F469",
      applicationId: "B8843628-3504-4D1A-A812-D02FF978C4A8",
      academicYearId: "1C12749B-89D8-45B4-A970-6010EFC75144",
      academicYearName: "AY2023-24",
      userChildName: "Rekha Kumari",
      classSectionId: "950D1D79-DDD5-4FE4-B38C-2147C94ACE07",
      classSectionName: "CLASS 10A",
      ApplicationAYClassSectionRollNumber: 2,
      examGroupId: "B351748F-2FF8-4868-B764-DE1F6D130734",
      examGroupName: "FORMATIVE - 1",
      weightage: 100,
      examGroupFullName: "FORMATIVE - 1 - 100%",
      subjectId: "30CCDB67-3D9E-46D9-978F-61FF43401C76",
      subjectName: "MATHS",
      examId: "EFB98B6A-9EF5-4E3D-AEB2-C5790BE79274",
      examName: "FA-1 Written",
      maxMarks: 100,
      marks: null,
      grade: null,
    },
    {
      id: "972A01DB-8DEE-4A33-AAC4-B9DF64D68A9B",
      userChildrenId: "F760A22C-527B-4A8D-8A0C-E05EF9498772",
      applicationId: "954037AF-CA4A-4153-A100-AFB2B38F4842",
      academicYearId: "1C12749B-89D8-45B4-A970-6010EFC75144",
      academicYearName: "AY2023-24",
      userChildName: "Aveta Hari Vaishnavi",
      classSectionId: "950D1D79-DDD5-4FE4-B38C-2147C94ACE07",
      classSectionName: "CLASS 10A",
      ApplicationAYClassSectionRollNumber: 8,
      examGroupId: "B351748F-2FF8-4868-B764-DE1F6D130734",
      examGroupName: "FORMATIVE - 1",
      weightage: 100,
      examGroupFullName: "FORMATIVE - 1 - 100%",
      subjectId: "30CCDB67-3D9E-46D9-978F-61FF43401C76",
      subjectName: "MATHS",
      examId: "EFB98B6A-9EF5-4E3D-AEB2-C5790BE79274",
      examName: "FA-1 Written",
      maxMarks: 100,
      marks: null,
      grade: null,
    },
    {
      id: "753E97C4-81BC-4A74-A9C8-004B18DC14FB",
      userChildrenId: "26893293-2360-46C4-915F-41E8684AAFB7",
      applicationId: "A78ECFC4-4867-48D2-B063-FB324B090CCD",
      academicYearId: "1C12749B-89D8-45B4-A970-6010EFC75144",
      academicYearName: "AY2023-24",
      userChildName: "Shiv",
      classSectionId: "950D1D79-DDD5-4FE4-B38C-2147C94ACE07",
      classSectionName: "CLASS 10A",
      ApplicationAYClassSectionRollNumber: 1,
      examGroupId: "B351748F-2FF8-4868-B764-DE1F6D130734",
      examGroupName: "FORMATIVE - 1",
      weightage: 100,
      examGroupFullName: "FORMATIVE - 1 - 100%",
      subjectId: "30CCDB67-3D9E-46D9-978F-61FF43401C76",
      subjectName: "MATHS",
      examId: "EFB98B6A-9EF5-4E3D-AEB2-C5790BE79274",
      examName: "FA-1 Written",
      maxMarks: 100,
      marks: 85,
      grade: "A",
    },
    {
      id: "B4FE378B-4B82-4DF0-96B2-F563C16D7254",
      userChildrenId: "5D822DB5-992D-4F28-844B-0878F3A30F0B",
      applicationId: "80D3E870-2379-40E1-97E4-16D2B98B0FC1",
      academicYearId: "1C12749B-89D8-45B4-A970-6010EFC75144",
      academicYearName: "AY2023-24",
      userChildName: "Gandi Anushka",
      classSectionId: "950D1D79-DDD5-4FE4-B38C-2147C94ACE07",
      classSectionName: "CLASS 10A",
      ApplicationAYClassSectionRollNumber: 6,
      examGroupId: "B351748F-2FF8-4868-B764-DE1F6D130734",
      examGroupName: "FORMATIVE - 1",
      weightage: 100,
      examGroupFullName: "FORMATIVE - 1 - 100%",
      subjectId: "30CCDB67-3D9E-46D9-978F-61FF43401C76",
      subjectName: "MATHS",
      examId: "EFB98B6A-9EF5-4E3D-AEB2-C5790BE79274",
      examName: "FA-1 Written",
      maxMarks: 100,
      marks: null,
      grade: null,
    },
    {
      id: "41765AD9-CB24-4F65-A6AC-97645FB0A481",
      userChildrenId: "2F311100-F4E3-4B9B-8BE0-02D16A97AFB9",
      applicationId: "DF81D66D-43F5-457D-8D8D-A22077F5EBBC",
      academicYearId: "1C12749B-89D8-45B4-A970-6010EFC75144",
      academicYearName: "AY2023-24",
      userChildName: "Yamajala Spurthi",
      classSectionId: "950D1D79-DDD5-4FE4-B38C-2147C94ACE07",
      classSectionName: "CLASS 10A",
      ApplicationAYClassSectionRollNumber: 7,
      examGroupId: "B351748F-2FF8-4868-B764-DE1F6D130734",
      examGroupName: "FORMATIVE - 1",
      weightage: 100,
      examGroupFullName: "FORMATIVE - 1 - 100%",
      subjectId: "30CCDB67-3D9E-46D9-978F-61FF43401C76",
      subjectName: "MATHS",
      examId: "EFB98B6A-9EF5-4E3D-AEB2-C5790BE79274",
      examName: "FA-1 Written",
      maxMarks: 100,
      marks: null,
      grade: null,
    },
    {
      id: "5EDC74D9-0409-461E-8F9D-3A0B005BD6EF",
      userChildrenId: "0C4A7DBF-F034-4E14-A83F-5B00BF9F195D",
      applicationId: "682DB139-2ABD-4FFC-AE17-5A7E7540E71A",
      academicYearId: "1C12749B-89D8-45B4-A970-6010EFC75144",
      academicYearName: "AY2023-24",
      userChildName: "Neerudi Vaishnavi",
      classSectionId: "950D1D79-DDD5-4FE4-B38C-2147C94ACE07",
      classSectionName: "CLASS 10A",
      ApplicationAYClassSectionRollNumber: 4,
      examGroupId: "B351748F-2FF8-4868-B764-DE1F6D130734",
      examGroupName: "FORMATIVE - 1",
      weightage: 100,
      examGroupFullName: "FORMATIVE - 1 - 100%",
      subjectId: "30CCDB67-3D9E-46D9-978F-61FF43401C76",
      subjectName: "MATHS",
      examId: "EFB98B6A-9EF5-4E3D-AEB2-C5790BE79274",
      examName: "FA-1 Written",
      maxMarks: 100,
      marks: null,
      grade: null,
    },
  ];
  const examMarksData = {
    examGroups: [
      {
        examGroupName: "FA-1 20%",
        type: "formative",
        exams: [
          {
            examName: "Practical Marks",
          },
          {
            examName: "Verbal",
          },
          {
            examName: "Proj Work",
          },
          {
            examName: "Slip Test",
          },
          {
            total: "Total",
          },
          {
            grade: "Grade",
          },
        ],
      },
      {
        examGroupName: "FA-2 20%",
        type: "formative",
        exams: [
          {
            examName: "Practical Marks",
          },
          {
            examName: "Verbal",
          },
          {
            examName: "Proj Work",
          },
          {
            examName: "Slip Test",
          },
          {
            total: "Total",
          },
          {
            grade: "Grade",
          },
        ],
      },
      {
        examGroupName: "SUMMATIVE-1",
        type: "summative",
        exams: [
          {
            examName: "Practical Marks",
          },
          {
            examName: "Verbal",
          },
          {
            examName: "Proj Work",
          },
          {
            examName: "Slip Test",
          },
        ],
      },
      {
        examGroupName: "FA-3 20%",
        type: "formative",
        exams: [
          {
            examName: "Practical Marks",
          },
          {
            examName: "Verbal",
          },
          {
            examName: "Proj Work",
          },
          {
            examName: "Slip Test",
          },
          {
            total: "Total",
          },
          {
            grade: "Grade",
          },
        ],
      },
      {
        examGroupName: "FA-4 20%",
        type: "formative",
        exams: [
          {
            examName: "Practical Marks",
          },
          {
            examName: "Verbal",
          },
          {
            examName: "Proj Work",
          },
          {
            examName: "Slip Test",
          },
          {
            total: "Total",
          },
          {
            grade: "Grade",
          },
        ],
      },
      {
        examGroupName: "SUMMATIVE-2",
        type: "summative",
        exams: [
          {
            examName: "Practical Marks",
          },
          {
            examName: "Verbal",
          },
          {
            examName: "Proj Work",
          },
          {
            examName: "Slip Test",
          },
        ],
      },
    ],
    examMarks: [
      {
        subject: "Telugu",
        marksEntry: [
          {
            marks: "4",
          },
          {
            marks: "5",
          },
          {
            marks: "5",
          },
          {
            marks: "3",
          },
          {
            total: "17",
          },
          {
            grade: "A2",
          },
        ],
      },
      {
        subject: "Telugu",
        marksEntry: [
          {
            marks: "4",
          },
          {
            marks: "4",
          },
          {
            marks: "5",
          },
          {
            marks: "3",
          },
          {
            total: "16",
          },
          {
            grade: "B1",
          },
        ],
      },
    ],
  };
  const params = httpParams.adminOrgParams(appVariables);
  const getCallMutation = useGetMutateCall();
  const createCallMutation = useCreateCall();
  const updateCallMutation = useUpdateCall();
  const [createData, setCreateData] = React.useState({});
  const [gettingData, setGettingData] = React.useState(false);
  const [selClassSectionId, setSelClassSectionId] = React.useState(null);
  const [selClassSectionName, setSelClassSectionName] = React.useState(null);
  const [selapplicaionId, setSelApplicationId] = React.useState(null);
  const [setMarksReport, setsetMarksReport] = React.useState(null);
  const [marksData, setMarksData] = React.useState({ data: { formData: [] } });
  const [AYClassSectionsData, setAYClassSectionsData] = React.useState(null);
  const [AYClassSectionsReportsData, setAYClassSectionsReportData] = React.useState(null);
  const [academicYear, setAcademicYear] = React.useState(null);
  const [selectedFilters, setSelectedFilters] = React.useState({
    classSectionId: "",
    applicationId: "",
  });
  const [reportSubjects, setReportSubjects] = React.useState(null);

  const exmRepData = {
    subjects: [{ subjectName: "Telugu" }, { subjectName: "Hindi" }],
    information: { name: "", classSection: "V A", rollNumber: "1" },
    examReport: [
      {
        examGroup: "Formative-1",
        exams: [
          {
            name: "FA-1 Performance",
            data: [
              { subjectName: "Telugu", marks: "8" },
              { subjectName: "Hindi", marks: "7" },
            ],
          },
          {
            name: "FA-1 Written",
            data: [
              { subjectName: "Telugu", marks: "9" },
              { subjectName: "Hindi", marks: "8" },
            ],
          },
          {
            name: "Total",
            data: [
              { subjectName: "Total", marks: "17" },
              { subjectName: "Total", marks: "16" },
            ],
          },
          {
            name: "Grade",
            data: [
              { subjectName: "Grade", marks: "A" },
              { subjectName: "Grade", marks: "A" },
            ],
          },
        ],
      },
      {
        examGroup: "Formative-2",
        exams: [
          {
            name: "FA-1 Performance",
            data: [
              { subjectName: "Telugu", marks: "10" },
              { subjectName: "Hindi", marks: "9" },
            ],
          },
          {
            name: "FA-1 Written",
            data: [
              { subjectName: "Telugu", marks: "8" },
              { subjectName: "Hindi", marks: "6" },
            ],
          },
          {
            name: "Total",
            data: [
              { subjectName: "Total", marks: "18" },
              { subjectName: "Total", marks: "15" },
            ],
          },
          {
            name: "Grade",
            data: [
              { subjectName: "Grade", marks: "A" },
              { subjectName: "Grade", marks: "A" },
            ],
          },
        ],
      },
    ],
  };
  const secAcadmYear = (id) => {
    setAcademicYear(id);
  };

  const getOnchangeAppl = async (e) => {
    e.preventDefault();
    setGettingData(true);
    const data = new FormData(e.target);

    console.log(data.get("marks"));
    setCreateData({
      classSectionId: data.get("classSectionId"),
      applicationId: data.get("applicationId"),
    });
    await getCallMutation
      .mutateAsync({
        apiURL: appVariables.REACT_APP_API_URL,
        apiEndpoint: "ApplicationAYClassSections",
        params:
          params +
          "&classSectionId=" +
          data.get("classSectionId") +
          "&examId=" +
          data.get("examId") +
          "&subjectId=" +
          data.get("subjectId"),
      })
      .then((resp) => {
        setMarksData({ data: resp });
      });
    setGettingData(false);
  };

  const onSelectClassSection = async (id) => {
    if (id === "Select Class Section") {
      return;
    }
    setAYClassSectionsData(null)
    selectedFilters["classSectionId"] = id;
    setSelectedFilters(selectedFilters);
    setSelClassSectionId(id);
    await getCallMutation
      .mutateAsync({
        apiURL: appVariables.REACT_APP_API_URL,
        apiEndpoint: "ApplicationAYClassSections",
        params: params + "&classSectionId=" + id,
      })
      .then((resp) => {
        setAYClassSectionsData(resp);
      });
  };

  const onSelectAYClassSection = async (id) => {
    if (id === "Select Subject") {
      return;
    }
    setSelApplicationId(id);
    selectedFilters["syllabusCombinationSubjectId"] = id;
    setSelectedFilters(selectedFilters);
    AYClassSectionsData.formData.filter(
      (f) => f.syllabusCombinationSubjectId === id
    );
    await getCallMutation
      .mutateAsync({
        apiURL: appVariables.REACT_APP_API_URL,
        apiEndpoint: "ApplicationAYClassSectionsSubjectExamMarksReport",
        params:
          params +
          "&applicationId=" +
          id +
          "&classSectionId=" +
          selClassSectionId +
          "&academicYearId=" +
          academicYear,
      })
      .then((resp) => {
        setAYClassSectionsReportData(resp);
      });
  };
  let filtrd = [];
  let groupExam = [];
  let groupSubs = [];
  let report = [];
  let reportSubs = [];
  let exReport={}
  if (AYClassSectionsReportsData !== null && selapplicaionId !== null) {
    filtrd = AYClassSectionsReportsData.formData?.filter(
      (f) => f.applicationId === selapplicaionId
    );

    var groupBy = function(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    const exSubs = _.groupBy(AYClassSectionsReportsData.formData, "subjectName")
    const exSubjects = []
    Object.entries(exSubs).map((m)=>
      exSubjects.push({subjectName:m[0],order:m[1][0].displayOrder})
    )
    exReport["subjects"]=exSubjects
    const exGrup = _.groupBy(AYClassSectionsReportsData.formData,"examGroupName") //_.groupBy(filtrd, "examGroupName");
  
    const exGroups = []
    Object.entries(exGrup).map((m)=>
      {
        const elem = {examGroup:m[0]}
        const exams=[]
      m[1].map((m1)=>
        exams.push({name:m1.examName})
      )
      elem["exams"]=exams
      exGroups.push(elem)
    }
  )
  exReport["examReport"]=exReport
  console.log(exReport)





    const fltGrpd = _.groupBy(filtrd, "examGroupName");
    const fltSub = _.groupBy(filtrd, "subjectName");
    Object.entries(fltSub).map((m) => {
      reportSubs.push({ sub: m[0] });
    });
    //console.log(fltGrpd)
    Object.entries(fltGrpd).map((m) => {
      const exams = [];
      const fltGrpdExms = _.groupBy(m[1], "examName");
      console.log(m[0]);
      report.push({ group: m[0], exam: fltGrpdExms });
    });

    const exmMrks = [];
    const mrkss = [];
    const grpd = _.groupBy(filtrd, "examGroupName");
    Object.entries(grpd).map((m) => {
      const exms = [];
      const mrks = [];
      m[1].map((mc) => {
        exms.push({ examName: mc.examName });
        mrks.push({
          subject: mc.subjectName,
          examName: mc.examName,
          marks: mc.marks,
        });
        exmMrks.push({ subject: mc.subjectName });
      });
      const exmsUniq = _.uniqBy(exms, function (e) {
        return e.examName;
      });
      groupExam.push({
        examGroupName: m[0],
        type: "formative",
        exams: exmsUniq,
        marks: mrks,
      });
    });
    const subs = [];
    const mrksEntry = [];
    const mrksEnt = [];
    const grpdsubs = _.groupBy(filtrd, "subjectName");

    Object.entries(grpdsubs).map((m) => {
      filtrd.map((f) => {
        f.subjectName === m[0] && mrksEntry.push({ marks: f.marks });
      });
      subs.push({ subjectName: m[0], entries: mrksEntry });
    });
    groupSubs = subs;
  }

  const processData = (apiData) => {
    const filtrd = apiData?.filter((f) => f.applicationId === selapplicaionId);
    const groupEx = _.groupBy(filtrd, "examGroupName");
    const groupSub = _.groupBy(filtrd, "subjectName");
    return groupEx;
    //return {grp:groupEx,sub:groupSub}
  };
//console.log("Report: ",exReport)
  return (
    <div className="contentbar">
      <div className="row">
        <form className="row" onSubmit={(e) => getOnchangeAppl(e)}>
          <div key="academicYearId" className="col-md-4">
            <select
              className="form-control"
              name="classSectionId"
              onChange={(e) => {
                secAcadmYear(e.target.value);
              }}
              disabled={
                authData.dependeciesFormAPIS.academicYears?.formData.length > 0
                  ? false
                  : true
              }
            >
              <option>
                {authData.dependeciesFormAPIS.academicYears?.formData.length > 0
                  ? "Select Academic Year"
                  : "Loading..."}
              </option>
              {authData.dependeciesFormAPIS.academicYears?.formData.length >
                0 &&
                authData.dependeciesFormAPIS.academicYears?.formData.map(
                  (ops, opsInd) => (
                    <option key={opsInd} value={ops.academicYearId}>
                      {ops.academicYearName}
                    </option>
                  )
                )}
            </select>
          </div>
          <div key="classSectionId" className="col-md-4">
            <select
              className="form-control"
              name="classSectionId"
              onChange={(e) => {
                onSelectClassSection(e.target.value);
              }}
              disabled={
                authData.dependeciesFormAPIS.classSections?.formData.length > 0
                  ? false
                  : true
              }
            >
              <option>
                {authData.dependeciesFormAPIS.classSections?.formData.length > 0
                  ? "Select Class Section"
                  : "Loading..."}
              </option>
              {authData.dependeciesFormAPIS.classSections?.formData.length >
                0 &&
                authData.dependeciesFormAPIS.classSections?.formData.map(
                  (ops, opsInd) => (
                    <option key={opsInd} value={ops.classSectionId}>
                      {ops.classSectionName}
                    </option>
                  )
                )}
            </select>
          </div>
          <div key="syllabusCombinationSubjectId" className="col-md-4">
            <select
              className="form-control"
              name="syllabusCombinationSubjectId"
              onChange={(e) => {
                onSelectAYClassSection(e.target.value);
              }}
              disabled={
                AYClassSectionsData !== null &&
                AYClassSectionsData.formData.length > 0
                  ? false
                  : true
              }
            >
              <option>
                {AYClassSectionsData !== null &&
                AYClassSectionsData.formData.length > 0
                  ? "Select Subject"
                  : "Loading..."}
              </option>
              {AYClassSectionsData !== null &&
                AYClassSectionsData.formData.length > 0 &&
                AYClassSectionsData.formData.map((ops, opsInd) => (
                  <option key={opsInd} value={ops.applicationId}>
                    {ops.userChildName}
                  </option>
                ))}
            </select>
          </div>
        </form>
      </div>
      <button
        id="print-button"
        type="button"
        onClick={() => window.print()}
        className="btn btn-primary-rgba py-1 font-16"
      >
        <i className="fa fa-print mr-2" />
        Print
      </button>
      {/* {console.log(reportSubjects)} */}
      <div className="table-responsive">
        <div className="marksreport-to-print">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td className="text-bold">
                  Name: {filtrd[0]?.userChildName || null}
                </td>
                <td className="text-bold">
                  Class: {filtrd[0]?.classSectionName || null}
                </td>
                <td className="text-bold">
                  Roll #:{" "}
                  {filtrd[0]?.ApplicationAYClassSectionRollNumber || null}
                </td>
              </tr>
            </tbody>
          </table>

          <table className="table table-bordered" key={AYClassSectionsReportsData}>
            <tr style={{ borderTop: "solid 2px" }}>
              <td style={{ borderLeft: "solid 2px" }} rowSpan={3} colSpan={1}>
                SUBJECT
              </td>
              {/* {groupExam && groupExam.length>0 && groupExam.map((grp, grpInd) => (
                <td
                  key={grpInd}
                  colSpan={
                    grp.type === "formative"
                      ? groupExam.length > 0 &&
                        groupExam.length +2
                      : 5
                  }
                >
                  {grp.examGroupName}
                </td>
              ))} */}
              {report.length > 0 &&
                report.map((grp, grpInd) => (
                  <td key={grpInd} colSpan={report.length + 2}>
                    {grp.group}
                  </td>
                ))}
            </tr>
            <tr style={{ fontSize: "0.5rem" }}>
              {report.length > 0 &&
                report.map((grpExm) =>
                  Object.entries(grpExm.exam).map((exm, exmInd) => (
                    <td key={exmInd} className="text-bold">
                      {exm[0]}
                    </td>
                  ))
                )}
              {/* {groupExam && groupExam.length>0 && groupExam.map((g, gInd) => {
                return (
                  <>
                    {g.type === "formative" && (
                      <>
                        {groupExam && groupExam.length>0 && groupExam[gInd].exams.map(
                          (grpEx, grpExInd) => (
                            <td key={grpExInd} className="text-bold">
                              {grpEx.examName || grpEx.total || grpEx.grade}
                            </td>
                          )
                        )}
                        <td className="text-bold">
                              Total
                            </td>
                            <td className="text-bold">
                              Grade
                            </td>
                      </>
                    )}
                    {g.type === "summative" && (
                      <>
                        <td rowSpan={2} className="text-bold">
                          Exam 80%
                        </td>
                        <td rowSpan={2} className="text-bold">
                          FA1,FA2 20%
                        </td>
                        <td rowSpan={2} className="text-bold">
                          Total 100%
                        </td>
                        <td rowSpan={2} className="text-bold">
                          Grade
                        </td>
                        <td rowSpan={2} className="text-bold">
                          GPA
                        </td>
                      </>
                    )}
                  </>
                );
              })} */}
            </tr>
            <tr style={{ borderBottom: "solid 2px" }}>
              {groupExam &&
                groupExam.length > 0 &&
                groupExam.map((g, gInd) => {
                  return (
                    <>
                      {g.type === "formative" && (
                        <>
                          {groupExam &&
                            groupExam.length > 0 &&
                            groupExam[gInd].exams.map((grpEx, grpExInd) => (
                              <td key={grpExInd} className="text-bold">
                                {grpEx.examName && grpExInd + 1}
                              </td>
                            ))}
                          <td className="text-bold">T</td>
                          <td className="text-bold">G</td>
                        </>
                      )}
                    </>
                  );
                })}
            </tr>
            {groupSubs.map((su, suInd) => (
              <tr key={suInd}>
                <td style={{ borderLeft: "solid 2px" }} className="text-bold">
                  {su.subjectName}
                </td>
                {su.entries.map((suC, suCInd) => (
                  <td key={suCInd}>{suC.marks || "-"}</td>
                ))}
              </tr>
            ))}
          </table>
          <table>
            <tr>
              <td>
                <table>
                  <tr>
                    <td>Subjects</td>
                  </tr>
                  {exmRepData.subjects.map((sub, subInd) => (
                    <tr key={subInd}>
                      <td>{sub.subjectName}</td>
                    </tr>
                  ))}
                </table>
              </td>
              <td>
                <table>
                  <tr>
                    {exmRepData.examReport.map((ex, exInd) => (
                      <td>
                        <tr>
                          <td key={exInd} colSpan={ex.exams.length}>
                            {ex.examGroup}
                          </td>
                        </tr>
                        <tr>
                          {ex.exams.map((exm, exmInd) => (
                            <td>
                              {exm.name}
                              <table>
                                {exm.data.map((m, mInd) => (
                                  <tr>
                                    <td>{m.marks}</td>
                                  </tr>
                                ))}
                              </table>
                            </td>
                          ))}
                        </tr>
                      </td>
                    ))}
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <div className="row border">
            <div className="col-3 border">
              Subjects
              {exmRepData.subjects.map((sub, subInd) => (
                <div className="row border">
                  <div key={subInd} className="col border">
                    {sub.subjectName}
                  </div>
                </div>
              ))}
            </div>
            <div className="col-9 border">
              <div className="row border">
                {exmRepData.examReport.map((ex, exInd) => (
                  <div key={exInd} className="col border">
                    {ex.examGroup}
                    <div className="row d-flex">
                      {ex.exams.map((exN, exNInd) => (
                        <div key={exNInd} className="col border">
                          {exN.name}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
