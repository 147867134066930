import React from "react";
import { default as dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import _ from "lodash";

import httpParams from "../../../lib/httpParams";
import { getDependencyCall } from "../../../lib/api/dependencies";
import { useGetMutateCall } from "../../../lib/api/get";

import "./expenseReport.css";

dayjs.extend(utc);
dayjs.extend(timezone);

export const ExpenseReport = ({
  primaryColor,
  secondaryColor,
  favicon,
  appVariables,
  getData,
  authData,
  modSchema,
}) => {
  const params = httpParams.adminOrgParams(appVariables);
  const getCallMutation = useGetMutateCall();

  const [selectedApplData, setSelectedApplData] = React.useState({
    data: { formData: [] },
  });
  const [gettingData, setGettingData] = React.useState(false);
  const [dates, setDates] = React.useState(null);

  const handleSelect = async (e) => {
    e.preventDefault();
    let formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    setDates({ from: data.expFrom, to: data.expTo });
    await getCallMutation
      .mutateAsync({
        apiURL: appVariables.REACT_APP_API_URL,
        apiEndpoint: "expenseAccounts",
        params:
          params +
          "&expFrom=" +
          dayjs.utc(data.expFrom).format("YYYY-MM-DD 00:00:00") +
          "&expTo=" +
          dayjs.utc(data.expTo).format("YYYY-MM-DD 00:00:00"),
      })
      .then((resp) => {
        setSelectedApplData(resp);
      });
    // const fDay = dayjs(monthVar).startOf("month").format("YYYY-MM-DD");
    // const lDay = dayjs(monthVar).endOf("month").format("YYYY-MM-DD");
  };
  return (
    <div className="contentbar">
      <div className="">
        <form onSubmit={(e) => handleSelect(e)} className="row">
          <div className="col-md-5 form-group">
            <input className="form-control" type="date" name="expFrom" />
          </div>
          <div className="col-md-5 form-group">
            <input className="form-control" type="date" name="expTo" />
          </div>
          <div className="col-md-2 form-group">
            <button type="submit" className="btn btn-primary">
              <i className="fa fa-search"></i>
            </button>
          </div>
        </form>
      </div>
      {gettingData && (
        <div className="row">
          <div className="calendar-container">
            <div className="calendar">
              <div className="year-header">
                <span className="year" id="label">
                  Loading...
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {gettingData !== true && (
        //selectedApplData.data.formData.length > 0 &&
        <div className="table-responsive">
          <button
            id="print-button"
            type="button"
            onClick={() => window.print()}
            className="btn btn-primary-rgba py-1 font-16"
          >
            <i className="fa fa-print mr-2" />
            Print
          </button>
          <div id="report-to-printExp">
            <table key={selectedApplData} className="table table-bordered">
              <tbody>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <span style={{ marginBottom: "0.5rem", fontWeight: "600" }}>
                      Expense Report
                    </span>
                    <br />
                    From{" "}
                    {dates !== null && dates.from
                      ? dayjs(dates.from).format("DD-MM-YYYY")
                      : null}{" "}
                    - To{" "}
                    {dates !== null && dates.to
                      ? dayjs(dates.to).format("DD-MM-YYYY")
                      : null}
                  </td>
                </tr>
                {selectedApplData !== null &&
                  selectedApplData.formData?.length > 0 &&
                  selectedApplData.formData.map((acc, accInd) => (
                    <tr key={accInd}>
                      <td style={{ fontWeight: "400" }}>
                        {acc.expenseAccountName} {`(${acc.expenseAccountType})`}
                        <table style={{ width: "100%", fontWeight: "normal" }}>
                          <tr style={{ width: "100%" }}>
                            <td>Date</td>
                            <td>Amount</td>
                            <td>Mode</td>
                            <td>Remarks</td>
                          </tr>
                          {acc.expenses.map((exp, expInd) => (
                            <tr key={expInd}>
                              <td>
                                {dayjs(exp.createdDateTime).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                              <td>{exp.expenseAmount}</td>
                              <td>{exp.expensePaymentType}</td>
                              <td>{exp.expenseRemarks}</td>
                            </tr>
                          ))}
                        </table>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
