import React from "react";
import { E500 } from "../../components/e500";
import { Form } from "../../components/form";

export const ModalForms = (props) => {
  try {
    return (
      <div
        className={`modal fade bd-example-modal-lg show`}
        style={{ display: "block", backgroundColor: "rgba(55,55,55,0.3)" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body  pt-2">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => props.setIsAdd(false)}
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="row">
                <div className="col-12">
                  <div className="modal-bg">
                    <h4 className="font-light m-b-0 mb-4">{props.formSchema.rowForm.formTitle}</h4>
                    <Form
                      defaultData={props.defaultData}
                      formSchema={props.formSchema.rowForm.update}
                      appVariables={props.appVariables}
                      isRowForm={true}
                      primaryColor={props.primaryColor}
                      secondaryColor={props.secondaryColor}
                      favicon={props.favicon}
                      crud={4}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
    );
  } catch (err) {
    return (
      <E500
        primaryColor={props.primaryColor}
        secondaryColor={props.secondaryColor}
        favicon={props.favicon}
        error={err}
      />
    );
  }
};
