import React from "react";
import _ from "lodash";
import httpParams from "../../../lib/httpParams";
import { getDependencyCall } from "../../../lib/api/dependencies";
import { useDependencies } from "../../../store/dependencies";
import { useAuthStore } from "../../../store/auth";
import { default as dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { E500 } from "../../../components/e500";
import { useGetMutateCall } from "../../../lib/api/get";

import "./attendanceReport.css";

dayjs.extend(utc);
dayjs.extend(timezone);

export const AttendanceReportChild = ({
	primaryColor,
	secondaryColor,
	favicon,
	appVariables,
	getData,
	authData,
	modSchema,
}) => {
	const params = httpParams.adminOrgParams(appVariables);
	const getCallMutation = useGetMutateCall();

	const [selectedApplData, setSelectedApplData] = React.useState({
		data: { formData: [] },
	});
	const [gettingData, setGettingData] = React.useState(false);
	const [monthVar, setMonthVar] = React.useState(null);
	const [dateFrom, setDateFrom] = React.useState(null);
	const [dateTo, setDateTo] = React.useState(null);
	const [classVar, setClassVar] = React.useState(null);
	const [dateTime, setDateTime] = React.useState(null);

	const appState = useAuthStore((state) => state);
	const { setDependency } = useDependencies();

	React.useEffect(() => {
		document.getElementById("siteTitle").innerHTML =
			appVariables.REACT_APP_PAGE_TITLE + " :: " + modSchema.pageTitle;

		const stateVar = { ...appState };

		if (modSchema.hasDependencies) {
			modSchema.dependeciesFormAPIS.map(async (api) => {
				if (!stateVar.dependeciesFormAPIS[api]) {
					stateVar.dependeciesFormAPIS[api] = await getDependencyCall(
						appVariables.REACT_APP_API_URL,
						api,
						params
					);
					setDependency(stateVar, stateVar.role, stateVar.roleId);
				}
			});
		}
	});

	const handleSelect = async (e) => {
		e.preventDefault();
		let formData = new FormData(e.currentTarget);
		const data = Object.fromEntries(formData);
		const fDay = dayjs(dateFrom).format("YYYY-MM-DD");
		const lDay = dayjs(dateTo).format("YYYY-MM-DD");

		if (dateFrom !== null && dateTo !== null) {
			setGettingData(true);
			setClassVar(data.class);
			await getCallMutation
				.mutateAsync({
					apiURL: appVariables.REACT_APP_API_URL,
					apiEndpoint: "attendance",
					params:
						params +
						"&checkInDateTimeFrom=" +
						dayjs.utc(fDay).format("YYYY-MM-DD 00:00:00") +
						"&checkInDateTimeTo=" +
						dayjs.utc(lDay).format("YYYY-MM-DD 23:59:59"),
				})
				.then((resp) => {
					setSelectedApplData({ data: resp });
				});
			setGettingData(false);
		}
	};
	try {
		return (
			<div className="contentbar">
				<div className="">
					<form onSubmit={(e) => handleSelect(e)} className="row">
						<div className="col-md-4 form-group">
							<select className="form-control" name="class">
								<option title="S C">Select Class Room</option>
								{authData.dependeciesFormAPIS["classSections"]?.formData
									.length > 0 &&
									authData.dependeciesFormAPIS["classSections"]?.formData.map(
										(ops, opsInd) => (
											<option
												key={opsInd}
												value={JSON.stringify({
													cId: ops.classSectionId,
													cName: ops.classSectionName,
												})}
											>
												{ops.classSectionName}
											</option>
										)
									)}
							</select>
						</div>
						<div className="col-md-3 form-group">
							<input
								className="form-control"
								onChange={(e) => setDateFrom(e.target.value)}
								type="date"
								name="dateFrom"
								placeholder="From"
							/>
						</div>
						<div className="col-md-3 form-group">
							<input
								className="form-control"
								onChange={(e) => setDateTo(e.target.value)}
								type="date"
								name="dateTo"
								placeholder="To"
							/>
							<input
								className="form-control"
								type="time"
								onChange={(e) => setDateTime(e.target.value)}
								name="dateTime"
							/>
						</div>
						<div className="col-md-2 form-group">
							<button type="submit" className="btn btn-primary">
								<i className="fa fa-search"></i>
							</button>
						</div>
					</form>
					<hr />
				</div>
				{gettingData && (
					<div className="row">
						<div className="calendar-container">
							<div className="calendar">
								<div className="year-header">
									<span className="year" id="label">
										Loading...
									</span>
								</div>
							</div>
						</div>
					</div>
				)}
				{gettingData !== true &&
					selectedApplData.data.formData.length > 0 &&
					monthVar &&
					classVar && (
						<div className="table-responsive">
							{/* <button
								id="print-button"
								type="button"
								onClick={() => window.print()}
								className="btn btn-primary-rgba py-1 font-16"
							>
								<i className="fa fa-print mr-2" />
								Print
							</button> */}
							<table className="table table-bordered">
								<tbody>
									<tr>
										<td className="text-bold">
											<strong>Name</strong>
										</td>
										<td className="text-bold">
											<strong>Room</strong>
										</td>
										<td className="text-bold">
											<strong>Date</strong>
										</td>
										<td className="text-bold">
											<strong>Check-In</strong>
										</td>
										<td className="text-bold">
											<strong>Check-Out</strong>
										</td>
									</tr>
									<tr>
										<td>Name</td>
										<td>Room</td>
										<td>Date</td>
										<td>Check-In</td>
										<td>Check-Out</td>
									</tr>
								</tbody>
							</table>
						</div>
					)}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
