import React from "react";
import { default as dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import _ from "lodash";

import httpParams from "../../../lib/httpParams";
import { useGetCall } from "../../../lib/api/get";

import "./scholarship.css";

dayjs.extend(utc);
dayjs.extend(timezone);

export const ScholarshipSummaryReport = ({
  primaryColor,
  secondaryColor,
  favicon,getData,
  appVariables,
  authData,
  modSchema,
}) => {
  const params = httpParams.adminOrgParams(appVariables);
  
  return (
    <div className="contentbar">
      <div className=""></div>
      {console.log(getData)}
      <div className="table-responsive">
        <button
          id="print-button"
          type="button"
          onClick={() => window.print()}
          className="btn btn-primary-rgba py-1 font-16"
        >
          <i className="fa fa-print mr-2" />
          Print
        </button>
        <div id="sdrreport-to-printExp">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td colSpan={7} style={{ textAlign: "center" }}>
                  <span style={{ marginBottom: "0.5rem", fontWeight: "600" }}>
                    Scholarship Due Report
                  </span><br />
                  <span className="mt-2">As On {dayjs().format("DD-MM-YYYY")}</span>
                </td>
              </tr>
              <tr style={{fontWeight:"bold"}}>
                <td>Date</td>
                <td>Name</td>
                <td>Scholarship</td>
                <td>Receipt #</td>
                <td>Amount</td>
                <td>Mode</td>
                <td>Paid To</td>
              </tr>
              {getData.data.formData &&
                getData.data.formData?.length > 0 &&
                getData.data.formData.sort((a,b)=>a.scholarshipDistributionDate-b.scholarshipDistributionDate).map((ssr, ssrInd) => (
                  <tr key={ssrInd}>
                    <td>{dayjs(ssr.scholarshipDistributionDate).format("DD-MM-YYYY")}</td>
                    <td>{ssr.userChildName}</td>
                    <td>{ssr.applicationScholarshipName}</td>
                    <td>{ssr.scholarshipDistributionNumber}</td>
                    <td>{ssr.scholarshipDistributionAmount.toFixed(2)}</td>
                    <td>{ssr.paymentMethod}</td>
                    <td>{ssr.scholarshipDistributionTo}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
