import React from "react";
import { default as dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { axios } from "../../lib/react-query/axios";
import httpParams from "../../lib/httpParams";
import { capAndMatch, vFAttendance } from "../../lib/FingerPrintDevice/fpFuncs";
import { E500 } from "../../components/e500";

const date = new Date();
const tzOffset = date.getTimezoneOffset();

var quality = 60; //(1 to 100) (recommanded minimum 55)
var timeout = 10; // seconds (minimum=10(recommanded), maximum=60, unlimited=0 )
var nooffinger = "1";

export const BioFPAttendance = ({
	authData,
	session,
	modSchema,
	navs,
	openMenu,
	setOpenMenu,
	routesData,
	appVariables,
	authsSession,
	primaryColor,
	secondaryColor,
	favicon,webSettings
}) => {
	const params = httpParams.adminOrgParams(appVariables);
	const [fpError, setFPError] = React.useState(false);
	const [fpSuccess, setFPSuccess] = React.useState(false);
	const [fpResponse, setFPResponse] = React.useState(null);
	const [disableCheckIn,setDisableCheckIn] = React.useState(false);

	React.useEffect(() => {
		let secTimer = setInterval( () => {
		  setDt(dayjs().format("DD-MM-YYYY HH:mm:ss").toLocaleString())
		},1000)
	
		return () => clearInterval(secTimer);
	}, []);
	
	if(disableCheckIn!==true && modSchema.moduleName==="StaffCheckIn" && webSettings["webSettings-contactForm"].configureStaffLogin.earlyCheckIn && dayjs().get("day") === webSettings["webSettings-contactForm"].configureStaffLogin.earlyCheckInOn.day){
		console.log("weekend Time up")
			setDisableCheckIn(true)
	}
	else if(disableCheckIn!==true && modSchema.moduleName==="StaffCheckIn")
	{
		//console.log(dayjs().format("HH:mm:ss"),dayjs(webSettings["webSettings-contactForm"].configureStaffLogin.disableTime).format("HH:mm:ss"))
		const t1 = new Date()
		const t2 = new Date(webSettings["webSettings-contactForm"].configureStaffLogin.disableTime)
		//console.log(dayjs(t1).format("HH:mm:ss"),dayjs(t2).format("HH:mm:ss"))
		if(dayjs(t1).format("HH:mm:ss")>=dayjs(t2).format("HH:mm:ss")){
			console.log("Weekday Time up")
			setDisableCheckIn(true)
		}
	}

		const [dt, setDt] = React.useState(new Date().toLocaleString());


	const resetFPForm = () => {
		setFPError(false);
		setFPResponse(null);
		document.getElementById("fpInput").value = "";
		document.getElementById("fpInput").focus();
	};

	const valFP = async () => {
		let start = Date.now();
		await vFAttendance(
			setFPError,
			setFPResponse,
			authData.dependeciesFormAPIS[modSchema.dependenciesToUse]?.formData,
			appVariables.REACT_APP_API_URL,
			modSchema.formAPI,
			params,
			modSchema.moduleName
		);
		// let timeTaken = Date.now() - start;
		// console.log("Total time taken : " + timeTaken + " milliseconds");
		//use this to keep device initialized all time
		//valFP()
	};

	const validateFP = async (data) => {
		if (data.length > 0) {
			await axios
				.get(
					appVariables.REACT_APP_API_URL +
						"/appl" +
						params +
						"&applicationNumber=" +
						data
				)
				.then(async (resp) => {
					if (
						resp.formData.length > 0 &&
						resp.formData[0].biometric_fp !== null
					) {
						// const fpResp = await capAndMatch(
						// 	quality,
						// 	timeout,
						// 	resp.formData[0].biometric_fp,
						// 	setFPError,
						// 	setFPResponse
						// );
						const formData = resp.formData[0];
						formData["attendanceType"] = 5;
						await vFAttendance(setFPError, setFPResponse, resp.formData);
						// await capAndMatch(
						// 	quality,
						// 	timeout,
						// 	resp.formData[0].biometric_fp,
						// 	setFPError,
						// 	setFPResponse
						// )
						// 	.then(async (resp) => {
						// 		if (resp === 1) {
						// 			await axios.post(
						// 				appVariables.REACT_APP_API_URL + "/attendance" + params,
						// 				formData
						// 			);
						// 		} else {
						// 			setFPError(true);
						// 		}
						// 	})
						// 	.catch(() => setFPError(true));
					} else {
						setFPError(true);
					}
				})
				.catch((err) => setFPError(true));
		} else {
			return;
		}
	};

	try {
		return (
			<div className="contentbar">
				<div id="header-with-footer-html">
					{dt}
					<div>
						{/* <input
							className="form-control"
							placeholder="Registration Number"
							type="text"
							id="fpInput"
							onBlur={(e) => valFP(e.target.value)}
							onFocus={(e) => {
								setFPError(false);
								setFPSuccess(false);
								setFPResponse(null);
							}}
						/> */}
						{disableCheckIn && <h6>Check-In time is over!</h6>}
						{fpError !== true && fpResponse === null && fpSuccess !== true && (
							<button
								className="btn btn-primary"
								id="fpInput"
								type="button"
								onClick={valFP}
								disabled={disableCheckIn}
							>
								Biometric Attendance
							</button>
						)}
					</div>
					{fpError && (
						<div className="modal-body text-center pb-5">
							<i className="icon icon-close fs-70 text-danger lh-1 my-4 d-inline-block" />{" "}
							<h4 className="text-danger mb-4">Error</h4>{" "}
							<p className="mb-4 mx-4">Invalid biometric, try again!</p>
							<button
								className="btn btn-danger pd-x-25"
								onClick={() => resetFPForm()}
							>
								Try Again
							</button>{" "}
						</div>
					)}
					{!fpError && fpResponse === "success" && (
						<div className="modal-body text-center pb-5">
							<i className="icon icon-close fs-70 text-success lh-1 my-4 d-inline-block" />{" "}
							<h4 className="text-success mb-4">Success</h4>{" "}
							<p className="mb-4 mx-4">Attendance Successfull!</p>
							<button
								className="btn btn-success pd-x-25"
								onClick={() => resetFPForm()}
							>
								Done
							</button>{" "}
						</div>
					)}
				</div>
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
