import axios from "axios";
import { axios as axio } from "../../lib/react-query/axios";

var uri = "http://localhost:8004/mfs100/";

export const captureFinger = (quality, timeout, setFPError, setFPResponse) => {
	var MFS100Request = {
		Quality: quality,
		TimeOut: timeout,
	};
	var jsondata = JSON.stringify(MFS100Request);
	return postMFS100Client("capture", jsondata, setFPError, setFPResponse);
};

export const capAndMatch = async (
	quality,
	timeout,
	fpString,
	setFPError,
	setFPResponse
) => {
	var MFS100Request = {
		Quality: quality,
		TimeOut: timeout,
		GalleryTemplate: fpString,
		BioType: "FMR", // you can paas here BioType as "ANSI" if you are using ANSI Template
	};
	var jsondata = JSON.stringify(MFS100Request);
	const res = await postMFS100Client(
		"match",
		jsondata,
		setFPError,
		setFPResponse
	);
	return res;
};

export const verifyFinger = (ProbFMR, GalleryFMR) => {
	var MFS100Request = {
		ProbTemplate: ProbFMR,
		GalleryTemplate: GalleryFMR,
		BioType: "FMR", // you can paas here BioType as "ANSI" if you are using ANSI Template
	};
	var jsondata = JSON.stringify(MFS100Request);
	return postMFS100Client("verify", jsondata);
};

export const vFAttendance = async (
	setFPError,
	setFPResponse,
	fData,
	api,
	formAPI,
	params,
	fpType
) => {
	var MFS100Request = {
		Quality: 60,
		TimeOut: 5,
	};
	var jsondata = JSON.stringify(MFS100Request);
	const filtData = [];
	console.log(fData)
	fData.map((f) => f.biometric_fp !== null && filtData.push(f));
	await axios
		.post(uri + "capture", jsondata, {
			headers: { "Content-Type": "application/json; charset=utf-8" },
		})
		.then(async (res) => {
			if (res.data?.IsoTemplate) {
				for (let i = 0; i < filtData.length; i++) {
					if (filtData[i].biometric_fp !== null) {
						let respNum = null;
						var MFS100Request1 = {
							ProbTemplate: res.data.IsoTemplate,
							GalleryTemplate: filtData[i].biometric_fp,
							BioType: "FMR", // you can paas here BioType as "ANSI" if you are using ANSI Template
						};
						var jsonData1 = JSON.stringify(MFS100Request1);
						await axios
							.post(uri + "verify", jsonData1, {
								headers: { "Content-Type": "application/json; charset=utf-8" },
							})
							.then(async (resp) => {
								if (resp.data?.Status) {
									respNum = filtData[i];
									const formData = filtData[i];
									// formData["attendanceType"] =
									// 	formAPI === "staffattendance" ? 0 : 5;
									// if (formAPI === "staffattendance") {
									// 	formData["authCodeFull"] =
									// 		formData.authCodePrefix + formData.authCode;
									// }
									if (fpType === "StaffCheckIn") {
										formData["attendanceType"] = 0;
										formData["authCodeFull"] =
											formData.authCodePrefix + formData.authCode;
									} else if (fpType === "StaffCheckOut") {
										formData["attendanceType"] = 2;
										formData["authCodeFull"] =
											formData.authCodePrefix + formData.authCode;
									} else {
										formData["attendanceType"] = 5;
									}
									if (
										fpType === "StaffCheckIn" ||
										fpType === "BiometricAttendance"
									) {
										await axio
											.post(api + "/" + formAPI + params, formData)
											.then((res) => setFPResponse("success"))
											.catch((err) => {
												setFPResponse("error");
												setFPError(true);
											});
									} else {
										await axio
											.put(api + "/" + formAPI + params, formData)
											.then((res) => setFPResponse("success"))
											.catch((err) => {
												setFPResponse("error");
												setFPError(true);
											});
									}
								}
							})
							.catch((err) => {
								respNum = "error";
								setFPResponse("error");
								setFPError(true);
							});
						if (respNum !== null && respNum !== "error") {
							break;
						}
						if (filtData.length === i + 1 && respNum === null) {
							setFPResponse("error");
							setFPError(true);
						}
					}
				}
			} else {
				setFPError(true);
				setFPResponse("error");
			}
		})
		.catch((err) => {
			setFPResponse("error");
			setFPError(true);
		});
};

const postMFS100Client = async (
	method,
	jsonData,
	setFPError,
	setFPResponse
) => {
	if (method === "capture") {
		await axios
			.post(uri + method, jsonData, {
				headers: { "Content-Type": "application/json; charset=utf-8" },
			})
			.then((res) => {
				if (res.data.ErrorCode === "0") {
					localStorage.setItem("mfsFP", res.data.IsoTemplate);
					setFPResponse("success");
				} else {
					setFPError(true);
					setFPResponse("error");
				}
			})
			.catch((err) => {
				setFPResponse("error");
				setFPError(true);
			});
	}

	if (method === "match") {
		let resp = null;
		await axios
			.post(uri + method, jsonData, {
				headers: { "Content-Type": "application/json; charset=utf-8" },
			})
			.then((res) => {
				if (res.data.Status) {
					setFPResponse("success");
					return (resp = 1);
				} else {
					setFPError(true);
					setFPResponse("error");
					return (resp = 0);
				}
			})
			.catch((err) => {
				setFPResponse("error");
				setFPError(true);
				return (resp = 0);
			});
		return resp;
	}
};

/////////// Classes

function Biometric(BioType, BiometricData, Pos, Nfiq, Na) {
	this.BioType = BioType;
	this.BiometricData = BiometricData;
	this.Pos = Pos;
	this.Nfiq = Nfiq;
	this.Na = Na;
}

function MFS100Request(BiometricArray) {
	this.Biometrics = BiometricArray;
}
