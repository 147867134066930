import React from "react";
import httpParams from "../../lib/httpParams";
import { default as dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import { useUpdateCall } from "../../lib/api/update";
import { E500 } from "../../components/e500";
import { useGetMutateCall } from "../../lib/api/get";
import { AttendanceModalChild } from "./attendanceCompChild";

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
const date = new Date();
const tzOffset = date.getTimezoneOffset();

export const ChildAttendance = (
	{
		primaryColor,
		secondaryColor,
		favicon,
		appVariables,
		getData,
		authData,
		modSchema,
	},
	props
) => {
	React.useEffect(() => {
		document.getElementById("siteTitle").innerHTML =
			appVariables.REACT_APP_PAGE_TITLE + " :: " + modSchema.pageTitle;
	});
	const params = httpParams.adminOrgParams(appVariables);
	const getCallMutation = useGetMutateCall();
	const [autoRefresh, setAutoRefresh] = React.useState(false);

	const [selectedAppl, setSelectedAppl] = React.useState(null);
	const [openActivity, setOpenActivity] = React.useState(false);
	const [activityAttenInd, setActivityAttenInd] = React.useState(null);
	const [gettingData, setGettingData] = React.useState(false);
	const [dates, setDates] = React.useState(null);
	const [selectedApplData, setSelectedApplData] = React.useState({
		data: { formData: [] },
	});

	// const getDatas = async () => {
	// 	await getCallMutation
	// 		.mutateAsync({
	// 			apiURL: appVariables.REACT_APP_API_URL,
	// 			apiEndpoint: "staffattendance",
	// 			params:
	// 				params +
	// 				"&checkInDateTime=" +
	// 				dayjs.utc(new Date()).format("YYYY-MM-DD HH:mm:ss") +
	// 				"&timeStamp=" +
	// 				dayjs.utc(new Date()).format("YYYY-MM-DD HH:mm:ss"),
	// 		})
	// 		.then((resp) => {
	// 			setSelectedApplData(resp.formData);
	// 		});
	// };
	// React.useEffect(() => getDatas(), []);
	const updateCallMutation = useUpdateCall();

	const onSubmit = async (data, type) => {
		const payload = { ...data };
		payload["attendanceType"] = type;

		await updateCallMutation
			.mutateAsync({
				url: appVariables.REACT_APP_API_URL,
				apiEndpoint: "attendance",
				invalidateWithParams: true,
				data: payload, // data,
				id: payload.attendanceId,
				messageTitle: "Attendance",
				message: "Attendance updated successfully!",
				params,
			})
			.then(async () => {
				await getCallMutation
					.mutateAsync({
						apiURL: appVariables.REACT_APP_API_URL,
						apiEndpoint: "attendance",
						params:
							params +
							"&checkInDateTime=" +
							dayjs
								.utc(dates === null ? new Date() : dates)
								.format("YYYY-MM-DD HH:mm:ss") +
							"&timdatesStamp=" +
							dayjs.utc(dates).format("YYYY-MM-DD HH:mm:ss"),
					})
					.then((resp) => {
						setSelectedApplData(resp.formData);
					});
			});
	};

	const getOnchangeAppl = async (e, typ) => {
		setDates(e);

		await getCallMutation
			.mutateAsync({
				apiURL: appVariables.REACT_APP_API_URL,
				apiEndpoint: "attendance",
				params:
					params +
					"&checkInDateTime=" +
					dayjs.utc(e).format("YYYY-MM-DD HH:mm:ss") +
					"&timdatesStamp=" +
					dayjs.utc(dates).format("YYYY-MM-DD HH:mm:ss"),
				doPolling: autoRefresh,
			})
			.then((resp) => {
				setSelectedApplData(resp.formData);
			});
	};

	const closeActivityModal = () => {
		setActivityAttenInd(null);
		setOpenActivity(false);
	};

	try {
		return (
			<div key={selectedApplData} className="contentbar">
				<div className="row">
					<div key="date" className="col-6">
						<input
							type="date"
							className="form-control"
							onChange={(e) => {
								getOnchangeAppl(e.target.value, "date");
							}}
							defaultValue={dates}
						/>
					</div>
					<div key="autoRefresh" className="col-6 text-right">
						Auto Refresh{" "}
						<input
							type="checkbox"
							onChange={() => setAutoRefresh(!autoRefresh)}
						/>
					</div>
				</div>
				<hr />
				<div key={selectedApplData} className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body" style={{ padding: "0px" }}>
								<div className="table-responsive">
									<table className="table mb-2">
										<tbody>
											{selectedApplData.length > 0 ? (
												selectedApplData
													.sort(
														(a, b) =>
															new Date(b.checkInDateTime) -
															new Date(a.checkInDateTime)
													)
													.map((atten, attenInd) => (
														<tr key={attenInd}>
															<td>
																<p className="d-none d-md-block">Check-In</p>
																<p className="d-block d-md-none">In</p>
																<div>
																	{dayjs(atten.checkInDateTime)
																		.utcOffset(tzOffset, true)
																		.format("hh:mm A")}{" "}
																	-
																	{atten.checkInConfirmedDateTime !== null
																		? dayjs(atten.checkInConfirmedDateTime)
																				.utcOffset(tzOffset, true)
																				.format("hh:mm A")
																		: " NA "}
																</div>
															</td>
															<td>
																<p className="d-none d-md-block">Check-Out</p>
																<p className="d-block d-md-none">Out</p>
																<div>
																	{atten.checkOutDateTime !== null
																		? dayjs(atten.checkOutDateTime)
																				.utcOffset(tzOffset, true)
																				.format("hh:mm A")
																		: " NA "}{" "}
																	-
																	{atten.checkOutConfirmedDateTime !== null
																		? dayjs(atten.checkOutConfirmedDateTime)
																				.utcOffset(tzOffset, true)
																				.format("hh:mm A")
																		: " NA "}
																</div>
															</td>
															<td>
																{atten.checkInConfirmedDateTime === null && (
																	<button
																		type="button"
																		onClick={() => onSubmit(atten, 1)}
																		className="btn btn-sm btn-success"
																	>
																		Check-In Confirm
																	</button>
																)}
																{atten.checkOutConfirmedDateTime === null && (
																	<button
																		type="button"
																		onClick={() => onSubmit(atten, 3)}
																		className="btn btn-sm btn-danger"
																	>
																		Check-out Confirm
																	</button>
																)}
																<button
																	className="btn btn-sm btn-outline-primary ml-2"
																	title="Activity"
																	onClick={() => {
																		setOpenActivity(true);
																		setActivityAttenInd(attenInd);
																	}}
																>
																	<i className="fa fa-tasks"></i>
																</button>
															</td>
														</tr>
													))
											) : (
												<tr>
													<td>No records found!</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				{openActivity && modSchema.noActivity !== true && (
					<AttendanceModalChild
						appVariables={appVariables}
						setOpenActivity={closeActivityModal}
						activityAttenInd={activityAttenInd}
						pdfSchema={modSchema.pdfSchema}
						pdfSchemaSettings={modSchema.pdfSchemaSettings}
						schema={modSchema}
						selectedApplData={selectedApplData}
						selectedAppl={selectedAppl}
						setSelectedAppl={setSelectedAppl}
						primaryColor={primaryColor}
						secondaryColor={secondaryColor}
						favicon={favicon}
					/>
				)}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
