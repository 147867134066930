import React from "react";
import _ from "lodash";
import httpParams from "../../../lib/httpParams";
import { getDependencyCall } from "../../../lib/api/dependencies";
import { useDependencies } from "../../../store/dependencies";
import { useAuthStore } from "../../../store/auth";
import { default as dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { E500 } from "../../../components/e500";
import { useGetMutateCall } from "../../../lib/api/get";

import "./attendanceReport.css";

dayjs.extend(utc);
dayjs.extend(timezone);

export const StaffAttendanceReport = ({
	primaryColor,
	secondaryColor,
	favicon,
	appVariables,
	getData,
	authData,
	modSchema,
}) => {
	const params = httpParams.adminOrgParams(appVariables);
	const getCallMutation = useGetMutateCall();

	const [selectedApplData, setSelectedApplData] = React.useState({
		data: { formData: [] },
	});
	const [gettingData, setGettingData] = React.useState(false);
	const [monthVar, setMonthVar] = React.useState(null);
	const [classVar, setClassVar] = React.useState(null);
	const [wrkDaysVar, setWrkDaysVar] = React.useState(null);

	const appState = useAuthStore((state) => state);
	const { setDependency } = useDependencies();

	React.useEffect(() => {
		document.getElementById("siteTitle").innerHTML =
			appVariables.REACT_APP_PAGE_TITLE + " :: " + modSchema.pageTitle;

		const stateVar = { ...appState };

		if (modSchema.hasDependencies) {
			modSchema.dependeciesFormAPIS.map(async (api) => {
				if (!stateVar.dependeciesFormAPIS[api]) {
					stateVar.dependeciesFormAPIS[api] = await getDependencyCall(
						appVariables.REACT_APP_API_URL,
						api,
						params
					);
					setDependency(stateVar, stateVar.role, stateVar.roleId);
				}
			});
		}
	});

	const getMonths = () => {
		const year = dayjs(monthVar).format("YYYY");
		const month = dayjs(monthVar).format("MM");
		const lastDayOfMonth = new Date(year, month, 0).getDate();
		var days = [];
		var weekday = new Array(7);
		weekday[0] = "Sun";
		weekday[1] = "Mon";
		weekday[2] = "Tues";
		weekday[3] = "Wed";
		weekday[4] = "Thu";
		weekday[5] = "Fri";
		weekday[6] = "Sat";

		for (let i = 1; i < lastDayOfMonth + 1; i++) {
			let ateendance = null;
			let attendanceData = {};
			const str = {
				date: new Date(year, month - 1, i).toDateString(),
				day: new Date(year, month - 1, i).getDay(),
				ateendance: ateendance,
				attendanceData: attendanceData,
			};
			days.push({ ...str, weekdays: weekday });
		}
		const monthDays = [
			...days,
			{ present: "P" },
			{ absent: "A" },
			{ total: "T" },
			{ percent: "%" },
		];

		return monthDays;
	};

	const getMonthsPrint = () => {
		const year = dayjs(monthVar).format("YYYY");
		const month = dayjs(monthVar).format("MM");
		const lastDayOfMonth = new Date(year, month, 0).getDate();
		var days = [];
		var weekday = new Array(7);
		weekday[0] = "Sun";
		weekday[1] = "Mon";
		weekday[2] = "Tues";
		weekday[3] = "Wed";
		weekday[4] = "Thu";
		weekday[5] = "Fri";
		weekday[6] = "Sat";

		for (let i = 1; i < lastDayOfMonth + 1; i++) {
			let ateendance = null;
			let attendanceData = {};
			const str = {
				date: new Date(year, month - 1, i).toDateString(),
				day: new Date(year, month - 1, i).getDay(),
				ateendance: ateendance,
				attendanceData: attendanceData,
			};
			days.push({ ...str, weekdays: weekday });
		}
		const monthDays = [
			...days,
			{ present: "P" },
			{ absent: "A" },
			{ total: "T" },
			{ percent: "%" },
		];

		const retArr = monthDays.slice(0, 15);
		return retArr;
		//return monthDays;
	};
	const getMonthsPrint2 = () => {
		const year = dayjs(monthVar).format("YYYY");
		const month = dayjs(monthVar).format("MM");
		const lastDayOfMonth = new Date(year, month, 0).getDate();
		var days = [];
		var weekday = new Array(7);
		weekday[0] = "Sun";
		weekday[1] = "Mon";
		weekday[2] = "Tues";
		weekday[3] = "Wed";
		weekday[4] = "Thu";
		weekday[5] = "Fri";
		weekday[6] = "Sat";

		for (let i = 1; i < lastDayOfMonth + 1; i++) {
			let ateendance = null;
			let attendanceData = {};
			const str = {
				date: new Date(year, month - 1, i).toDateString(),
				day: new Date(year, month - 1, i).getDay(),
				ateendance: ateendance,
				attendanceData: attendanceData,
			};
			days.push({ ...str, weekdays: weekday });
		}
		const monthDays = [
			...days,
			{ present: "P" },
			{ absent: "A" },
			{ total: "T" },
			{ percent: "%" },
		];

		const retArr = monthDays.slice(15);
		return retArr;
		//return monthDays;
	};

	function getMonthlyStats(attenData) {
		const year = dayjs(monthVar).format("YYYY");
		const month = dayjs(monthVar).format("MM");
		const lastDayOfMonth = new Date(year, month, 0).getDate();
		var days = [];
		var weekday = new Array(7);
		weekday[0] = "Sun";
		weekday[1] = "Mon";
		weekday[2] = "Tues";
		weekday[3] = "Wed";
		weekday[4] = "Thu";
		weekday[5] = "Fri";
		weekday[6] = "Sat";

		for (let i = 1; i < lastDayOfMonth + 1; i++) {
			let ateendance = null;
			let attendanceData = {};
			let todaysCount = 0;

			if (attenData.length > 0) {
				attenData.map((a) =>
					dayjs(a.checkInDateTime).format("DD-MM-YYYY") ===
					dayjs(new Date(year, month - 1, i).toDateString()).format(
						"DD-MM-YYYY"
					)
						? (todaysCount = todaysCount + 1)
						: null
				);
			}

			const str = {
				date: new Date(year, month - 1, i).toDateString(),
				day: new Date(year, month - 1, i).getDay(),
				todaysCount: todaysCount,
			};

			days.push({ ...str });
		}
		return days;
	}

	function getMonthlyStatsPrint(attenData) {
		const year = dayjs(monthVar).format("YYYY");
		const month = dayjs(monthVar).format("MM");
		const lastDayOfMonth = new Date(year, month, 0).getDate();
		var days = [];
		var weekday = new Array(7);
		weekday[0] = "Sun";
		weekday[1] = "Mon";
		weekday[2] = "Tues";
		weekday[3] = "Wed";
		weekday[4] = "Thu";
		weekday[5] = "Fri";
		weekday[6] = "Sat";

		for (let i = 1; i < lastDayOfMonth + 1; i++) {
			let ateendance = null;
			let attendanceData = {};
			let todaysCount = 0;

			if (attenData.length > 0) {
				attenData.map((a) =>
					dayjs(a.checkInDateTime).format("DD-MM-YYYY") ===
					dayjs(new Date(year, month - 1, i).toDateString()).format(
						"DD-MM-YYYY"
					)
						? (todaysCount = todaysCount + 1)
						: null
				);
			}

			const str = {
				date: new Date(year, month - 1, i).toDateString(),
				day: new Date(year, month - 1, i).getDay(),
				todaysCount: todaysCount,
			};

			days.push({ ...str });
		}
		const retArr = days.slice(0, 15);
		return retArr;
		//return days;
	}

	function getMonthlyStatsPrint2(attenData) {
		const year = dayjs(monthVar).format("YYYY");
		const month = dayjs(monthVar).format("MM");
		const lastDayOfMonth = new Date(year, month, 0).getDate();
		var days = [];
		var weekday = new Array(7);
		weekday[0] = "Sun";
		weekday[1] = "Mon";
		weekday[2] = "Tues";
		weekday[3] = "Wed";
		weekday[4] = "Thu";
		weekday[5] = "Fri";
		weekday[6] = "Sat";

		for (let i = 1; i < lastDayOfMonth + 1; i++) {
			let ateendance = null;
			let attendanceData = {};
			let todaysCount = 0;

			if (attenData.length > 0) {
				attenData.map((a) =>
					dayjs(a.checkInDateTime).format("DD-MM-YYYY") ===
					dayjs(new Date(year, month - 1, i).toDateString()).format(
						"DD-MM-YYYY"
					)
						? (todaysCount = todaysCount + 1)
						: null
				);
			}

			const str = {
				date: new Date(year, month - 1, i).toDateString(),
				day: new Date(year, month - 1, i).getDay(),
				todaysCount: todaysCount,
			};

			days.push({ ...str });
		}
		const retArr = days.slice(15);
		return retArr;
		//return days;
	}

	function getMonthlyReport(attenData, yea, mont) {
		const year = dayjs(monthVar).format("YYYY");
		const month = dayjs(monthVar).format("MM");
		const lastDayOfMonth = new Date(year, month, 0).getDate();
		var days = [];
		var weekday = new Array(7);
		weekday[0] = "Sun";
		weekday[1] = "Mon";
		weekday[2] = "Tues";
		weekday[3] = "Wed";
		weekday[4] = "Thu";
		weekday[5] = "Fri";
		weekday[6] = "Sat";

		for (let i = 1; i < lastDayOfMonth + 1; i++) {
			let ateendance = null;
			let attendanceData = {};

			if (attenData.length > 0) {
				attenData.map((a) =>
					dayjs(a.checkInDateTime).format("DD-MM-YYYY") ===
					dayjs(new Date(year, month - 1, i).toDateString()).format(
						"DD-MM-YYYY"
					)
						? (ateendance = 0)
						: null
				);

				attenData.map((a) =>
					dayjs(a.checkInDateTime).format("DD-MM-YYYY") ===
					dayjs(new Date(year, month - 1, i).toDateString()).format(
						"DD-MM-YYYY"
					)
						? (attendanceData = a)
						: null
				);
			}

			const str = {
				date: new Date(year, month - 1, i).toDateString(),
				day: new Date(year, month - 1, i).getDay(),
				ateendance: ateendance,
				attendanceData: attendanceData,
			};
			days.push({ ...str, weekdays: weekday });
		}

		const regAtten = [];

		attenData.map((a) =>
			regAtten.push({
				att: a,
				daysAtten: [],
			})
		);

		const reggg = [];
		attenData.map((m, mI) => {
			reggg.push({ name: m });
			days.map((d, dI) => {
				const stri = {
					name: m,
					att: [],
				};
				reggg.push({ m, attend: d });
			});
		});

		let cps = 0,
			cabs = 0;
		for (let i = 0; i < days.length; i++) {
			if (days[i].day > 0 && days[i].ateendance === null) {
				cabs = cabs + 1;
			} else if (days[i].day > 0) {
				cps = cps + 1;
			}
		}

		let percentAtt = (cps / days.length) * 100;

		const monthlyDays = [
			...days,
			{ present: cps },
			{ absent: cabs },
			{ total: cabs + cps },
			{ attenPercent: percentAtt },
		];
		
		const holidays =
	  authData.dependeciesFormAPIS["academicYearHolidays"]?.formData;
	  const filtData = monthlyDays.filter((days) => {
		  return !holidays.some((holiday) => dayjs(days.date).format("DD-MM-YYYY") === dayjs(holiday.academicYearHolidayDate).format("DD-MM-YYYY") ) 
		});
		const fltTotalDay=filtData.filter((f)=>f.date)
		monthlyDays.map((m)=>
		m.total ? m.total=fltTotalDay.length:null
		)

		const fltPrsntDay=fltTotalDay.filter((f)=>f.ateendance !== null)
		monthlyDays.map((m)=>
		m.present ? m.present=fltPrsntDay.length:null
		)

		const fltAbsntDay=fltTotalDay.filter((f)=>f.ateendance === null)
		monthlyDays.map((m)=>
		m.absent ? m.absent=fltAbsntDay.length:null
		)

		let fltPercnt = (fltPrsntDay.length / fltTotalDay.length) * 100;
		monthlyDays.map((m)=>
		m.attenPercent ? m.attenPercent=fltPercnt:null
		)

		return monthlyDays;
	}

	function getMonthlyReportPrint(attenData, yea, mont) {
		const year = dayjs(monthVar).format("YYYY");
		const month = dayjs(monthVar).format("MM");
		const lastDayOfMonth = new Date(year, month, 0).getDate();
		var days = [];
		var weekday = new Array(7);
		weekday[0] = "Sun";
		weekday[1] = "Mon";
		weekday[2] = "Tues";
		weekday[3] = "Wed";
		weekday[4] = "Thu";
		weekday[5] = "Fri";
		weekday[6] = "Sat";

		for (let i = 1; i < lastDayOfMonth + 1; i++) {
			let ateendance = null;
			let attendanceData = {};

			if (attenData.length > 0) {
				attenData.map((a) =>
					dayjs(a.checkInDateTime).format("DD-MM-YYYY") ===
					dayjs(new Date(year, month - 1, i).toDateString()).format(
						"DD-MM-YYYY"
					)
						? (ateendance = 0)
						: null
				);

				attenData.map((a) =>
					dayjs(a.checkInDateTime).format("DD-MM-YYYY") ===
					dayjs(new Date(year, month - 1, i).toDateString()).format(
						"DD-MM-YYYY"
					)
						? (attendanceData = a)
						: null
				);
			}

			const str = {
				date: new Date(year, month - 1, i).toDateString(),
				day: new Date(year, month - 1, i).getDay(),
				ateendance: ateendance,
				attendanceData: attendanceData,
			};
			days.push({ ...str, weekdays: weekday });
		}

		const regAtten = [];

		attenData.map((a) =>
			regAtten.push({
				att: a,
				daysAtten: [],
			})
		);

		const reggg = [];
		attenData.map((m, mI) => {
			reggg.push({ name: m });
			days.map((d, dI) => {
				const stri = {
					name: m,
					att: [],
				};
				reggg.push({ m, attend: d });
			});
		});

		let cps = 0,
			cabs = 0;
		for (let i = 0; i < days.length; i++) {
			if (days[i].day > 0 && days[i].ateendance === null) {
				cabs = cabs + 1;
			} else if (days[i].day > 0) {
				cps = cps + 1;
			}
		}

		let percentAtt = (cps / days.length) * 100;

		const monthlyDays = [
			...days,
			{ present: cps },
			{ absent: cabs },
			{ total: cabs + cps },
			{ attenPercent: percentAtt },
		];
		const retArr = monthlyDays.slice(0, 15);
		return retArr;
		//return monthlyDays;
	}

	function getMonthlyReportPrint2(attenData, yea, mont) {
		const year = dayjs(monthVar).format("YYYY");
		const month = dayjs(monthVar).format("MM");
		const lastDayOfMonth = new Date(year, month, 0).getDate();
		var days = [];
		var weekday = new Array(7);
		weekday[0] = "Sun";
		weekday[1] = "Mon";
		weekday[2] = "Tues";
		weekday[3] = "Wed";
		weekday[4] = "Thu";
		weekday[5] = "Fri";
		weekday[6] = "Sat";

		for (let i = 1; i < lastDayOfMonth + 1; i++) {
			let ateendance = null;
			let attendanceData = {};

			if (attenData.length > 0) {
				attenData.map((a) =>
					dayjs(a.checkInDateTime).format("DD-MM-YYYY") ===
					dayjs(new Date(year, month - 1, i).toDateString()).format(
						"DD-MM-YYYY"
					)
						? (ateendance = 0)
						: null
				);

				attenData.map((a) =>
					dayjs(a.checkInDateTime).format("DD-MM-YYYY") ===
					dayjs(new Date(year, month - 1, i).toDateString()).format(
						"DD-MM-YYYY"
					)
						? (attendanceData = a)
						: null
				);
			}

			const str = {
				date: new Date(year, month - 1, i).toDateString(),
				day: new Date(year, month - 1, i).getDay(),
				ateendance: ateendance,
				attendanceData: attendanceData,
			};
			days.push({ ...str, weekdays: weekday });
		}

		const regAtten = [];

		attenData.map((a) =>
			regAtten.push({
				att: a,
				daysAtten: [],
			})
		);

		const reggg = [];
		attenData.map((m, mI) => {
			reggg.push({ name: m });
			days.map((d, dI) => {
				const stri = {
					name: m,
					att: [],
				};
				reggg.push({ m, attend: d });
			});
		});

		let cps = 0,
			cabs = 0;
		for (let i = 0; i < days.length; i++) {
			if (days[i].day > 0 && days[i].ateendance === null) {
				cabs = cabs + 1;
			} else if (days[i].day > 0) {
				cps = cps + 1;
			}
		}

		let percentAtt = (cps / days.length) * 100;

		const monthlyDays = [
			...days,
			{ present: cps },
			{ absent: cabs },
			{ total: cabs + cps },
			{ attenPercent: percentAtt },
		];
		const retArr = monthlyDays.slice(15);

		const holidays =
	  authData.dependeciesFormAPIS["academicYearHolidays"]?.formData;
	  const filtData = monthlyDays.filter((days) => {
		  return !holidays.some((holiday) => dayjs(days.date).format("DD-MM-YYYY") === dayjs(holiday.academicYearHolidayDate).format("DD-MM-YYYY") ) 
		});
		const fltTotalDay=filtData.filter((f)=>f.date)
		monthlyDays.map((m)=>
		m.total ? m.total=fltTotalDay.length:null
		)

		const fltPrsntDay=fltTotalDay.filter((f)=>f.ateendance !== null)
		monthlyDays.map((m)=>
		m.present ? m.present=fltPrsntDay.length:null
		)

		const fltAbsntDay=fltTotalDay.filter((f)=>f.ateendance === null)
		monthlyDays.map((m)=>
		m.absent ? m.absent=fltAbsntDay.length:null
		)

		let fltPercnt = (fltPrsntDay.length / fltTotalDay.length) * 100;
		monthlyDays.map((m)=>
		m.attenPercent ? m.attenPercent=fltPercnt:null
		)

		return retArr;
		//return monthlyDays;
	}

	let totColumns = getMonths().length;

	const groupBy = (array, key) => {
		const groups = _.groupBy(array, key);
		const attends = [];
		Object.entries(groups).map(([key, value]) => {
			const kArr = [];
			kArr.push({ attens: value });
			if (value.length > 0) {
				attends.push({
					attends: value,
				});
			}
		});
		return attends;
	};

	const handleSelect = async (e) => {
		e.preventDefault();
		let formData = new FormData(e.currentTarget);
		const data = Object.fromEntries(formData);
		const fDay = dayjs(monthVar).startOf("month").format("YYYY-MM-DD");
		const lDay = dayjs(monthVar).endOf("month").format("YYYY-MM-DD");

		if (monthVar !== null) {
			setGettingData(true);
			setClassVar(data.class);
			await getCallMutation
				.mutateAsync({
					apiURL: appVariables.REACT_APP_API_URL,
					apiEndpoint: "staffattendance",
					params:
						params +
						"&checkInDateTimeFrom=" +
						dayjs.utc(fDay).format("YYYY-MM-DD 00:00:00") +
						"&checkInDateTimeTo=" +
						dayjs.utc(lDay).format("YYYY-MM-DD 23:59:59"),
				})
				.then((resp) => {
					setSelectedApplData({ data: resp });
				});
			setGettingData(false);
		}
	};

	function getDatesBetween(startDate, endDate) {
			
		let initialTime = startDate
,endTime     = endDate
,arrTime     = []
;
for (let q = initialTime; q <= endTime; q.setDate(q.getDate() + 1)) {
arrTime.push(q.toString());
}
		
		return arrTime;
	  }

	const showAttendance = (data, cInd,attenData) => {
		let returnVal = { val: "A", text: "Absent" };

		const holidays =
		  authData.dependeciesFormAPIS["academicYearHolidays"]?.formData;
		const filtData = holidays.filter(
		  (f) =>
			dayjs(f.academicYearHolidayDate).format("DD-MM-YYYY") ===
			dayjs(data.date).format("DD-MM-YYYY")
		);
		if (data.ateendance === 0 || data.ateendance === 5) {
		  returnVal = { val: "P", text: "Present" };
		}		
		
		if (filtData.length > 0) {
			filtData.map((f) =>
			f.academicYearHolidayType === "WEEKLY"
			? (returnVal = { val: "", text: "Weekly Holiday" })
			: null
			);
		}
		if (filtData.length > 0) {
		  filtData.map((f) =>
		  f.academicYearHolidayType === "VACATION"
		  ? (returnVal = { val: "V", text: f.academicYearHolidayName })
		  : null
		  );
		}

		
		if (filtData.length > 0) {
			filtData.map((f) =>
			f.academicYearHolidayType === "GENERAL"
			? (returnVal = { val: "H", text: f.academicYearHolidayName })
			: null
			);
		}

		const leaves =
		authData.dependeciesFormAPIS["staffLeaveRequests"]?.formData;
		const filtLeaves = leaves.filter((days) => 
		days.userId === attenData.attends[0].userId
		);
		  let leaveDays = []
		filtLeaves.map((m)=>
		m.leaveRequestStatus === "APPROVED" ? leaveDays = getDatesBetween(new Date(m.leaveFromDate), new Date(m.leaveToDate)):[]
		)
		
		if(leaveDays.length>0 && data.ateendance ===null){
			leaveDays.map((l)=>
			dayjs(l).format("DD-MM-YYYY")===dayjs(data.date).format("DD-MM-YYYY") ? (returnVal = { val: "L", text: "Leave" })
			: null
			)
		}

		return (
		  <td
			key={cInd}
			title={returnVal.text}
			className={`b-all p-1 ${
			  returnVal.text === "Weekly Holiday" ? "bg-danger" : ""
			}`}
		  >
			{returnVal.val}
		  </td>
		);
	  };
	
	  const showAttendanceCount=(data, cInd,isBlank=false)=>{
		console.log(data)
		let returnVal = { val: "A", text: "Absent" };
		const holidays =
		  authData.dependeciesFormAPIS["academicYearHolidays"]?.formData;
		const filtData = holidays.filter(
		  (f) =>
			dayjs(f.academicYearHolidayDate).format("DD-MM-YYYY") ===
			dayjs(data.date).format("DD-MM-YYYY")
		);
		
		if (data.checkInDateTime) {
		  returnVal = { val: data.todaysCount, text: "Present" };
		}
		if (filtData.length > 0) {
		  filtData.map((f) =>
			f.academicYearHolidayType === "WEEKLY"
			  ? (returnVal = { val: "", text: "Weekly Holiday" })
			  : null
		  );
		}
		if (filtData.length > 0) {
		  filtData.map((f) =>
			f.academicYearHolidayType === "VACATION"
			  ? (returnVal = { val: "V", text: f.academicYearHolidayName })
			  : null
		  );
		}
		if (filtData.length > 0) {
		  filtData.map((f) =>
			f.academicYearHolidayType === "GENERAL"
			  ? (returnVal = { val: "H", text: f.academicYearHolidayName })
			  : null
		  );
		}
	
		return (
			<td
								key={cInd}
								className={`${isBlank ? "p3-5 pb-3":""} b-all p-1 ${
									returnVal.text === "Weekly Holiday" ? "bg-danger" : ""
								}`}
								title={returnVal.text}
							  >
								{isBlank?" ":returnVal.val}
							  </td>
		);
	  };

	try {
		return (
			<div className="contentbar">
				<div className="">
					<form onSubmit={(e) => handleSelect(e)} className="row">
						<div className="col-md-6 form-group">
							<input
								className="form-control"
								onChange={(e) => setMonthVar(e.target.value)}
								type="month"
								name="month"
							/>
						</div>
						<div className="col-md-2 form-group">
							<button type="submit" className="btn btn-primary">
								<i className="fa fa-search"></i>
							</button>
						</div>
					</form>
					<hr />
				</div>
				{gettingData && (
					<div className="row">
						<div className="calendar-container">
							<div className="calendar">
								<div className="year-header">
									<span className="year" id="label">
										Loading...
									</span>
								</div>
							</div>
						</div>
					</div>
				)}
				{gettingData !== true &&
					selectedApplData.data.formData.length > 0 &&
					monthVar && (
						<div className="table-responsive">
							<button
								id="print-button"
								type="button"
								onClick={() => window.print()}
								className="btn btn-primary-rgba py-1 font-16"
							>
								<i className="fa fa-print mr-2" />
								Print
							</button>
							<table className="table table-bordered">
								<tbody>
									<tr>
										<td
											colSpan={
												totColumns === 32
													? 16 + 1
													: totColumns === 33
													? 16 + 1
													: totColumns === 34
													? 17 + 1
													: 17 + 1
											}
											className="text-bold"
										>
											<strong>
												Month: {dayjs(monthVar).format("MMMM - YYYY")}
											</strong>
										</td>
										<td
											colSpan={
												totColumns === 32
													? 16 + 1
													: totColumns === 33
													? 17 + 1
													: totColumns === 34
													? 17 + 1
													: 18 + 1
											}
											className="text-bold"
										>
											<strong>Working Days: {wrkDaysVar}</strong>
										</td>
									</tr>
									<tr>
										<td className="text-bold">
											<strong>Name</strong>
										</td>
										{getMonths().map((d, dInd) => (
											<td key={dInd}>
												<strong>
													{d.date
														? dayjs(d.date).format("D")
														: d.present
														? d.present
														: d.absent
														? d.absent
														: d.total
														? d.total
														: d.percent}
												</strong>
											</td>
										))}
									</tr>
									{groupBy(selectedApplData.data.formData, "userId").map(
										(r, rInd) => (
											<tr key={rInd}>
												<td>{r.attends[0].AppUser.userFullName}</td>
												{getMonthlyReport(r.attends).map((c, cInd) =>
													dayjs(c.date).isBefore(dayjs(new Date())) ? (
														showAttendance(c, cInd,r)
													) : (
														<td>
															{c.attenPercent
																? Math.round(c.attenPercent)
																: c.present
																? c.present
																: c.absent || c.total || "-"}
														</td>
													)
												)}
											</tr>
										)
									)}
									<tr style={{ borderTopColor: "#000000" }}>
										<td>
											<strong>Morning</strong>
										</td>
										{getMonthlyStats(selectedApplData.data.formData).map(
											(s, sInd) =>
												dayjs(s.date).isBefore(dayjs(new Date())) ? (
													<td key={sInd} className={`b-all p-1 ${
														s.day === 0 ? "bg-danger" : ""
													}`}>
													{s.day > 0 && s.todaysCount}
												  </td>
												) : (
													<td>-</td>
												)
										)}
									</tr>
									<tr>
										<td>
											<strong>Afternoon</strong>
										</td>
										{getMonthlyStats(selectedApplData.data.formData).map(
											(s, sInd) =>
												dayjs(s.date).isBefore(dayjs(new Date())) ? (
													<td key={sInd} className={`b-all p-1 ${
														s.day === 0 ? "bg-danger" : ""
													}`}>
													{s.day > 0 && s.todaysCount}
												  </td>
												) : (
													<td>-</td>
												)
										)}
									</tr>
									<tr>
										<td>
											<strong>Average</strong>
										</td>
										{getMonthlyStats(selectedApplData.data.formData).map(
											(s, sInd) =>
												dayjs(s.date).isBefore(dayjs(new Date())) ? (
													<td key={sInd} className={`b-all p-1 ${
														s.day === 0 ? "bg-danger" : ""
													}`}>
													{s.day > 0 && s.todaysCount}
												  </td>
												) : (
													<td>-</td>
												)
										)}
									</tr>
									<tr>
										<td>
											<strong>Signature</strong>
										</td>
										{getMonthlyStats(selectedApplData.data.formData).map(
											(s, sInd) =>
												dayjs(s.date).isBefore(dayjs(new Date())) ? (
													<td
							key={sInd}
							className={`p3-5 pb-3 b-all p-1 ${
								s.day === 0 ? "bg-danger" : ""
							}`}
						></td>
												) : (
													<td>-</td>
												)
										)}
									</tr>
								</tbody>
							</table>
							<div id="report-to-print">
								<table
									style={{ pageBreakAfter: "always" }}
									className="table table-bordered"
								>
									<tbody>
										<tr>
											<td colSpan={16} className="text-bold">
												<strong>
													Month: {dayjs(monthVar).format("MMMM - YYYY")}
												</strong>
											</td>
										</tr>
										<tr>
											<td className="text-bold">
												<strong>Name</strong>
											</td>
											{getMonthsPrint().map((d, dInd) => (
												<td key={dInd}>
													<strong>
														{d.date
															? dayjs(d.date).format("D")
															: d.present
															? d.present
															: d.absent
															? d.absent
															: d.total
															? d.total
															: d.percent}
													</strong>
												</td>
											))}
										</tr>
										{groupBy(selectedApplData.data.formData, "userId").map(
											(r, rInd) => (
												<tr key={rInd}>
													<td>{r.attends[0].AppUser.userFullName}</td>
													{getMonthlyReportPrint(r.attends).map((c, cInd) =>
														dayjs(c.date).isBefore(dayjs(new Date())) ? (
															// <td
															// 	key={cInd}
															// 	title={c.date}
															// 	className={`b-all p-1 ${
															// 		c.day === 0 ? "bg-danger" : ""
															// 	}`}
															// >
															// 	{c.name
															// 		? c.name
															// 		: c.day === 0
															// 		? ""
															// 		: c.ateendance === null
															// 		? "A"
															// 		: c.ateendance === undefined
															// 		? "P"
															// 		: c.present
															// 		? c.present
															// 		: c.absent
															// 		? c.absent
															// 		: c.total || c.attenPercent}
															// </td>
															showAttendance(c, cInd,r)
														) : (
															<td>
																{c.attenPercent
																	? Math.round(c.attenPercent)
																	: c.present
																	? c.present
																	: c.absent || c.total || "-"}
															</td>
														)
													)}
												</tr>
											)
										)}
										<tr style={{ borderTopColor: "#000000" }}>
											<td>
												<strong>Morning</strong>
											</td>
											{getMonthlyStatsPrint(selectedApplData.data.formData).map(
												(s, sInd) =>
													dayjs(s.date).isBefore(dayjs(new Date())) ? (
														<td
															key={sInd}
															className={`b-all p-1 ${
																s.day === 0 ? "bg-danger" : ""
															}`}
														>
															{s.day > 0 && s.todaysCount}
														</td>
													) : (
														<td>-</td>
													)
											)}
										</tr>
										<tr>
											<td>
												<strong>Afternoon</strong>
											</td>
											{getMonthlyStatsPrint(selectedApplData.data.formData).map(
												(s, sInd) =>
													dayjs(s.date).isBefore(dayjs(new Date())) ? (
														<td
															key={sInd}
															className={`b-all p-1 ${
																s.day === 0 ? "bg-danger" : ""
															}`}
														>
															{s.day > 0 && s.todaysCount}
														</td>
													) : (
														<td>-</td>
													)
											)}
										</tr>
										<tr>
											<td>
												<strong>Average</strong>
											</td>
											{getMonthlyStatsPrint(selectedApplData.data.formData).map(
												(s, sInd) =>
													dayjs(s.date).isBefore(dayjs(new Date())) ? (
														<td
															key={sInd}
															className={`b-all p-1 ${
																s.day === 0 ? "bg-danger holidayBG" : ""
															}`}
														>
															{s.day > 0 && s.todaysCount}
														</td>
													) : (
														<td>-</td>
													)
											)}
										</tr>
										<tr>
											<td>
												<strong>Signature</strong>
											</td>
											{getMonthlyStatsPrint(selectedApplData.data.formData).map(
												(s, sInd) =>
													dayjs(s.date).isBefore(dayjs(new Date())) ? (
														<td
															key={sInd}
															className={`p3-5 pb-3 b-all p-1 ${
																s.day === 0 ? "bg-danger" : ""
															}`}
														></td>
													) : (
														<td>-</td>
													)
											)}
										</tr>
									</tbody>
								</table>
								<table className="table table-bordered">
									<tbody>
										<tr>
											<td colSpan={19} className="text-bold">
												<strong>
													Working Days: {dayjs(monthVar).format("MMMM - YYYY")}
												</strong>
											</td>
										</tr>
										<tr>
											{getMonthsPrint2().map((d, dInd) => (
												<td key={dInd}>
													<strong>
														{d.date
															? dayjs(d.date).format("D")
															: d.present
															? d.present
															: d.absent
															? d.absent
															: d.total
															? d.total
															: d.percent}
													</strong>
												</td>
											))}
										</tr>
										{groupBy(selectedApplData.data.formData, "userId").map(
											(r, rInd) => (
												<tr key={rInd}>
													{getMonthlyReportPrint2(r.attends).map((c, cInd) =>
														dayjs(c.date).isBefore(dayjs(new Date())) ? (
															// <td
															// 	key={cInd}
															// 	title={c.date}
															// 	className={`b-all p-1 ${
															// 		c.day === 0 ? "bg-danger" : ""
															// 	}`}
															// >
															// 	<strong>
															// 		{c.name
															// 			? c.name
															// 			: c.day === 0
															// 			? ""
															// 			: c.ateendance === null
															// 			? "A"
															// 			: c.ateendance === undefined
															// 			? "P"
															// 			: c.present
															// 			? c.present
															// 			: c.absent
															// 			? c.absent
															// 			: c.total || c.attenPercent}
															// 	</strong>
															// </td>
															showAttendance(c, cInd,r)
														) : (
															<td>
																{c.attenPercent
																	? Math.round(c.attenPercent)
																	: c.present
																	? c.present
																	: c.absent || c.total || "-"}
															</td>
														)
													)}
												</tr>
											)
										)}
										<tr style={{ borderTopColor: "#000000" }}>
											{getMonthlyStatsPrint2(
												selectedApplData.data.formData
											).map((s, sInd) =>
												dayjs(s.date).isBefore(dayjs(new Date())) ? (
													<td
														key={sInd}
														className={`b-all p-1 ${
															s.day === 0 ? "bg-danger" : ""
														}`}
													>
														{s.day > 0 && s.todaysCount}
													</td>
												) : (
													<td>-</td>
												)
											)}
										</tr>
										<tr>
											{getMonthlyStatsPrint2(
												selectedApplData.data.formData
											).map((s, sInd) =>
												dayjs(s.date).isBefore(dayjs(new Date())) ? (
													<td
														key={sInd}
														className={`b-all p-1 ${
															s.day === 0 ? "bg-danger" : ""
														}`}
													>
														{s.day > 0 && s.todaysCount}
													</td>
												) : (
													<td>-</td>
												)
											)}
										</tr>
										<tr>
											{getMonthlyStatsPrint2(
												selectedApplData.data.formData
											).map((s, sInd) =>
												dayjs(s.date).isBefore(dayjs(new Date())) ? (
													<td
														key={sInd}
														className={`b-all p-1 ${
															s.day === 0 ? "bg-danger holidayBG" : ""
														}`}
													>
														{s.day > 0 && s.todaysCount}
													</td>
												) : (
													<td>-</td>
												)
											)}
										</tr>
										<tr>
											{getMonthlyStatsPrint2(
												selectedApplData.data.formData
											).map((s, sInd) =>
												dayjs(s.date).isBefore(dayjs(new Date())) ? (
													<td
														key={sInd}
														className={`p3-5 pb-3 b-all p-1 ${
															s.day === 0 ? "bg-danger" : ""
														}`}
													>
														&nbsp;
													</td>
												) : (
													<td>-</td>
												)
											)}
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					)}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
