import React from "react";
import { default as dayjs } from "dayjs";
import { E500 } from "../e500";

export const InputField = ({
	field,
	errors,
	register,
	isAdd,
	defaultData,
	prefixValue,
	primaryColor,
	secondaryColor,
	favicon,
}) => {
	let defDate = !isAdd && defaultData[field.name];
	let defVal;
	if(field.defaultValue){
		defVal=field.defaultValue
	}
	if (!isAdd) {
		if (field.valFromObj) {
			defVal = defaultData[field.obj[0]][field.obj[1]];
		} else {
			defVal = defaultData[field.name];
		}
	}
	if (!isAdd && field.type === "date") {
		defDate = dayjs(defDate).format("YYYY-MM-DD");
		defDate = defDate.toString();
	}

	if (!isAdd && field.type === "datetime-local") {
		defDate = dayjs(defDate).format("YYYY-MM-DD H:mm");
		defDate = defDate.toString();
	}

	if (!isAdd && field.type === "time") {
		defDate = dayjs(defDate).format("HH:mm");
		defDate = defDate.toString();
	}

	try {
		return (
			<div
				className={`form-group col-md-${field.size}`}
				style={{ display: field.hidden ? "none" : "block" }}
			>
				<label htmlFor="inputEmail4">{field.placeholder}</label>
				{isAdd ? (
					<>
					{field.defaultValue ?
						<input
							type={field.type}
							className={`form-control ${errors[field.name] && "is-invalid"}`}
							style={{ color: "#000000" }}
							placeholder={field.placeholder}
							{...register(field.name)}
							disabled={field.disabled}
							defaultValue={defVal}
						/>:<input
						type={field.type}
						className={`form-control ${errors[field.name] && "is-invalid"}`}
						style={{ color: "#000000" }}
						placeholder={field.placeholder}
						{...register(field.name)}
						disabled={field.disabled}
					/>}
						{errors[field.name] && (
							<div className="invalid-feedback">
								{errors[field.name].message}
							</div>
						)}
					</>
				) : (
					<>
						<div className="input-group">
							{field.hasPrefix && (
								<div class="input-group-prepend">
									<span className="input-group-text">
										{defaultData[prefixValue]}
									</span>
								</div>
							)}
							<input
								type={field.type}
								className={`form-control ${errors[field.name] && "is-invalid"}`}
								style={{
									color: "#000000",
									backgroundColor: field.disabled ? "white" : "",
								}}
								placeholder={field.placeholder}
								{...register(field.name)}
								defaultValue={field.noValueDisplay ? "" : defDate || defVal}
								autoComplete={field.noAutocomplete ? "new-password" : ""}
								disabled={field.disabled}
							/>
							{errors[field.name] && (
								<div className="invalid-feedback">
									{errors[field.name].message}
								</div>
							)}
						</div>
					</>
				)}
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
