import React from "react";
import { default as dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { axios } from "../../lib/react-query/axios";
import httpParams from "../../lib/httpParams";
import { useCreateCall } from "../../lib/api/create";

import { useDependencies } from "../../store/dependencies";
import { useAuthStore } from "../../store/auth";
import { getDependencyCall } from "../../lib/api/dependencies";
import { E500 } from "../../components/e500";
import { AttendanceCompClass } from "./attendanceCompClass";
import { useGetMutateCall } from "../../lib/api/get";

dayjs.extend(utc);
dayjs.extend(timezone);

export const ClassAttendance = ({
	authData,
	session,
	modSchema,
	navs,
	openMenu,
	setOpenMenu,
	routesData,
	appVariables,
	authsSession,
	roleActive,
	primaryColor,
	secondaryColor,
	favicon,
	app,
}) => {
	const params = httpParams.adminOrgParams(appVariables);
	const getCallMutation = useGetMutateCall();
	const getData = { data: { formData: [] } };
	const [attenData, setAttenData] = React.useState({ data: { formData: [] } });
	const [gettingData, setGettingData] = React.useState(false);

	const appState = useAuthStore((state) => state);
	const { setDependency } = useDependencies();
	const createCallMutation = useCreateCall();

	const getDeps = () => {
		const stateVar = { ...appState };
		if (modSchema.hasDependencies) {
			modSchema.dependeciesFormAPIS.map(async (api) => {
				stateVar.dependeciesFormAPIS[api] = await getDependencyCall(
					appVariables.REACT_APP_API_URL,
					api,
					params
				);
				setDependency(stateVar, stateVar.role, stateVar.roleId);
			});
		}
	};

	const getOnchangeAppl = async (e) => {
		setAttenData(null)
		setGettingData(true);
		await getCallMutation
			.mutateAsync({
				apiURL: appVariables.REACT_APP_API_URL,
				apiEndpoint: "ApplicationAYClassSections",
				params: params + "&classSectionId=" + e,
			})
			.then((resp) => {
				setAttenData({ data: resp });
			});
		setGettingData(false);
	};

	React.useEffect(() => {
		getDeps();
	}, []);

	const [compUpdate, setCompUpdate] = React.useState(0);

	const submitAttendance = async (formData, type) => {
		formData["attendanceType"] = type;
		await createCallMutation
			.mutateAsync({
				url: appVariables.REACT_APP_API_URL,
				apiEndpoint: "attendance",
				parentAPI: "appl",
				data: formData, //data,
				messageTitle: "Class Attendance",
				message: "Attendance Registered",
				params: params,
				isMultipart: true,
			})
			.then(() => {
				setCompUpdate(compUpdate + 1);
				getDeps();
			});
	};

	try {
		return (
			<div className="contentbar">
				<div className="row">
					<div key="ClassSectionId" className="col-md-6">
						<select
							className="form-control"
							onChange={(e) => {
								getOnchangeAppl(e.target.value, "applicationId");
							}}
						>
							<option>Select Class Section</option>
							{authData.dependeciesFormAPIS.classSections?.formData.length >
								0 &&
								authData.dependeciesFormAPIS.classSections?.formData.map(
									(ops, opsInd) => (
										<option key={opsInd} value={ops.classSectionId}>
											{ops.classSectionName}
										</option>
									)
								)}
						</select>
					</div>
					<div key="attendanceDate" className="col-md-6 text-right">
						<h6 className="font-light pt-2">
							{dayjs(new Date()).format("DD-MM-YYYY")}
						</h6>
					</div>
					<div className="col-md-12">
						<AttendanceCompClass
							key={`${compUpdate}${attenData}${appState}`}
							modSchema={modSchema}
							getData={attenData}
							submitAttendance={submitAttendance}
							appState={appState}
							gettingData={gettingData}
							appVariables={appVariables}
							primaryColor={primaryColor}
							secondaryColor={secondaryColor}
							favicon={favicon}
						/>
					</div>
				</div>
			</div>
		);
	} catch (err) {
		return (
			<E500
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				favicon={favicon}
				error={err}
			/>
		);
	}
};
